import * as R from 'ramda';
import { utcParse, utcDay } from 'd3';

const parseDate = utcParse('%Y/%m/%d');

export const getTime = R.invoker(0, 'getTime')
export const safeParseDate = R.compose(R.unless(R.isNil, getTime), parseDate);
export const parseBool = bool => bool === 'true' ? true : bool === 'false' ? false : bool
export const mapKeys = R.curry((fn, obj) => R.fromPairs(R.map(R.adjust(0, fn), R.toPairs(obj))));
export const getUtcDay = utcDay.floor;
export const getUtcDayEpoch = R.compose(getUtcDay, getTime)