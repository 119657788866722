import { actionGenerator } from '../common';

export const HOME = 'ROUTES:HOME'
export const DASHBOARD = 'ROUTES:DASHBOARD'
export const LOGIN = 'ROUTES:LOGIN'
export const EMBED = 'ROUTES:EMBED'
export const PROMO = 'ROUTES:PROMO'
export const TOS = 'ROUTES:TOS'
export const FAQ = 'ROUTES:FAQ'
export const EMPTY = 'ROUTES:EMPTY'
export const NOT_FOUND = 'ROUTES:NOT_FOUND';
export const ABOUT = 'ROUTES:ABOUT';
export const METHODOLOGY = 'ROUTES:METHODOLOGY';
export const WHAT_WE_OFFER = 'ROUTES:WHAT_WE_OFFER';
export const JOIN_IN = 'ROUTES:JOIN_IN';
export const SURVEY_TOS = 'ROUTES:SURVEY_TOS';
export const PRIVACY = 'ROUTES:PRIVACY';
export const JOBS = 'ROUTES:JOBS';
export const THANKS = 'ROUTES:THANKS';
export const REPORTS = 'ROUTES:REPORTS';
export const REPORTS_PAGED = 'ROUTES:REPORTS:PAGED';
export const REPORT = 'ROUTES:REPORT';
export const FOUR_ZERO_FOUR = 'ROUTES:FOUR_ZERO_FOUR';
export const TEST_EXCEPTION = 'ROUTES:TEST_EXCEPTION';
export const TEST_EXCEPTION_DASH = 'ROUTES:TEST_EXCEPTION';

export const goToHome = () => actionGenerator(HOME);
export const goToDashboard = (jobName, query = {}, force) => actionGenerator(DASHBOARD, { payload: { jobName }, meta: { query } })
export const goToLogin = () => actionGenerator(LOGIN);
export const goToNotFound = () => actionGenerator(NOT_FOUND);
export const goToEmpty = () => actionGenerator(EMPTY);
export const goToReportsPage = page => actionGenerator(REPORTS_PAGED, { payload: { page } })
export const gotToFourZeroFour = () => actionGenerator(FOUR_ZERO_FOUR);
export const goToTestException = () => actionGenerator(TEST_EXCEPTION);
