import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import { breakpoints, publicUrl, viewportConstants as vc } from '../common';
import socialLinks from './socialLinks';

const firstColumn = [
  { href: '/reports', text: 'News and Reports' },
  { href: '/what-we-offer', text: 'What We Offer' },
  { href: '/methodology', text: 'Our Methodology' },
  { href: '/about', text: 'Our Team' },
  { href: '/faq', text: 'FAQ' },
  { href: '/jobs', text: 'Jobs' }
]

const secondColumn = [
  { href: '/join-in', text: 'Join In' },
  { href: 'https://c0f75171.sibforms.com/serve/MUIEAOCmsQF5sVsqy-UNCNxCO4hb9ciiWcWlVZsU1wi_VnFwgN7yY7EakUH3qwKbOKz6O4JgYumE_KL9uG6V_eM73OLFJP9viuAcF1ru7SoFWOL6PgYg_zIkZNjKyEBTjYKV3fIk3gTTBFi3q77IIPs2AG3kaB0pGPCjWD8CAVv4XNcUWInnjiUorHjx71Y3Bf3hJjIzzn7aayB6', text: 'Newsletter' },
  { href: '/terms-of-service', text: 'Results Terms of Service' },
  { href: '/survey-terms-of-service', text: 'Survey Terms of Use' },
  { href: '/privacy', text: 'Privacy Policy' },
  { href: '/privacy#ccpa', text: 'Do Not Sell My Information' },
]

const thirdColumn = [
  { href: 'mailto:inquiries@civiqs.com', text: 'inquiries@civiqs.com', icon: 'email' },
  { href: 'tel:1-510-394-5664', text: '(510) 394-5664', icon: 'phone' }
]

const styles = StyleSheet.create({
  root: {
    // borderTop: '1px solid #ddd',
    boxSizing: 'border-box',
    position: 'relative',
    background: '#fff',
    zIndex: 100
  },
  info: {
    minHeight: 300,
    position: 'relative',
    backgroundColor: '#101B25',
    display: 'flex',
    justifyContent: 'center',
  },
  inner: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '40px 0',
    width: 1140,
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, {
      width: '90%'
    }),
  },
  ul: {
    listStyle: 'none',
    flex: 1,
    padding: 0,
    color: '#616a73',
    margin: '0 20px 20px 20px',
    fontSize: '14px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, {
      flex: 'none',
      width: '100%',
      margin: '0'
    }),
  },
  li: {
    ':first-child': {
      borderBottom: '1px solid #616a73',
      fontWeight: 'bold',
      lineHeight: '50px',
      minHeight: '50px'
    },
    minHeight: 50,
    lineHeight: '50px'
  },
  a: {
    lineHeight: '50px',
    textDecoration: 'none',
    color: '#616a73',
    ':hover': {
      // textDecoration: 'underline',
      color: '#fff'
    }
  },
  i: {
    lineHeight: '50px',
    float: 'left',
    marginRight: '10px',
  },
  socialIcon: {
    fontSize: 14,
    marginRight: 20,
    lineHeight: '50px',
    color: '#6077aa'
  },
  civiqsIcon: {
    width: 'auto',
    height: 30,
    float: 'right'
  }
})

export const ListItem = ({ href, text, icon, children }) => (
  <li className={css(styles.li)}>
    {icon && <i className={`${css(styles.i)} material-icons`}>{icon}</i>}
    <a className={css(styles.a)} href={href}>{text}</a>
    {children}
  </li>
)

ListItem.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string
}

export const SocialIcon = ({ href, className }) => (
  <a className={css(styles.a)} href={href}>
    <i className={`fa ${className} ${css(styles.socialIcon)}`}></i>
  </a>
)

SocialIcon.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string
}

export const Address = () => (
  <div style={{ lineHeight: '1.5' }}>
    <div style={{ marginTop: 8 }}>Civiqs</div>
    <div style={{ marginLeft: 35 }}>PO Box 70008</div>
    <div style={{ marginLeft: 35, marginBottom: 8 }}>Oakland, CA 94612</div>
  </div>
)

export const List = ({ children, title }) => (
  <ul className={css(styles.ul)}>
    <li className={css(styles.li)}>{title}</li>
    {children}
  </ul>
)

List.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string
}

class Footer extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className={css(styles.root)}>
        {children}
        <div className={css(styles.info)}>
          <div className={css(styles.inner)}>
            <List title={'Company'}>
              {firstColumn.map((c, i) => <ListItem href={c.href} text={c.text} key={i} />)}
            </List>
            <List title={'Participate'}>
              {secondColumn.map((c, i) => <ListItem href={c.href} text={c.text} key={i} />)}
            </List>
            <List title={'Contact'}>
              <ListItem icon={'place'}>
                <Address />
              </ListItem>
              {thirdColumn.map((c, i) => <ListItem href={c.href} text={c.text} icon={c.icon} key={i} />)}
              <ListItem icon={'public'}>
                {socialLinks.map((s, i) => <SocialIcon {...s} key={i} />)}
              </ListItem>
              <ListItem>
                <img className={css(styles.civiqsIcon)} src={publicUrl('/favicon.ico')}></img>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  onSelect: PropTypes.func,
  related: PropTypes.array,
  children: PropTypes.any
}

export default Footer;
