import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { api, mixpanel } from '../common';
import * as actions from './actions';
import { DASHBOARD } from '../routing/';
import { getJobNames, jobDescription } from '../helpers';

export default function* main() {
  yield all([
    takeEvery(actions.SEARCH_SUBMIT, searchSubmit),
    takeEvery(actions.CALL_SEARCH_API, callSearchApi),
    takeEvery(actions.FETCH_TOPLINES, fetchToplines),
    takeEvery(DASHBOARD, clear)
  ])
}

function* clear() {
  yield put(actions.setResults([]));
}

export function* searchSubmit(action) {
  const { value } = action;
  yield put(actions.setResults([]));
  yield put(actions.setSearchValue(value));
  yield put(actions.setFromIndex(0));
  yield put(actions.callSearchApi(action.mixPanelOrigin))
}

export function* callSearchApi(action) {
  const { mixPanelOrigin } = action;
  const { results, searchValue, fromIndex, jobsPerRow, initialLoadQty, rows } = yield select(state => state.search);
  const loadQty = jobsPerRow * rows;
  yield put(actions.setNoResults(false));
  yield put(actions.noMoreResults(false));
  yield put(actions.setIsSearching(true));
  const { data, error } = yield call(api.search, searchValue, fromIndex, fromIndex === 0 ? initialLoadQty : loadQty)
  if (error) {
    yield put(actions.setNoResults(true))
  } else {
    if (data.hits.length === 0) {
      yield put(actions.setNoResults(true));
      yield put(actions.setIsSearching(false));
    }
    else {
      yield put(actions.fetchToplines(data.hits, results, data.exhausted))
    }
  }

  mixpanel.issueSearch(searchValue, mixPanelOrigin);

}

export function* fetchToplines(action) {
  const { data, results, exhausted } = action;
  const jobNames = getJobNames(data)
  const { data: toplines } = yield call(api.toplines, jobNames);
  const newResults = data.map(d => {
    return {
      jobDescription: jobDescription(d),
      trendline: {
        trendline: toplines[d.job_description.name]
      }
    }
  })

  yield put(actions.setResults([...results, ...newResults]));
  yield put(actions.setIsSearching(false));
  yield put(actions.noMoreResults(exhausted))
}
