import { connect } from 'react-redux';
import Header from './Header';
import { toggleSearchBar} from '../search';

const mapStateToProps = state => {
  return {
    showSearchBar: state.search.showSearchBar,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickSearchOpen: () => {
      dispatch(toggleSearchBar(true))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
