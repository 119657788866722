import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { RestrictedPopupTrigger, mediaQueries } from '../common/';
import styled from 'styled-components';
import { setChartType } from './actions'

const Root = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex: 1 1 100%;
`

const Button = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 10px;
  border: 1px solid ${props => props.theme.lightBlueGrey};
  color: ${props => props.toggled ? '#fff' : props.theme.lightBlueGrey};
  background-color: ${props => props.toggled ? props.theme.lightBlueGrey : '#fff'};
  cursor: pointer;
  min-height: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  :not(:first-child) {
    margin-left: -1px;
  };
  ${mediaQueries.tabletLandscape`flex: 1`};
`

const MapButton = styled(Button)`
  flex: 0 0 60px;
  margin-left: 10px;
`

const Icon = styled.span`
  font-size: 24px;
`

function ChartTypeToggle() {
  const { jobDescription, show, restrictedAccess, hasNet, hasMap } = useSelector(state => state.dashboard);
  const dispatch = useDispatch();

  function onClick(type) {
    dispatch(setChartType(type));
  }

  return <Root show={hasNet || hasMap}>
    <Button toggled={!show.net && !show.map} onClick={() => onClick('trendline')}>Trendline</Button>
    {hasNet && <Button toggled={show.net} onClick={() => onClick('net')}>{jobDescription.displayNet.label}</Button>}
    {hasMap && <RestrictedPopupTrigger>
      <MapButton toggled={show.map} onClick={() => restrictedAccess ? null : onClick('map')}>
        <Icon className='icon-civiqs-map'></Icon>
      </MapButton>
    </RestrictedPopupTrigger>
    }
  </Root>
}

export default ChartTypeToggle;
