import { last, first, keys } from 'lodash';

export const getLatestDate = (dates) => {
  return last(dates);
}

export const getFirstDate = dates => {
  return first(dates)
}

export const getDateRange = dates => {
  return [getFirstDate(dates), getLatestDate(dates)]
}

export const getDates = ({ trendline }) => {
  return keys(trendline).map(Number).sort();
}

export const getEmbedDateRange = (dates, show) => {
  const [startDate, endDate] = getDateRange(dates);
  return [show.startDate || startDate, show.endDate || endDate]
}