import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import { primaryBlue, RestrictedPopupTrigger } from '../common/';
import { StyleSheet, css } from 'aphrodite';
import DemographicSearch from './DemographicSearch';

const styles = StyleSheet.create({
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'none'
  },
  ulExpanded: {
    display: 'block'
  },
  listItem: {
    height: 40,
    borderTop: '1px solid #ddd',
    boxSizing: 'border-box',
    lineHeight: '40px',
    background: '#fff',
    color: '#000',
    paddingLeft: 50,
    cursor: 'pointer',
    fontSize: '1.4rem',
    ':hover': {
      background: 'rgb(243,244,246)'
    }
  },
  category: {
    paddingLeft: 20
  },
  hasChildren: {
    paddingLeft: 20,
    cursor: 'auto',
    ':hover': {
      background: '#fff',
    }
  },
  isActive: {
    color: primaryBlue,
    background: 'rgb(243,244,246)'
  },
  icon: {
    lineHeight: '40px',
    float: 'right',
    marginRight: 10,
    fontSize: 18
  },
  activeDemographic: {
    fontSize: 12,
    marginRight: 10,
    float: 'right',
    fontStyle: 'normal'
  },
  restrictedAccess: {
    ':hover': {
      background: '#fff'
    },
    color: 'rgba(152,162,175,1)'
  }
})


class Demographic extends React.Component {

  constructor() {
    super();
    this.onToggle = this.onToggle.bind(this)

    this.state = {
      expanded: true
    }
  }

  onToggle() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    const {demographic, restrictedAccess, activeDemographic, onAdd, onRemove } = this.props;

    return (
      <RestrictedPopupTrigger>
        <div>
          <div onClick={this.onToggle} className={css(styles.listItem, restrictedAccess && styles.restrictedAccess, styles.category)}>
            <strong>{upperFirst(demographic.label)}</strong>
            {
              !this.state.expanded && activeDemographic ?
                <i className={css(styles.activeDemographic)}>{activeDemographic}</i> :
                <i className={`material-icons ${css(styles.icon)}`}>{this.state.expanded ? 'expand_more' :  activeDemographic || 'chevron_right'}</i>}
          </div>
          <div className={css(styles.ul, this.state.expanded && styles.ulExpanded)}>
            <DemographicSearch options={demographic.values} demographic={demographic.label} render={searcResults => {
              return searcResults.map(option => {
                const isActive = activeDemographic === option
                return <div className={css(styles.listItem, restrictedAccess && styles.restrictedAccess)} key={option} onClick={() => {
                  if(restrictedAccess) return;
                  isActive ? onRemove(option) : onAdd(option)
                }}>
                  <span>
                    {upperFirst(option)}
                  </span>
                  <i className={`material-icons ${css(styles.icon)}`}>
                    {isActive &&  'close' }
                  </i>
                  {restrictedAccess && <i className={`material-icons ${css(styles.icon)}`}>lock</i>}
                </div>
              })
            }}/>
          </div>
        </div>
      </RestrictedPopupTrigger>
    );
  }

}

Demographic.propTypes = {
  children: PropTypes.any,
  demographic: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  restrictedAccess: PropTypes.bool,
  activeDemographic: PropTypes.string,
  expanded: PropTypes.bool,
};

export default Demographic;
