import { connect } from 'react-redux';
import Search from './Search';
import * as actions from './actions';

const mapStateToProps = state => {
  return {
    ...state.search,
    ...state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onComponentWillMount: (mixPanelOrigin) => {
      dispatch(actions.callSearchApi(mixPanelOrigin))
    },
    setLoadParams:(initialLoadQty, jobsPerRow) => {
      dispatch(actions.setLoadParams(initialLoadQty, jobsPerRow))
    },
    onSubmit: (value, mixPanelOrigin) => {
      dispatch(actions.searchSubmit(value, mixPanelOrigin))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
