import { mapValues, keys, omit, flatten, values } from 'lodash';

export const keysToExclude = jobDescription => flatten(values(jobDescription.choiceTotals))

export const sumCrosstabs = (data, jobDescription) => {
  return mapValues(data, value => {
    let summedValues = {}
    keys(jobDescription.choiceTotals).forEach(key => {
      let sum = 0;
      jobDescription.choiceTotals[key].forEach(v => {
        sum += value[v]
      })
      summedValues[key] = sum;
    })
    return {
      ...omit(value, keysToExclude(jobDescription)),
      ...summedValues
    };
  })
}

const getDiff = data => Math.sqrt(data.map(l => Math.pow(l, 2)).reduce((a, b) => a + b, 0))

export const sumTrendline = (data, jobDescription) => {
  const exlusions = keysToExclude(jobDescription);
  let output = { trendline: {}, ci_high: {}, ci_low: {} };
  keys(data.trendline).map(date => {
    output.trendline[date] = { ...data.trendline[date] }
    output.ci_high[date] = { ...data.ci_high[date] }
    output.ci_low[date] = { ...data.ci_low[date] }
    keys(jobDescription.choiceTotals).forEach(key => {
      let lows = [], highs = [], sum = 0;
      jobDescription.choiceTotals[key].map(choice => {
        const low = data.ci_low[date][choice];
        const high = data.ci_high[date][choice];
        const lowSd = (low - data.trendline[date][choice]) / 1.96
        const highSd = (high - data.trendline[date][choice]) / 1.96
        lows.push(lowSd);
        highs.push(highSd)
        sum += data.trendline[date][choice];
      })

      const ciDiffs = [getDiff(lows), getDiff(highs)].map(l => l * 1.96)
      output.trendline[date][key] = sum;
      output.ci_high[date][key] = sum + ciDiffs[1];
      output.ci_low[date][key] = sum - ciDiffs[0];
    })
  })
  return mapValues(output, values => mapValues(values, v => omit(v, exlusions)));
}


export const lineChart = (data, choices, dates) => {
  return choices.map(choice => {
    return {
      key: choice,
      values: dates.map(date => {
        return {
          date,
          value: data.trendline[date][choice],
          low: data['ci_low'] ? data['ci_low'][date][choice] : null,
          high: data['ci_high'] ? data['ci_high'][date][choice] : null
        }
      })
    }
  })
}

export const getPopulationStatistics = (demographicSize, stateSize) => {
  if (stateSize) return +demographicSize / +stateSize;
  return demographicSize
}
