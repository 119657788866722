import { load } from 'js-yaml';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import remark from 'remark';
import html from 'remark-html';
import styled from 'styled-components';
import { BlueBanner, CopyContainer, Footer, Header, publicUrl } from './common';

const md = remark().use(html)
const getId = title => `${title.replace(' ', '')}`

const ButtonsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 20px;
  line-height: 40px;
  margin-bottom: 100px;
`

const Li = styled.li`
  float: left;
  margin: 0 10px;
  padding: 0;
  :first-child {
    margin-left: 0px;
  }
`

const Iframe = styled.iframe`
  background-color:transparent;
  margin-top:15px;
  overflow-x:hidden;
  overflow-y:auto;
  height:1230px;
  width:100%;
`

const Input = styled.input`
  background-color: ${props => props.theme.primaryBlue};
  color: #fff;
  padding: 10px 10px;
  width: 100px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-tap-highlight-color : none;
  :hover {
    background: rgb(97, 106, 115);
  }
`

class ApplyWidget extends Component {
  constructor() {
    super();

    this.state = {}

    this.onApplyOrCancel = this.onApplyOrCancel.bind(this)
  }

  onApplyOrCancel() {
    this.setState({
      applying: !this.state.applying
    })
  }

  render() {
    const { id } = this.props;
    const { applying } = this.state;
    if(!id) return <div/>
    return <React.Fragment>
      <div className='resumator-buttons' style={{margin: '25 0', padding:0}}>
        <ButtonsList>
          <Li><Input type='button' value={applying ? 'Cancel': 'Apply Now'} onClick={this.onApplyOrCancel}/></Li>
          <Li><a href={`https://app.jazz.co/app/share/${id}`} rel='noopener noreferrer' target='_blank' type='button'>Share Job</a></Li>
        </ButtonsList>
      </div>
      <div>
        {applying && <Iframe frameBorder='0' src={`https://dailykos.applytojob.com/apply/embed/form/${id}/Our Career Page Widget`}/>}
      </div>
    </React.Fragment>
  }
}

ApplyWidget.propTypes = {
  id: PropTypes.string
}


class Jobs extends Component {
  constructor() {
    super()

    this.state = {}
  }

  fetchManifest() {
    return fetch(publicUrl('/data/jobs.yml')).then(resp => resp.text()).then(d => load(d))
  }

  async componentDidMount() {
    const data = await this.fetchManifest();
    const jobs = {
      ...data,
      listings: data.listings || []
    }
    for (let listing of jobs.listings) {
      const parsed = await md.process(listing.description);
      listing.parsed = parsed;
    }

    this.setState({
      data: jobs
    })
  }


  render() {
    const { data } = this.state;
    return <div>
      <Header />
      { data &&
        <React.Fragment>
          <BlueBanner>{data.title}</BlueBanner>
          <CopyContainer>
            <h1>Current Openings</h1>
          {!data.listings.length && <p>No current openings</p>}
            <ul>
              {data.listings.map( d => <li key={d.title}>
                <a href={`#${getId(d.title)}`}>{d.title}</a>
              </li>)}
            </ul>
            {
              data.listings.map(d => {
                const id = getId(d.title);
                return <div  ref={el => this[id] = el} key={id}>
                  <ScrollableAnchor id={id} key={d.title}>
                    <div>
                      <p dangerouslySetInnerHTML={{__html: d.parsed.contents}}/>
                      <ApplyWidget id={d.job_id}/>
                    </div>
                  </ScrollableAnchor>
                </div>
              })
            }
          </CopyContainer>
        </React.Fragment>
      }
      <Footer />
    </div>
  }
}

export default Jobs;
