import { format } from 'd3';
import { default as moment } from 'moment-twitter';

export const commaNumber = number => format(',d')(number)
export const percent = number => number < 0.01 ? '<1%' : format('.0%')(+number);
export const rawPercent = number => format('.0%')(number);
export const signedRawPercent = number => format('+.0%')(number)
export const netPercent = number => {
  switch (true) {
    case number > 0 && number < 0.01:
      return '<+1%'
    case number < 0 && number > -0.01:
      return '<-1%'
    default:
      return format('+.0%')(number);
  }
}
export const monthYear = date => moment.utc(date).format('MMMM YYYY')
export const shortMonthYear = date => moment.utc(date).format('MMM. DD, YYYY')
export const monthDayYear = date => moment.utc(date).format('MMMM D, YYYY')
export const timestamp = date => moment(date).format('MMMM D, YYYY h:mm:ss A')
