import React, { Component } from 'react';
import { Header, Footer } from './common';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    color: '#616a73',
    fontSize: '2em',
    lineHeight: 2,
    textAlign: 'center',
    height: 'calc(100vh - 550px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px auto',
  }
})

class MaintenancePage extends Component {

  render() {
    return (
      <div>
        <Header logoOnly={true}/>
        <div className={css(styles.container)}>
          We're down for maintenance. <br/> Be right back!
        </div>
        <Footer />
      </div>
    );
  }

}

export default MaintenancePage;
