import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Preview, breakpoints, viewportConstants as vc, mixpanel } from '../common';
import { goToDashboard } from '../routing';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';

const styles = StyleSheet.create({
  relatedArea: {
    margin: '50px 100px 20px 100px',
    ...breakpoints(vc.PHONE, {
      margin: '50px 20px 100px 20px'
    })
  },
  relatedTitle: {
    color: '#2A3546',
    textAlign: 'center',
    lineHeight: '16px',
    fontSize: 18,
    marginBottom: 30,
    fontWeight: 'bold'
  }
});

const mapStateToProps = state => {
  return {
    related: state.dashboard.related
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelect: (question) => {
      mixpanel.clickGraphPartial(question, 'home');
      dispatch(goToDashboard(question, { annotations: true, net: false, uncertainty: true }))

    }
  }
}

class Related extends Component {

  render() {
    const { onSelect, related } = this.props;
    return (
      <div className={css(styles.relatedArea)}>
        <div className={css(styles.relatedTitle)}>RELATED POLLS</div>
        <div>
          <Preview data={related} onClick={onSelect} />
        </div>
      </div>
    );
  }
}

Related.propTypes = {
  onSelect: PropTypes.func,
  related: PropTypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(Related);
