import * as actions from './actions';

const defaults = {
  show: {},
};

export default (state = defaults, action) => {

  switch (action.type) {
    case actions.SET_MANY:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
}
