import { toPairs, fromPairs, sortBy, find, keys, mapKeys } from 'lodash';
import getDmas from './dmas.js';
import getRegions from './getRegions'
import predictor_values from '../../civiqs_mappings/predictor_values.json'


const age_2 = predictor_values['age_2'];
const age_3 = predictor_values['age_3'];
const age_4 = predictor_values['age_4'];

const race_2 = predictor_values['race_2'];
const race_3 = predictor_values['race_3'];
const race_4 = predictor_values['race_4'];
const race_5 = predictor_values['race_5'];

const gender = predictor_values['gender'];
const gender_2 = predictor_values['gender_2'];

const education = predictor_values['education'];
const education_2 = predictor_values['education_2'];
const education_3 = predictor_values['education_3'];

const party = predictor_values['party'];
const party_3 = predictor_values['party_3'];
const party_2_d = predictor_values['party_2_d']
const party_2_r = predictor_values['party_2_r']

const home_state = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

const predictors = { age_2, age_3, age_4, race_2, race_3, race_4, race_5, gender, education, education_2, education_3, party, party_3, party_2_r, party_2_d, gender_2, home_state };

export const validUrlDemographics = ['age', 'race', 'gender', 'education', 'party', 'dma', 'home_state', 'project_keystone_region'];

const mapping = {
  age_2: { name: 'age', label: 'age' },
  age_3: { name: 'age', label: 'age' },
  age_4: { name: 'age', label: 'age' },
  race_2: { name: 'race', label: 'race' },
  race_3: { name: 'race', label: 'race' },
  race_4: { name: 'race', label: 'race' },
  race_5: { name: 'race', label: 'race' },
  gender: { name: 'gender', label: 'gender' },
  gender_2: { name: 'gender', label: 'gender' },
  education: { name: 'education', label: 'education' },
  education_2: { name: 'education', label: 'education' },
  education_3: { name: 'education', label: 'education' },
  party: { name: 'party', label: 'party' },
  party_3: { name: 'party', label: 'party' },
  party_2_d: { name: 'party', label: 'party' },
  party_2_r: { name: 'party', label: 'party' },
  dma: { name: 'dma', label: 'Media Market' },
  home_state: { name: 'home_state', label: 'State' },
  project_keystone_region: { name: 'project_keystone_region', label: 'Region' }
}

export const predictorsToDemographics = ({ job_description }) => {
  const { predictor_list } = job_description;
  let list = []

  predictor_list.forEach(predictor => {
    list.push({
      predictor,
      demographic: mapping[predictor].name,
      label: mapping[predictor].label,
      values: predictors[predictor]
    })
  })

  if (job_description.geographical_subunit === 'home_state') {
    list.push({
      predictor: 'home_state',
      demographic: 'home_state',
      label: 'state',
      values: home_state
    })
  }

  list = sortBy(list, [d => d.label])

  // Media markets are a special case. We  add them to the
  // fron the demographcs array and treat them as a normal demographic
  if (job_description.by_dma || (job_description.geographical_subunit === 'dma')) {
    list.unshift({
      predictor: 'dma',
      demographic: 'dma',
      label: 'Media Market',
      values: getDmas(job_description.respondent_home_state)
    })
  }

  if (job_description.geographical_subunit === 'project_keystone_region') {
    list.unshift({
      predictor: 'project_keystone_region',
      demographic: 'project_keystone_region',
      label: 'Region',
      values: getRegions(job_description.respondent_home_state)
    })
  }

  return list

}

export const predictorToDemographic = (predictor) => {
  return mapping[predictor].name;
}

export const demographicsToPredictors = ({ active, available }) => {
  let output = {};
  keys(active).sort().forEach(demographic => {
    const predictor = find(available, ['demographic', demographic]);
    output = {
      ...output,
      [predictor.predictor]: active[demographic]
    }
  })
  return output
}

export const embedActivDemographics = (demographicString) => {
  const parsed = JSON.parse(demographicString);
  return mapKeys(parsed, (v, k) => mapping[k].name);
}

export const demographicToString = demographic => {
  return JSON.stringify(fromPairs(sortBy(toPairs(demographic), 0)));
}
