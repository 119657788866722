//This component fetches and renders markdown
import React from 'react';
import PropTypes from 'prop-types';
import { BlueBanner, CopyContainer } from './Styled';
import Header from './HeaderContainer';
import Footer from './Footer';
import useMarkdown from './useMarkdown';

function Copy(props) {
  const { fileName, render } = props;
  const { contents, data } = useMarkdown(`/copy/${fileName}`)

  if(!data) return <div/>
  return (
    <div>
      <Header />
      <BlueBanner>{data.title}</BlueBanner>
      <CopyContainer>
        { render ? render({contents, data}) : <p dangerouslySetInnerHTML={{__html: contents}}></p> }
      </CopyContainer>
      <Footer />
    </div>
  );
}

Copy.propTypes = {
  fileName: PropTypes.string.isRequired,
  render: PropTypes.func
}

export default Copy;
