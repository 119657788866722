import React, { Component } from 'react';
import { Header, Footer, BlueBanner, BannerButton, publicUrl, Subtitle} from './common';
import { load } from 'js-yaml';
import marked from 'marked';
import styled from 'styled-components'

const CopyContainer = styled.div`
  max-width: 75rem;
  margin: auto;
  padding: 20px;
`

const P = styled.p`
  color: #616a73;
  font-family: Lato,sans-serif;
  font-weight: 400;
  line-height: 26px;
  .pull-quote {
    color: #c4c6c7;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 32px;
    margin: 42px;
    text-align: center
  }
`

class JoinIn extends Component {
  constructor() {
    super();
    this.state = { }
  }

  fetchManifest() {
    return fetch(publicUrl('/data/join.yml')).then(resp => resp.text()).then(d => load(d))
  }

  fetchCopy () {
    return fetch(publicUrl('/copy/join_in.html.md')).then(resp => resp.text()).then(data => {
      const html = marked(data)
      return html;
    })
  }

  async componentDidMount() {
    const data = await this.fetchManifest();
    const html =  await this.fetchCopy();
    this.setState({
      data,
      html
    })
  }

  render() {
    const { data, html } = this.state
    return (
      <div>
        <Header />
        { data &&
          <React.Fragment>
            <BlueBanner>{data.banner_title}
              <BannerButton href={`${data.banner_button_path}?utm_source=civiqs_website`}>{data.banner_button_text}</BannerButton>
            </BlueBanner>
            <CopyContainer>
              <Subtitle>{data.about_title}</Subtitle>
              <P dangerouslySetInnerHTML={{__html: html}}/>
              <BannerButton href={`${data.banner_button_path}?utm_source=civiqs_website`} inverse>{data.cta_button_text}</BannerButton>
            </CopyContainer>
          </React.Fragment>
        }
        <Footer />
      </div>
    );
  }

}

export default JoinIn;
