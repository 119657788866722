import { call, all, put, takeEvery } from 'redux-saga/effects';
import { saga as dashboardSaga } from './dashboard/';
import { loginSaga } from './login/';
import { searchSaga } from './search';
import { NOT_FOUND } from 'redux-first-router';
import { goToHome } from './routing';
import { embedSaga } from './embeds';

export default function* mainSaga() {
  yield all([
    call(dashboardSaga),
    call(loginSaga),
    call(searchSaga),
    call(embedSaga),
    takeEvery(NOT_FOUND, notFound)
  ])
}

function* notFound() {
  yield put(goToHome());
}
