import { connectRoutes } from 'redux-first-router';
import queryString from 'querystring';
import createHistory from 'history/createBrowserHistory';
import * as actions from './actions';

const history = createHistory();
const basename = '';

export const routesMap = {
  [actions.HOME]: `${basename}/`,
  [actions.LOGIN]: `${basename}/login`,
  [actions.EMBED]: `${basename}/results/embed`,
  [actions.TOS]: `${basename}/terms-of-service`,
  [actions.ABOUT]: `${basename}/about`,
  [actions.METHODOLOGY]: `${basename}/methodology`,
  [actions.WHAT_WE_OFFER]: `${basename}/what-we-offer`,
  [actions.JOIN_IN]: `${basename}/join-in`,
  [actions.SURVEY_TOS]: `${basename}/survey-terms-of-service`,
  [actions.FAQ]: `${basename}/faq`,
  [actions.JOBS]: `${basename}/jobs`,
  [actions.PRIVACY]: `${basename}/privacy`,
  [actions.THANKS]: `${basename}/contact-thanks`,
  [actions.REPORTS]: `${basename}/reports`,
  [actions.REPORTS_PAGED]: `${basename}/reports/page/:page`,
  [actions.REPORT]: `${basename}/reports/:slug+`,
  [actions.NOT_FOUND]: `${basename}/not-found`,
  [actions.FOUR_ZERO_FOUR]: `${basename}/404`,
  [actions.TEST_EXCEPTION]: `${basename}/throw-from/results_frontend`,
  [actions.TEST_EXCEPTION_DASH]: `${basename}/throw-from/results-frontend`,
  [actions.PROMO]: `${basename}/results/:jobName/live-embed`,
  [actions.DASHBOARD]: `${basename}/results/:jobName`
}

const { reducer, middleware, enhancer, initialDispatch } = connectRoutes(history, routesMap, { initialDispatch: false, querySerializer: queryString });

export default {
  reducer,
  middleware,
  enhancer,
  initialDispatch,
}
