import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Header, Footer, mediaQueries, BlueBanner, publicUrl, P } from './common';
import { load } from 'js-yaml';
import marked from 'marked';
import styled from 'styled-components'

const CopyContainer = styled.div`
  max-width: 1170px;
  margin: auto;
  padding: 20px;
`

const Subtitle = styled.h2`
  max-width: 650px;
  text-align: center;
  color: ${props => props.theme.color};
  margin: auto;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 50px;
  margin-top: 50px;
`
const Name = styled.h2`
  font-size: 18px;
  letter-spacing: 2px;
  margin: 3px 0;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  color: ${props => props.theme.color}
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const Member = styled.div`
  flex: 0 0 30%;
  margin-bottom: 50px;
  ${mediaQueries.tablet`flex: 0 0 50%`};
  ${mediaQueries.phone`flex: 0 0 100%`}
`

const Title = styled.h3`
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: center;
`

const Image = styled.img`
  border-radius: 20%;
  height: 150px;
  width: auto;
  vertical-align: top;
  display: block;
  margin: auto;
`

const ImagePlaceholder = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 20%;
  margin: auto;
`

const createMarkdown = bio => {
  return {__html: marked(bio)}
}

const TeamMember = ({member}) => (
  <Member>
    {member.picture ? <Image src={publicUrl(`/images/bios/${member.picture}.jpg`)} /> : <ImagePlaceholder/>}
    <Name>{member.name}</Name>
    <Title>{member.title}</Title>
    <P dangerouslySetInnerHTML={createMarkdown(member.bio)}/>
  </Member>
)


TeamMember.propTypes = {
  member: PropTypes.object
}

class About extends Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    fetch(publicUrl('/data/about.yml')).then(resp => resp.text()).then(d => {
      const data = load(d)
      this.setState({data})
    })

  }

  render() {
    const { data } = this.state
    return (
      <div>
        <Header />
        { !data ? <div /> :
          <div>
            <BlueBanner>About Us</BlueBanner>
            <CopyContainer>
              <Subtitle>{data.subtitle}</Subtitle>
              <Row>
                {data.team.map(member => <TeamMember key={member.name} member={member}/>)}
              </Row>
            </CopyContainer>
          </div>
        }
        <Footer />
      </div>
    );
  }

}

export default About;
