import React from 'react';
import PropTypes from 'prop-types';

class Logo extends React.Component {

  render() {
    return (
      <a href="/" className='icon-civiqs-logo' style={{textDecoration: 'none', color: 'inherit'}}></a>
    );
  }
}

Logo.propTypes = {
  onClick: PropTypes.func
}
export default Logo;
