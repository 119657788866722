import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    marginTop: 50,
    fontSize: 26,
    fontWeight: 'bold',
    textAlign: 'center'
  }
})

const NoResults = () => (
  <div className={css(styles.root)}>
    No Results Found
  </div>
);

export default NoResults;
