import { isEmpty, isArray, difference, keys, remove, toPairs, sortBy, pickBy, fromPairs } from 'lodash';
import { descending } from 'd3';
import { getLatestDate } from './dates'

const choices = (jobDescription, overall, dates, sumTotals) => {
  const latestDate = getLatestDate(dates).toString();
  if (sumTotals) {
    const latest = overall.trendline[latestDate];
    const totalKeys = keys(jobDescription.choiceTotals);
    const sortedTotals = fromPairs(sortBy(toPairs(pickBy(latest, (v, k) => totalKeys.indexOf(k) > -1)), 1).reverse())
    const sortedOthers = fromPairs(sortBy(toPairs(pickBy(latest, (v, k) => totalKeys.indexOf(k) === -1)), 1).reverse())
    return keys({ ...sortedTotals, ...sortedOthers })
  }
  if (jobDescription.customChoiceOrder && jobDescription.customChoiceOrder.length > 0) {
    return jobDescription.customChoiceOrder
  }
  const displayNet = jobDescription.displayNet;
  const latestTopline = overall.trendline[latestDate];
  let choices = keys(latestTopline);
  let output;

  if (!isEmpty(displayNet)) {
    if (isArray(displayNet.minuend)) {
      output = [
        ...displayNet.minuend
      ];
    }
    else {
      output = [displayNet.minuend]
    }

    if (isArray(displayNet.subtrahend)) {
      output = [...output, ...displayNet.subtrahend]
    }
    else {
      output = [...output, displayNet.subtrahend];
    }

    const remaining = difference(choices, output);
    remaining.sort((a, b) => descending(latestTopline[a], latestTopline[b]));
    output = [...output, ...remaining];
  } else {
    output = [...choices.sort((a, b) => descending(latestTopline[a], latestTopline[b]))]
  }
  const unsure = remove(output, v => v === 'Unsure')
  return [...output, ...unsure];
}

export default choices;
