import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import getAxes from './getAxes';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setDates } from '../dashboard/actions'
import { nth } from 'ramda';

const FlatButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin: 0px;
  width: 40px;
  text-align: center;
  border-bottom: 2px solid ${props => props.toggled ? props.theme.darkBlueGrey : props.theme.lightBlueGrey};
  color: ${props => props.toggled ? props.theme.darkBlueGrey : props.theme.lightBlueGrey};
  display: ${props => props.hide ? 'none' : 'block'};
  border-radius: 0;
  outline: none;
  line-height: 2;
  font-size: 12px;
`

const ButtonContainer = styled.div`
  width: ${props => props.width}px;
  margin-left: ${props => props.marginLeft}px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const I = styled.i`
  color: ${props => props.theme.lightBlueGrey};
  :hover {
    color: ${props => props.theme.darkBlueGrey};
  }
  cursor: pointer;
  margin-top: -5px;
`

const getWidth = nth(1);
const monthOffsets = [12, 6, 3];

function Brush(props) {
  const { marginLeft, marginRight, x, show, dateRange, height, visible } = props;
  const xAxisRef = useRef();
  const width = getWidth(x.range())
  const [xAxis] = getAxes(x, null, width);
  const dispatch = useDispatch();
  const toggleDates = monthOffsets.map(m => d3.utcMonth.offset(dateRange[1], -m).getTime())

  useEffect(() => {
    d3.select(xAxisRef.current).call(xAxis)
  })

  function onClickSetDate(months) {
    dispatch(setDates({ delta: months }))
  }

  return <React.Fragment>
    <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
      <div style={{ display: visible ? 'block' : 'none' }}>
        <ButtonContainer marginLeft={marginLeft} width={width}>
          <FlatButton toggled={!show.startDate && !show.endDate} onClick={() => dispatch(setDates({}))}>Clear</FlatButton>
          {monthOffsets.map((offset, index) => (
            <FlatButton key={offset} toggled={toggleDates[index] === show.startDate} hide={toggleDates[index] < dateRange[0]} onClick={() => onClickSetDate(offset)}>{`${offset}M`}</FlatButton>
          ))}
        </ButtonContainer>
        <svg width={width + marginLeft + marginRight} height={80}>
          <g transform={`translate(${marginLeft}, 0)`}>
            {props.children}
            <g transform={`translate(0, ${height})`} className='axis x-axis' ref={xAxisRef}></g>
          </g>
        </svg>

      </div>
      <ButtonContainer marginLeft={marginLeft} width={width}>
        <I onClick={props.onToggleVisibility} className='material-icons'>{visible ? 'close' : 'date_range'}</I>
      </ButtonContainer>
    </div>
  </React.Fragment>

}

Brush.propTypes = {
  children: PropTypes.any,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  show: PropTypes.object,
  x: PropTypes.func,
  dateRange: PropTypes.array,
  height: PropTypes.number,
  visible: PropTypes.bool
}
export default Brush