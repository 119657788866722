import { keys } from 'lodash';

const LOAD_FRONT_PAGE = 'load_front_page';
const ISSUE_SEARCH = 'issue_search';
const CLICK_SEARCH_RESULT = 'click_search_result';
const CLICK_GRAPH_PARTIAL = 'click_graph_partial';
const VIEW_GRAPH = 'view_graph';
const CLICK_EMBED = 'click_embed';
const EMBED_LOADED = 'embed_loaded';
const LOGIN = 'login';
const LOGOUT = 'logout';
const PING_RETRY = 'ping_retry';

class Mixpanel {
  constructor() {
    this.props = {}

  }


  print(event, properties = {}) {
    let data = [];
    const _keys = keys(properties).sort();
    _keys.forEach(key => {
      data.push({ key, value: properties[key] })
    })
    // console.log(`Mixpanel event "${event}" was called with following properties:`)
    // data.forEach(({key, value})=> {
    // console.log(`${key}: ${value}`)
    // })
  }


  isDev() {
    const { location: { hostname } } = window;
    return hostname === 'localhost';
  }

  registerOnce(props) {
    if (this.isDev()) {
      this.print('register', props)
    } else {
      window.mixpanel.register(props)
    }
  }

  track(event, props = {}) {
    if (this.isDev()) {
      this.print(event, props)
    } else {
      window.mixpanel.track(event, props);
    }
  }

  loadFrontPage() {
    this.track(LOAD_FRONT_PAGE);
  }

  issueSearch(query, origin) {
    this.track(ISSUE_SEARCH, { query, origin });
    window.ga('send', 'event', 'input', 'search', { query, origin });
  }

  clickSearchResult(query, origin, index) {
    this.track(CLICK_SEARCH_RESULT, { query, origin, index });
    window.ga('send', 'event', 'search_result', 'click', { query, origin, index });
  }

  clickGraphPartial(question, origin, index) {
    this.track(CLICK_GRAPH_PARTIAL, { question, origin, index });
    window.ga('send', 'event', 'graph_partial', 'click', { question, origin, index });
  }

  viewGraph(graphParams) {
    this.track(VIEW_GRAPH, graphParams);
    window.ga('send', 'event', 'graph', 'view', { ...graphParams });
  }

  login() {
    this.track(LOGIN);
  }

  logout() {
    this.track(LOGOUT);
  }

  clickEmbed(graphParams) {
    this.track(CLICK_EMBED, graphParams);
    window.ga('send', 'event', 'embed_button', 'click', { ...graphParams });
  }


  pingRetry(retryCount, error) {
    this.track(PING_RETRY, { retryCount, error });
  }

}


export default new Mixpanel()
