import { max } from 'd3';
import * as R from 'ramda';

const divideBy10 = R.divide(R.__, 10)
const multiplyBy10 = R.multiply(10)
const getNearestTenth = R.compose(divideBy10, Math.ceil, multiplyBy10, max)
const getAbsoluteValues = R.map(R.compose(Math.abs, R.prop('value')))
const getMaxYForNet = R.compose(R.max(0.3), getNearestTenth, getAbsoluteValues)
const getMaxYForTrendline = R.compose(getNearestTenth, R.map(R.prop('high')), R.flatten, R.map(R.prop('values')));
const getYTickValues = R.compose(R.map(divideBy10), R.range(0), multiplyBy10, R.add(0.1));

export function getYDomainForNet(zoomIn, data) {
  if (zoomIn) {
    const maxY = getMaxYForNet(data);
    return [R.negate(maxY), maxY]
  }
  return [-1, 1]
}

export function getYDomainForTrendline(zoomIn, data) {
  if (zoomIn) {
    const maxY = R.min(1, getMaxYForTrendline(data))
    const yTickValues = getYTickValues(maxY)
    return [[0, maxY], yTickValues]
  }
  return [[0, 1]]
}