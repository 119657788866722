import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  error: {
    borderLeft: '3px solid #F44336',
    backgroundColor: '#f5f4f4',
    color: '#000',
    fontSize: 14,
    padding: '10px 20px 20px 20px'
  },
  strong: {
    lineHeight: 2
  }
})


class LoginError extends Component {
  render() {
    const { loginFailed } = this.props;
    if(loginFailed) {
      return (
        <div className={css(styles.error)}>
          <strong className={css(styles.strong)}>Whoops!</strong>
          <div>Your username and passphrase don&apos;t match, please try again.</div>
        </div>

      );
    } else {
      return null;
    }
  }
}

LoginError.propTypes = {
  loginFailed: PropTypes.bool
}

export default LoginError;
