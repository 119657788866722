import { StyleSheet } from 'aphrodite';

export const lightBlueGrey = 'rgba(152,162,175,1)';
export const primaryBlue = 'rgba(42,53,70,1)'

const stylesheet = StyleSheet.create({
  primaryBlueBackground: {
    backgroundColor: primaryBlue
  }
})

export default stylesheet;
