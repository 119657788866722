import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { primaryBlue } from '../common/colors';
import breakpoints, { viewportConstants } from '../common/breakpoints';
import LoginError from './LoginError';

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '15px',
    border: '1px solid #fff',
    padding: '0 8px',
    fontSize: 14
  },
  user: {
    marginLeft: 5,
    ...breakpoints(viewportConstants.PHONE, viewportConstants.TABLET_PORTRAIT, {
      display: 'none'
    })
  },
  form: {
    width: 300,
    ...breakpoints(viewportConstants.PHONE, {
      top: 70,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 100,
      overflow: 'hidden',
      position: 'fixed'
    }),
    zIndex: 10,
    lineHeight: 1,
    border: '1px solid #ddd',
    position: 'absolute',
    right: 10,
    top: 60,
    backgroundColor: '#fff',
    padding: 20

  },
  input: {
    height: 50,
    width: '100%',
    display: 'block',
    outline: 'none',
    border: '1px solid #ddd',
    fontSize: 14,
    padding: 10,
    boxSizing: 'border-box',
    borderRadius: 2,
    marginBottom: 10,
    '-webkit-appearance': 'none',
    ':focus': {
      border: `1px solid ${primaryBlue}`
    }
  },
  submit: {
    color: '#fff',
    '-webkit-appearance': 'none',
    textAlign: 'center',
    backgroundColor: primaryBlue,
    border: 'none',
    fontFamily: 'Lato',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  error: {
    borderLeft: '3px solid #F44336',
    backgroundColor: '#f5f4f4',
    color: '#000',
    fontSize: 14,
    padding: '0 20px 20px 20px',
  },
  signup: {
    display: 'inline-block',
    marginTop: 10,
    textAlign: 'right',
    width: '100%',
    color: '#616a73',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  }

})

class LoginLogout extends React.Component {
  constructor() {
    super();

    this.initialState = {
      show: false,
      password: '',
      email: ''
    }

    this.state = { ...this.initialState }

    this.onToggle = this.onToggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.close);
    window.addEventListener('keyup', e => {
      if (e.keyCode === 27) this.close();
    })
  }

  close() {
    this.setState({ ...this.initialState });
    this.props.clearError();
  }

  open() {
    this.setState({
      show: true
    })
  }

  handleChange(field) {
    return (e) => {
      this.props.clearError();
      this.setState({
        [field]: e.target.value
      })
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit({
      email: this.state.email,
      password: this.state.password
    })
  }

  onToggle(e) {
    if (this.state.show) {
      this.close();
    } else {
      this.open();
    }
  }

  render() {
    const { show, email, password } = this.state;
    const { onClick, user, loginFailed } = this.props;
    const _user = user === 'public' ? null : user;
    return <div className={css(styles.root)} onClick={e => e.stopPropagation()}>
      <div onClick={this.onToggle} className={`login-form-container ${css(styles.iconContainer)}`}>
        {!_user ? <i style={{ fontSize: 14 }} className='material-icons'>person_outline</i> : <i style={{ fontSize: 14 }} className='material-icons'>person</i>}
        <span className={css(styles.user)}>{!_user ? 'Log In' : _user} </span>
      </div>
      <div className={css(styles.form)} style={{ display: show ? 'block' : 'none' }}  >
        {
          _user ? <button className={css([styles.input, styles.submit])} onClick={onClick}>Sign Out</button> : <form onClick={e => e.stopPropagation()} action="" style={{ width: '100%' }} onSubmit={this.onSubmit}>
            <input placeholder='email' value={email} onChange={this.handleChange('email')} className={css(styles.input)} type="text" />,
            <input placeholder='password' value={password} onChange={this.handleChange('password')} className={css(styles.input)} type="password" />
            <input className={css([styles.input, styles.submit])} type="submit" value="Log In" />
          </form>
        }
        <LoginError loginFailed={loginFailed} />
      </div>
    </div>
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
    window.removeEventListener('keyup', this.close);
  }
}

LoginLogout.propTypes = {
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  user: PropTypes.string,
  loginFailed: PropTypes.bool,
  clearError: PropTypes.func
}

export default LoginLogout;
