import { css, StyleSheet } from 'aphrodite';
import { Snackbar } from 'material-ui';
import PropTypes from 'prop-types';
import { last, toPairs } from 'ramda';
import React from 'react';
import ReactHelmet from 'react-helmet';
import styled from 'styled-components';
import { MobileKey } from '../charts';
import { ActiveDemographics, breakpoints, Footer, Header, JobDescription, Loading, mediaQueries, RestrictedPopup, viewportConstants as vc } from '../common/';
import ChartSwitcher from './ChartSwitcher';
import ChartToolbar from './ChartToolbarContainer';
import CrossTabs from './CrosstabsContainer';
import Demographics from './DemographicsContainer';
import Related from './Related';
import SearchOverlay from './SearchOverlayContainer';

const JobDescriptionContainer = styled.div`
  padding: 20px;
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
  ${mediaQueries.tablet`box-shadow:none; position:static`}
`

const styles = StyleSheet.create({
  jobDescriptionContainer: {
    padding: 20,
    position: 'fixed',
    top: 72,
    left: 0,
    right: 0, backgroundColor: '#fff',
    zIndex: 5,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, { boxShadow: 'none', position: 'static' }),
  },
  mainContainer: {
    marginTop: 202,
    display: 'flex',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, { marginTop: 0, display: 'block' })
  },
  demographicsContainer: {
    width: 250,
    height: '100%',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, { width: '100%', position: 'static' })
  },
  chartContainer: {
    padding: '20px 50px',
    flex: 1,
    overflowX: 'hidden',
    borderLeft: '1px solid #ddd',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, { marginLeft: 0, padding: '20px 10px' })
  },
  questionBody: {
    fontSize: '1.2em',
    fontWeight: '800',
    lineHeight: 1.5
  },
  mobileKey: {
    margin: '10px 0 20px 0'
  },
  chartToolbar: {
    margin: '30px 0'
  }
})

class Dashboard extends React.Component {
  onZoom(xDomain) {
    this.setState({
      xDomain
    })
  }

  componentDidMount() {
    this.props.onComponentDidMount(this.props.location.query, this.props.location.payload.jobName);
  }

  render() {
    const { viewport, showSearch, trendline, net, dates, jobDescription, show, colorScale, questionBody, demographics, populationWarning, onRemoveLatestDemographic } = this.props;
    const isPhone = viewport === vc.PHONE;
    const lastDemographic = last(toPairs(demographics.active))
    if (show.net) {
      if (!net) return <Loading />
    } else {
      if (!trendline) return <Loading />
    }
    return (
      <div>
        <RestrictedPopup />
        <Snackbar open={populationWarning} autoHideDuration={2500} message='Selected population is too small. Removing last selection.' onRequestClose={() => onRemoveLatestDemographic(lastDemographic)}></Snackbar>
        <ReactHelmet title={`Civiqs | ${jobDescription.displayText}`}
          meta={[{ name: 'description', content: `Civiqs survey results: ${questionBody}` }]}
        />
        <Header sticky={true} enableSearch={true} />
        <SearchOverlay />
        <JobDescriptionContainer>
          <JobDescription />
        </JobDescriptionContainer>
        <div style={{ position: showSearch ? 'fixed' : 'static' }}>
          <div className={css(styles.mainContainer)}>
            <div className={css(styles.demographicsContainer)}>
              <Demographics />
            </div>
            <div className={css(styles.chartContainer)}>
              <div className={css(styles.questionBody)}>{questionBody}</div>
              <ActiveDemographics removable={true} demographics={demographics} />
              <div>
                <ChartSwitcher />
              </div>
              {isPhone && !show.map &&
                <div className={css(styles.mobileKey)}>
                  <MobileKey
                    colorScale={colorScale}
                    showNet={show.net}
                    jobDescription={jobDescription}
                    data={trendline}
                    dates={dates}
                    activeChoice={show.choice}
                    onClick={this.onClickLabel}
                  />
                </div>
              }
              <div className={css(styles.chartToolbar)}>
                <ChartToolbar />
              </div>
              <CrossTabs />
            </div>
          </div>
          <Footer>
            <Related />
          </Footer>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }
}

Dashboard.propTypes = {
  colorScale: PropTypes.func,
  dates: PropTypes.array,
  jobDescription: PropTypes.object,
  trendline: PropTypes.array,
  net: PropTypes.array,
  location: PropTypes.object,
  onComponentDidMount: PropTypes.func,
  onUnmount: PropTypes.func,
  questionBody: PropTypes.string,
  showSearch: PropTypes.bool,
  viewport: PropTypes.symbol,
  demographics: PropTypes.object,
  show: PropTypes.object,
  populationWarning: PropTypes.bool,
  onRemoveLatestDemographic: PropTypes.func
}

export default Dashboard;
