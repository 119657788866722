import { color } from 'd3';

export default {
  fontFamily: 'Lato',
  // headingColor: '#8c8c8c';
  color: '#616a73',
  primaryBlue: 'rgba(42,53,70,1)',
  lightBlueGrey: 'rgba(152,162,175,1)',
  darkBlueGrey: color('rgba(152,162,175,1)').darker(2.2)
}
