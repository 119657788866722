import { connect } from 'react-redux';
import RestrictedPopup from './RestrictedPopup';
import { showRestrictedModal } from '../actions';

const mapStateToProps = state => {
  return {
    location: state.location,
    restrictedAccess: state.dashboard.restrictedAccess,
    showRestrictedModal: state.app.showRestrictedModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(showRestrictedModal(false))
    },
    flashPopup: () => {
      dispatch(showRestrictedModal(true))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedPopup);
