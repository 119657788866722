import { actionGenerator } from '../common'

export const MOUNT = 'dashboard:MOUNT'
export const ADD_DEMOGRAPHIC = 'dashboard:ADD_DEMOGRAPHIC';
export const REMOVE_DEMOGRAPHIC = 'dashboard:REMOVE_DEMOGRAPHIC';
export const CLEAR_DEMOGRAPHICS = 'dashboard:CLEAR_DEMOGRAPHICS';
export const TOGGLE_SHOW = 'dashboard:TOGGLE_SHOW';
export const REMOVE_SHARED_DATE = 'dashboard:REMOVE_SHARED_DATE';
export const REQUEST_REMOVE_SHARED_DATE = 'dashboard:REQUEST_REMOVE_SHARED_DATE';
export const SET_ACTIVE_CHOICE = 'dashboard:SET_ACTIVE_CHOICE';
export const SET_MANY = 'dashboard:SET_MANY';
export const SET_SHARE_URL = 'dashboard:SET_SHARE_URL'
export const UNMOUNT = 'question.unmount';
export const SET_MODELED_QUESTIONS = 'question:modeled';
export const CLEAR = 'question:clear';
export const SET_ERROR = 'question:setError'
export const MESSAGE = 'question:message';
export const SET_RELATED = 'questions:setRelated';
export const SHARE_CHART = 'question:shareChart';
export const SET_DATES = 'dashboard:SET_DATES';
export const SET_CHART_TYPE = 'dashboard:SET_CHART_TYPE';
export const SET_BRUSH_VISIBILITY = 'dashboard:SET_BRUSH_VISBILITY'

export const addDemographic = (demographic, value) => actionGenerator(ADD_DEMOGRAPHIC, { demographic, value });
export const removeDemographic = (demographic, value) => actionGenerator(REMOVE_DEMOGRAPHIC, { demographic, value });
export const clearDemographics = () => actionGenerator(CLEAR_DEMOGRAPHICS);
export const toggleShow = (parameter, show) => actionGenerator(TOGGLE_SHOW, { parameter });
export const setShareUrl = shareUrl => actionGenerator(SET_SHARE_URL, { shareUrl });
export const mount = (query, jobName) => actionGenerator(MOUNT, { query, jobName });
export const unMount = () => actionGenerator(UNMOUNT)
export const clear = () => actionGenerator(CLEAR);
export const setError = error => actionGenerator(SET_ERROR, { error })
export const message = message => actionGenerator(MESSAGE, { message });
export const setRelated = related => actionGenerator(SET_RELATED, { related });
export const shareChart = () => actionGenerator(SHARE_CHART);
export const removeSharedDate = () => actionGenerator(REMOVE_SHARED_DATE);
export const requestRemoveSharedDate = () => actionGenerator(REQUEST_REMOVE_SHARED_DATE);
export const setActiveChoice = activeChoice => actionGenerator(SET_ACTIVE_CHOICE, { activeChoice })
export const setMany = data => actionGenerator(SET_MANY, { data })
export const setDates = (dates = {}) => actionGenerator(SET_DATES, { dates })
export const setChartType = chartType => actionGenerator(SET_CHART_TYPE, { chartType })
export const setBrushVisibility = visibility => actionGenerator(SET_BRUSH_VISIBILITY, { visibility }) 
