import { useEffect, useState } from 'react';
import { utcDay, select, event } from 'd3';
import { translateMouse } from './canvas';

function useMouse(ref, x, dateRange, show, margins, height) {
  let _dateRange = [show.startDate || dateRange[0], show.endDate || dateRange[1]];
  const [currentDate, setCurrentDate] = useState(_dateRange[1])
  const [moving, setMoving] = useState(false)


  useEffect(() => {
    let _ref = ref.current
    const width = x.range()[1]
    if (!moving) setCurrentDate(_dateRange[1])
    function onMouseMove(e) {
      const coords = translateMouse(ref.current, event, margins)
      if (coords[1] < 0 || coords[1] > height) return onMouseLeave();
      const date = utcDay.floor(x.invert(coords[0])).getTime();
      const [lower, upper] = _dateRange
      if (date <= lower) {
        setCurrentDate(lower);
      }

      else if (date >= upper) {
        setCurrentDate(upper);
      }

      else {
        setCurrentDate(date)
      }

      setMoving(true)
    }

    function onMouseLeave() {
      const lastPoint = utcDay.floor(x.invert(width)).getTime();
      const [lower, upper] = _dateRange;
      setCurrentDate(lastPoint > upper ? upper : lastPoint < lower ? lower : lastPoint)
      setMoving(false)
    }

    select(_ref).on('mousemove', onMouseMove)
    select(_ref).on('mouseleave', onMouseLeave)

    return () => {
      select(_ref).on('mousemove', null)
      select(_ref).on('mouseleave', null)
    }
  }, [ref, x, dateRange, show, _dateRange])

  return [currentDate, moving]
}

export default useMouse
