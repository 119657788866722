import { connect } from 'react-redux';
import ActiveDemographics from './ActiveDemographics';
import { removeDemographic, clearDemographics } from '../dashboard/actions';

const mapStateToProps = state => {
  return {
    // ...state.dashboard,
    populationStatistics: state.dashboard.populationStatistics
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRequestDelete: (dimension, value, query) => {
      dispatch(removeDemographic(dimension, value));
    },
    onRequestDeleteAll: () => {
      dispatch(clearDemographics())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDemographics);
