import { toPairs } from 'lodash';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getColorForChoice, summedTotalsColor } from '../charts';
import { api } from '../common';
import * as helpers from '../helpers';
import { goToNotFound } from '../routing';
import * as actions from './actions';

export default function* main() {
  yield all([
    takeEvery(actions.ON_WILL_MOUNT, onWillMount)
  ])
}

function* handelError(error) {
  if (error) {
    throw new Error(error)
  } else {
    yield
  }
}

export function* onWillMount(action) {
  const { liveEmbed, jobName, query } = action;
  const queryParams = helpers.url.getQueryParams(query);

  if (liveEmbed) {
    yield call(fetchLiveData, jobName, queryParams)
  } else {
    yield call(fetchSnapshotData, query)
  }
}

function* fetchLiveData(jobName, queryParams) {
  try {
    const { data: metadata, error: metaError } = yield call(api.metadata, jobName);
    yield handelError(metaError);

    const { data, error: toplineError } = yield call(api.topline, jobName, {}, metadata.run_id);
    yield handelError(toplineError)

    if (queryParams.show.map) {
      const { data: { crosstabs }, error: crosstabError } = yield call(api.latestCrosstabs, jobName, {}, metadata.run_id);
      yield handelError(crosstabError)

      const jobDescription = helpers.model.jobDescription(metadata);
      const mapData = helpers.model.getMapData(crosstabs, jobDescription)

      yield setData(metadata, data, queryParams.show, queryParams.activeDemographics, mapData)
    } else {
      yield setData(metadata, data, queryParams.show, queryParams.activeDemographics)
    }
  } catch (e) {
    yield put(goToNotFound())
  }
}


function* fetchSnapshotData(query) {
  try {
    const { data, error } = yield call(api.snapshot, query.snapshotUrl);
    yield handelError(error);
    const [demographic, topline] = toPairs(data.snapshot_data)[0];
    const activeDemographics = helpers.model.embedActivDemographics(demographic)
    const jobDescription = helpers.model.jobDescription(data);
    if (data.show.map) {
      const [, crosstabs] = toPairs(data.crosstabs)[0]
      const mapData = helpers.model.getMapData(crosstabs, jobDescription)
      yield setData(data, topline, { ...data.show }, activeDemographics, mapData)
    } else {
      yield setData(data, topline, { ...data.show }, activeDemographics)
    }

  } catch (e) {
    yield put(goToNotFound())
  }
}

function* setData(metadata, topline, show, activeDemographics, mapData) {
  const jobDescription = helpers.model.jobDescription(metadata);
  const dates = helpers.model.getDates(topline);
  const _trendlineData = show.sumTotals ? helpers.sumTrendline(topline, jobDescription) : topline
  const choices = helpers.model.choices(jobDescription, _trendlineData, dates, show.sumTotals);
  const mapChoices = helpers.model.choices(jobDescription, _trendlineData, dates, false);
  const trendline = helpers.model.lineChart(_trendlineData, choices, dates);
  const net = helpers.model.getNet(jobDescription, topline, dates)
  const annotations = helpers.model.getAnnotations(jobDescription)
  const dateRange = helpers.model.getEmbedDateRange(dates, show);
  const sampleSize = metadata.sample_size;
  const name = metadata.job_description.name;
  const questionBody = helpers.model.removeHtml(metadata.question_body);
  const availableDemographics = helpers.model.predictorsToDemographics(metadata);
  const colorScale = show.sumTotals && !show.net ? summedTotalsColor({ jobDescription, choices }) : getColorForChoice(jobDescription);
  yield put(actions.setMany({
    jobDescription,
    trendline,
    mapData,
    net,
    choices,
    mapChoices,
    annotations,
    dateRange,
    sampleSize,
    name,
    questionBody,
    show,
    dates,
    activeDemographics,
    availableDemographics,
    colorScale
  }))
}