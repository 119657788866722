import React, { useRef } from 'react'
import { SVG } from './StyledComponents';
import getAxes from './getAxes';
import useScales from './useScales';

function Axes(props) {
  const { yAxisPosition, xAxisPosition, y, x, yTickValues } = props;

  const xAxisRef = useRef()
  const yAxisRef = useRef()

  const [xAxis, yAxis] = getAxes(x, y, yTickValues);

  useScales(xAxisRef, yAxisRef, xAxis, yAxis);

  return <SVG>
    <g className={'axis y-axis'} ref={yAxisRef} transform={`translate(${yAxisPosition})`}></g>
    <g className={'axis'} ref={xAxisRef} transform={`translate(${xAxisPosition})`}></g>
  </SVG>
}

export default Axes