import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Timeseries, NetTimeseries, StateMap } from '../charts';
import { viewportConstants as vc } from '../common';

const mapStateToProps = state => {
  const { show } = state.dashboard;
  const { viewport } = state.app;
  return {
    show,
    viewport
  }
}

function ChartSwitcher(props) {
  const { show, viewport } = props;
  const isPhone = viewport === vc.PHONE;
  const height = isPhone ? 250 : 300;

  if (show.net) {
    return <NetTimeseries
      height={height}
      viewport={viewport}
    />
  } else if (show.map) {
    return <StateMap
      height={isPhone ? 250 : 402}
      viewport={viewport}
    />
  } else {
    return <Timeseries
      viewport={viewport}
      height={height}
    />
  }
}

ChartSwitcher.propTypes = {
  onRemoveSharedDate: PropTypes.func,
  onClickState: PropTypes.func,
  show: PropTypes.object,
  viewport: PropTypes.symbol,
  choices: PropTypes.array,
  annotations: PropTypes.array
}


export default connect(mapStateToProps)(ChartSwitcher);
