import { connect } from 'react-redux';
import * as actions from './actions';
import Dashboard from './Dashboard';

const mapStateToProps = state => {
  const { viewport } = state.app;
  return {
    location: state.location,
    ...state.dashboard,
    ...state.login,
    viewport
  }
}

const dispatchToProps = dispatch => {
  return {
    onComponentDidMount: (query, jobName) => {
      dispatch(actions.mount(query, jobName))
    },
    onRemoveLatestDemographic: (demographic) => {
      dispatch(actions.removeDemographic(...demographic))
    },
    onUnmount: () => {
      dispatch(actions.unMount())
    }
  }
}

export default connect(mapStateToProps, dispatchToProps)(Dashboard);
