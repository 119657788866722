import React, { Component } from 'react';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { connect } from 'react-redux';
import { widthConstants as wc, viewportConstants as vc, theme as styledTheme } from './common';
import { resize } from './actions';
import styled, { ThemeProvider } from 'styled-components'
import './charts/Chart.css';

export const PHONE = Symbol('phone');
export const TABLET_PORTRAIT = Symbol('tabletPortrait');
export const TABLET_LANDSCAPE = Symbol('tabletLandscape');
export const DESKTOP = Symbol('desktop');
export const BIG_DESKTOP = Symbol('bigDesktop');

const theme = getMuiTheme({
  palette: {
    primary1Color: 'rgb(30,37,50)'
  }
})

const mapStateToProps = state => {
  return {}
}



const mapDispatchToProps = dispatch => {
  return {
    onResize: viewport => {
      dispatch(resize(viewport))
    }
  }
}


class App extends Component {
  constructor() {
    super();
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    setTimeout(() => this.resize());
  }

  resize() {
    throttle(() => {
      const width = window.innerWidth
      let viewport;
      switch (true) {
        case width < wc.PHONE_MAX:
          viewport = vc.PHONE;
          break;
        case width > wc.TABLET_PORTRAIT_MIN && width < wc.TABLET_PORTRAIT_MAX:
          viewport = vc.TABLET_PORTRAIT;
          break;
        case width > wc.TABLET_LANDSCAPE_MIN && width < wc.TABLET_LANDSCAPE_MAX:
          viewport = vc.TABLET_LANDSCAPE;
          break;
        case width > wc.DESKTOP_MIN && width < wc.DESKTOP_MAX:
          viewport = vc.DESKTOP;
          break;
        case width > wc.BIG_DESKTOP_MIN:
          viewport = vc.BIG_DESKTOP;
          break;
      }
      this.props.onResize(viewport)
    }, 10)();
  }

  render() {
    const { children } = this.props;
    return (
      <MuiThemeProvider muiTheme={theme}>
        <ThemeProvider theme={styledTheme}>
          <div>
            {children}
          </div>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
}

App.propTypes = {
  children: PropTypes.any,
  onResize: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
