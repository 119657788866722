import { connect } from 'react-redux';
import Demographics from './Demographics';
import { addDemographic, removeDemographic } from './actions';

const mapStateToProps = state => {
  return {
    router: state.router,
    ...state.dashboard,
    ...state.app
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onAdd: (demographic, value) => {
      dispatch(addDemographic(demographic, value))
    },
    onRemove: (demographic, value) => {
      dispatch(removeDemographic(demographic, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);
