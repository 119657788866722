import { RESIZE, SHOW_RESTRICTED_MODAL } from './actions';
import { viewportConstants } from './common'

const defaultState = {viewport: viewportConstants.DESKTOP, showRestrictedModal: false};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESIZE:
      return {
        viewport: action.viewport
      }
    case SHOW_RESTRICTED_MODAL:
      return {
        ...state,
        showRestrictedModal: action.show
      }
    default:
      return state;

  }
}

export default reducer;
