import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import states from './states.topo.json';
import * as topojson from 'topojson'
import * as d3 from 'd3'
import SVG from './SVG'
import Attribution from './Attribution';
import { fillOpacity, netScale, nonNetMapScale } from './colorScales'
import AnimatedPath from './AnimatedPath';
import mouse from './mouse';
import { addDemographic } from '../dashboard/actions'
import { viewportConstants } from '../common'
import { isUndefined } from 'lodash';

// const AttributionText = Attribution.extend('text');

const geoJSON = topojson.feature(states, states.objects.states)
const projection = d3.geoAlbersUsa();
const path = d3.geoPath().projection(projection)

function getColor(d, jobDescription, colorScale) {
  const {
    negativeColor,
    positiveColor
  } = netScale(jobDescription);

  let color;

  if (!isUndefined(d.net)) {
    color = d3.rgb(d.net >= 0 ? positiveColor : negativeColor);
    color.opacity = fillOpacity(Math.abs(d.net));
  } else {
    color = nonNetMapScale(d, colorScale)
  }
  return color;
}

function StateMap(props) {

  const { width, height, data, jobDescription, setMouseData, disableSelect, viewport, colorScale } = props;
  const isPhone = viewport === viewportConstants.PHONE

  projection.fitExtent([[0, 0], [width, height]], geoJSON);

  const backgroundRef = useRef();
  const dispatch = useDispatch();

  function onMouseMove(e, data, state) {
    const [x, y] = mouse(backgroundRef.current, e);
    setMouseData({ x, y, data: { ...data, state } })
  }

  function onClick(state) {
    if (disableSelect) return;
    dispatch(addDemographic('home_state', state))
  }

  if (!data) return <g />

  const attributionX = isPhone ? - 20 : projection([-166.5, 53.8])[0] - 10

  return <g>
    <rect ref={backgroundRef} width={width} height={height} style={{ fill: '#fff', pointerEvents: 'all' }} onMouseEnter={() => setMouseData(null)} />
    {
      geoJSON.features.map(feature => {
        const d = data[feature.properties.name]
        if (!d) return <path
          key={feature.id}
          d={path(feature)}
          style={{ stroke: '#ddd', fill: '#fff', cursor: 'pointer' }}
          onClick={() => onClick(feature.properties.name)}
          onMouseEnter={() => setMouseData(null)}
        />
        const color = getColor(d, jobDescription, colorScale)
        return <AnimatedPath
          onMouseMove={e => onMouseMove(e, d, feature.properties.name)}
          key={feature.id}
          onClick={() => onClick(feature.properties.name)}
          style={{ stroke: '#fff', fill: '#fff', strokeWidth: 0.5, pointerEvents: 'all', cursor: 'pointer' }}
          attribute='fill'
          value={color}
          d={path(feature)}
        />
      })
    }
    <foreignObject x={attributionX} y={height + 5} width={50} height={15}>
      <Attribution y={height + 5} x={attributionX} />
    </foreignObject>
  </g>
}

StateMap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.object,
  setMouseData: PropTypes.func,
  disableSelect: PropTypes.bool,
  jobDescription: PropTypes.object,
  viewport: PropTypes.symbol,
  colorScale: PropTypes.func
}

export default SVG(StateMap);

// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { useDispatch } from 'react-redux';
// import * as d3 from 'd3';
// import * as topojson from 'topojson'
// import { Canvas, Container } from './StyledComponents';
// import states from './states.topo.json'
// import useResize from './useResize';
// import { fillOpacity, netScale, nonNetMapScale } from './colorScales'
// import usePrevious from './usePrevious';
// import animate from './animate';
// import { reset, fillState, addMesh, translateMouse } from './canvas';
// import * as turf from '@turf/turf';
// import { addDemographic } from '../dashboard/actions'
// import { keys } from 'lodash';
// import Attribution from './Attribution';
// import { viewportConstants } from '../common'

// const geoJSON = topojson.feature(states, states.objects.states)
// const projection = d3.geoAlbersUsa();
// const path = d3.geoPath().projection(projection)
// const flattened = geoJSON.features.map(feature => {
//   const flattened = turf.flatten(feature);
//   return {
//     properties: feature.properties,
//     ...flattened
//   }
// })

// function getMouseState(e, ref, margins) {
//   const coords = translateMouse(ref.current, e, margins);
//   const longLat = projection.invert(coords);
//   const point = turf.point(longLat);
//   const state = flattened.find(feature => {
//     return feature.features.some(f => turf.booleanWithin(point, f))
//   });
//   return [state, ...coords];
// }

// function getColor(d, jobDescription, colorScale) {
//   const {
//     negativeColor,
//     positiveColor
//   } = netScale(jobDescription);

//   let color;

//   if (d.net) {
//     color = d3.rgb(d.net < 0 ? negativeColor : positiveColor);
//     color.opacity = fillOpacity(Math.abs(d.net));
//   } else {
//     color = nonNetMapScale(d, colorScale)
//   }
//   return color;
// }

// function Map(props) {
//   const { data, height, jobDescription, colorScale, marginTop = 10, marginBottom = 50, marginLeft = 0, marginRight = 0, setMouseData, disableSelect, viewport } = props;
//   const isPhone = viewport === viewportConstants.PHONE

//   const canvas = useRef()
//   const container = useRef()

//   const width = useResize(container, marginLeft, marginRight);
//   const margins = { top: marginTop, marginBottom: marginBottom, left: marginLeft, right: marginRight }
//   const totalHeight = height + marginTop + marginBottom;
//   const totalWidth = width + marginLeft + marginRight;

//   projection.fitExtent([[0, 0], [width, height]], geoJSON);

//   const previousData = usePrevious(data);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (!canvas.current || !data || keys(data).length < 2) return;
//     const context = canvas.current.getContext('2d')

//     animate(previousData, data, (data => {
//       reset(context, totalWidth, totalHeight, marginLeft, marginTop)
//       geoJSON.features.map(feature => {
//         const d = data[feature.properties.name]
//         if (!d) return;
//         const color = getColor(d, jobDescription, colorScale);
//         fillState(context, path, feature, color)
//       })
//       addMesh(context, path, states)
//     }), 500)
//   })

//   function onMouseMove(e) {
//     const [state, x, y] = getMouseState(e, canvas, margins);
//     if (state) {
//       const d = data[state.properties.name];
//       setMouseData({ x, y, data: { ...d, state: state.properties.name } })
//     } else {
//       setMouseData(null)
//     }
//   }

//   function onClick(e) {
//     if (disableSelect) return;
//     const [state] = getMouseState(e, canvas, margins);
//     if (state) {
//       dispatch(addDemographic('home_state', state.properties.name))
//     }
//   }
//   const attributionX = isPhone ? 0 : projection([-166.5, 53.8])[0] - 10
//   return <Container ref={container} width={totalWidth} height={totalHeight}>
//     <Canvas ref={canvas} height={totalHeight * 2} width={totalWidth * 2} onMouseMove={onMouseMove} onClick={onClick} />
//     <Attribution top={totalHeight - 40} left={attributionX} />
//   </Container>
// }

// Map.propTypes = {
//   data: PropTypes.object,
//   height: PropTypes.number,
//   jobDescription: PropTypes.object,
//   colorScale: PropTypes.func,
//   marginTop: PropTypes.number,
//   marginBottom: PropTypes.number,
//   marginLeft: PropTypes.number,
//   marginRight: PropTypes.number
// }

// export default Map;