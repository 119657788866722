import { connect } from 'react-redux';
import LoggedIn from './LoggedIn';
import { checkUser } from './actions';

const mapStateToProps = state => {
  return {
    loggedIn: state.login.loggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onComponentWillMount: () => {
      dispatch(checkUser())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);
