import { camelCase } from 'lodash';
import { pick, prop, compose, assoc, either, propOr } from 'ramda'
import { mapKeys } from '../shared'

const keys = [
  'name',
  'annotations_list',
  'choice_colors',
  'custom_choice_order',
  'display_net',
  'display_text',
  'limit_to_party',
  'population_model',
  'sampling_frame',
  'respondent_home_state',
  'security_level',
  'geographical_subunit',
  'choice_totals',
  'linked_population_model_job'
];


const getGeo = either(prop('respondentHomeState'), propOr('National', 'samplingFrame'))
export const addGeo = o => assoc('geo', getGeo(o), o);

export default compose(addGeo, mapKeys(camelCase), pick(keys), prop('job_description'))
