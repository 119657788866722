import 'babel-polyfill';
import React from 'react';
import BugsnagErrorBoundary from './bugsnag';
import ReactDOM from 'react-dom';
import App from './App';
import 'modern-normalize/modern-normalize.css'
import './index.css';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import { dashboard } from './dashboard';
import { login } from './login/'
import { Message, messages } from './messages';
import mainSaga from './saga';
import appReducer from './reducer';
import { searchReducer } from './search';
import { embedsReducer } from './embeds';
import { TrafficCop, routing, reducer as routingReducer } from './routing';
import { RestrictedPopup } from './common';
import ReactHelmet from 'react-helmet'

//Fix for IE
if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
}

const reducers = combineReducers({
  location: routing.reducer,
  routing: routingReducer,
  app: appReducer,
  embeds: embedsReducer,
  dashboard,
  search: searchReducer,
  login,
  messages
})

const sagaMiddleware = createSagaMiddleware();
const routingMiddleware = routing.middleware;
const middlewares = applyMiddleware(sagaMiddleware, routingMiddleware);

export const store = createStore(reducers, compose(routing.enhancer, middlewares));

sagaMiddleware.run(mainSaga);
routing.initialDispatch();

ReactDOM.render(
  <BugsnagErrorBoundary>
    <Provider store={store}>
      <App>
        <ReactHelmet>
          < meta name = "description" content = "Civiqs is an online polling company. See public opinion results updated daily, engage Civiqs for research, and take Civiqs surveys." / >
        </ReactHelmet>
        <React.Fragment>
          <Message />
          <TrafficCop />
          <RestrictedPopup />
        </React.Fragment>
      </App>
    </Provider>
  </BugsnagErrorBoundary>,
  document.getElementById('root')
)
