import { LOGIN, CHECK_USER, LOGOUT, setLoggedIn, setUser, loginFailed } from './actions';
import { put, call, take, takeEvery, all } from 'redux-saga/effects';
import { api, mixpanel, isMobile } from '../common/';
import { errorMessage } from '../messages';

export function* login () {
  while (true) {
    const action = yield take(LOGIN);
    const {email, password } = action
    yield put(loginFailed(false));
    const { error } = yield call(api.login, email, password);
    if(!error) {
      mixpanel.registerOnce({
        user: email,
        mobile: isMobile()
      })
      mixpanel.login();
      window.location.reload();
    } else {
      yield put(loginFailed(true))
    }
  }
}

export function * checkUser() {
  const { error, user } = yield call(api.search, null, 0, 0);
  yield(put(setUser(user)))
  mixpanel.registerOnce({
    user,
    mobile: isMobile()
  });
  yield put(setLoggedIn(true))
}

export function * logout () {
  const { error } = yield call(api.logout);
  if(error) {
    yield put(errorMessage('Unable to logout'))
  } else {
    mixpanel.logout();
    window.location.reload();
  }
}

export default function* main() {
  yield all([
    call(login),
    takeEvery(LOGOUT, logout),
    takeEvery(CHECK_USER, checkUser)
  ])
}
