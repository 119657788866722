import { isArray, isEmpty } from 'lodash';
import React from 'react';
import ReactHelmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CopyContainer as CC, Footer, Header, mediaQueries, P as Paragraph, publicUrl, ReportDate, socialLinks, useMarkdown } from '../common';
import { gotToFourZeroFour } from '../routing/actions';

const generateHref = (type, data) => {
  let url;
  if (type === 'twitter') {
    url = encodeURI(`https://twitter.com/intent/tweet?text=From @Civiqs ${data.date}: ${data.title}&url=${window.location.href}`)
  }
  if (type === 'facebook') {
    url = encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
  }
  return url
}

const CopyContainer = styled(CC)`
  max-width: 980px;
  width: auto;
  margin: auto;
  padding: 20px;
  min-height: 100vh;
  margin-top: 40px;
  position: relative;
  ${mediaQueries.tablet`padding: 20px 30px`};
  h1 {
    text-align: center;
    line-height: 1.2;
    margin: 10px 0;
    margin-top: 40px;
  }
  img {
    width: 80%;
    display: block;
    height: auto;
    margin: auto;
  }
  em {
    display: inline-block;
  }
`
const P = styled(Paragraph)`
  text-align: left;
  padding: 0;
  flex: 1;
`

const Aside = styled.div`
  flex: 0 0 200px;
  padding-left: 50px;
  padding-top: 30px;
  ${mediaQueries.tablet`padding-left:0`}
  ul {
    padding: 0;
    margin: 0;
    margin-left: 15px;
    margin-top: 10px;
  }
`

const AsideItem = styled.div`
  flex: 1 0 100%;
  margin-top: 20px;
  font-size: 1.4rem;
  :first-child {
    margin-top: 0px;
    ${mediaQueries.tablet`margin-top: 40px`}
  }
 `

const MultiSourceLink = styled.a`
  display: block;
`

const Date = styled(ReportDate)`
  text-align: center;
`

const InnerContainer = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQueries.tablet`display:block`}
`
const SocialLink = styled.a`
  font-size: 2.4rem;
  display: inline-block;
  ${mediaQueries.tablet`width: 50px;`}
  :last-child {
    margin-left: 20px;
  }
`

function Report() {
  const { payload: { slug } } = useSelector(state => state.location)
  const assetPath = `/reports_data/${slug}/`;

  let { contents, data } = useMarkdown(`${assetPath}report.md`);

  if (contents) contents = contents.replace(/(img src=")(.+)"/ig, function (a, b, c, d) {
    return `${b}${publicUrl(`/images/reports/${c}`)}"`
  })
  const dispatch = useDispatch();

  if (contents && isEmpty(data)) {
    dispatch(gotToFourZeroFour())
  }

  return (
    <div>
      <Header />
      {!data ? <div /> :
        <div>
          < ReactHelmet title={`Civiqs | ${data.title}`}
            meta={[{ name: 'description', content: data.blurb }]}
          />
          <CopyContainer>
            <h1>{data.title}</h1>
            <Date>{data.date}</Date>
            <InnerContainer>
              <P dangerouslySetInnerHTML={{ __html: contents }}></P>
              <Aside>
                {isArray(data.source) ? <span>
                  <AsideItem>Download the survey methodology and crosstabs:</AsideItem>
                  <ul>
                    {data.source.map(d => <li key={d.label}>
                      <MultiSourceLink href={d.url} target='__blank' rel='noopener noreferrer'>{d.label}</MultiSourceLink>
                    </li>
                    )}
                  </ul>
                </span>
                  : <AsideItem>
                    <a href={data.source} target='__blank' rel='noopener noreferrer'>Download</a> the survey methodology and crosstabs.
                  </AsideItem>}
                <AsideItem>
                  Interested in conducting a survey? <a href="/what-we-offer#contact">Speak with a Civiqs Analyst.</a>
                </AsideItem>
                <AsideItem>
                  Want Civiqs updates in your inbox? Sign up <a href='https://c0f75171.sibforms.com/serve/MUIEAOCmsQF5sVsqy-UNCNxCO4hb9ciiWcWlVZsU1wi_VnFwgN7yY7EakUH3qwKbOKz6O4JgYumE_KL9uG6V_eM73OLFJP9viuAcF1ru7SoFWOL6PgYg_zIkZNjKyEBTjYKV3fIk3gTTBFi3q77IIPs2AG3kaB0pGPCjWD8CAVv4XNcUWInnjiUorHjx71Y3Bf3hJjIzzn7aayB6'> for our newsletter</a>, Civiqs Lesson.
                </AsideItem>
                <AsideItem>
                  {
                    socialLinks.slice(0, 2).map(social => {
                      return <SocialLink href={generateHref(social.type, data)} key={social.href}>
                        <i className={`fa ${social.className}`}></i>
                      </SocialLink>
                    })
                  }
                </AsideItem>
              </Aside>
            </InnerContainer>
          </CopyContainer>
        </div>
      }
      <Footer />
    </div>
  );
}

export default Report;
