import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { rgb, max } from 'd3';
import { size } from 'lodash';
import { percent, Loading, RestrictedPopupTrigger, ActiveDemographics } from '../common/';
import CrosstabHeader from './CrosstabHeader';
import { fillOpacity } from './colorScales'
import styled from 'styled-components';
import useAnimate from './useAnimate'
import computeTextLength from './computeTextLength';

const breakWidth = 200;

const getOpacity = ({ colorScale, data }) => {
  const color = rgb(colorScale(data.key));
  color.opacity = fillOpacity(data.value);
  return color
}

const getMaxLabelLength = (choices) => {
  const words = choices.join(' ').split(' ');
  const lengths = words.map((w) => computeTextLength(w, 14, 'bold Lato'));
  return Math.ceil(max(lengths)) + 40;
}

const Root = styled.div`
  font-size: 1.4rem;
`

const Grid = styled.div`
  display: flex;
  background-color: blue;
`
const Cell = styled.div`
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 4rem;
  padding: 0 10px;
  box-sizing: border-box;
`

const RightCell = styled(Cell)`
  flex: 1 0 ${props => props.width}px;
  text-align: right;
  cursor: pointer;
  display: flex;
  position: relative;
  left: ${props => props.offset * props.width}px;
  align-items: center;
  justify-content: flex-end;
  transition: left 0.5s;
  font-weight: ${props => props.isMax ? 600 : 500};
  :last-child {
    border-right: none;
  };
`

const HeaderCell = styled(RightCell)`
  font-weight: bold;
  cursor: auto;
  border-bottom: 2px solid ${props => props.choice ? props.colorScale(props.choice) : '#ddd'};
`

const Row = styled.div`
  display: flex;
  background-color: ${props => props.highlight ? '#eee' : '#fff'};
`

const LeftColumnContainer = styled.div`
  flex: 0 0 200px;
`
const LeftCell = styled(Cell)`
  text-align: left;
  line-height: 4rem;
  cursor: ${props => props.primary ? 'auto' : 'pointer'};
  text-transform: ${props => props.primary ? 'uppercase' : ''};
  font-weight: ${props => props.primary ? 'bold' : 'normal'};
  padding: ${props => props.primary ? '0' : '0 0 0 20px'};
  background-color: ${props => props.highlight ? '#eee' : '#fff'};
`

const RightScrollableArea = styled.div`
  overflow-x: hidden;
  flex: 1 1 100%;
  position: relative;
  background-color: red;
`

const ScrollButton = styled.div`
  position: absolute;
  right: ${props => props.right ? '5px' : 'auto'};
  left: ${props => props.right ? 'auto' : '5px'};
  top: calc(50% + 5px);
  z-index: 1;
  width: 30px;
  height: 30px;
  line-height: 25px;
  border-radius: 16px;
  font-size: 30px;
  font-weight: 500;
  background-color: ${props => props.hover ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.1)'};
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  ::before {
    content: '${props => props.right ? '›' : '‹'}';
  }
`

const I = styled.i`
  color: #171F2E;
  display: block;
  height: 40;
  line-height: 40px;
  font-size: 18px;
`

class HoverGrid extends Component {
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = { hover: false }

  }

  onMouseEnter() {
    this.setState({ hover: true })
  }

  onMouseLeave() {
    this.setState({ hover: false })
  }

  render() {
    return (
      <Grid onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {this.props.render(this.state.hover)}
      </Grid>
    );
  }
}

HoverGrid.propTypes = {
  render: PropTypes.func.isRequired
}

const AnimatedCell = (props) => {
  const cellRef = useRef()
  const { data, width, offset, isMax, colorScale, restrictedAccess, checkScroll } = props;
  useAnimate(cellRef, { text: percent(data.value), 'background-color': isMax ? getOpacity({ colorScale, data }) : 'transparent' }, false, restrictedAccess)
  return <RightCell
    key={data.key}
    width={width}
    ref={cellRef}
    offset={offset}
    isMax={isMax}
    colorScale={colorScale}
    data={data}
    className={checkScroll && 'check-scroll'}
  >{restrictedAccess ? <LockedCell /> : ''}</RightCell>
}

AnimatedCell.propTypes = {
  checkScroll: PropTypes.bool,
  colorScale: PropTypes.func,
  data: PropTypes.object.isRequired,
  isMax: PropTypes.bool,
  offset: PropTypes.number,
  restrictedAccess: PropTypes.bool,
  width: PropTypes.number
}

const LockedCell = () => (
  <RestrictedPopupTrigger>
    <I className='material-icons'>lock</I>
  </RestrictedPopupTrigger>
)

const LeftColumn = ({ rows, primary, labelFirstRow = true, hoverDemographic, onMouseEnterRow, onMouseLeaveRow, onClickRow, restrictedAccess }) => {
  return <LeftColumnContainer>
    <LeftCell primary={true}>{labelFirstRow && rows.label}</LeftCell>
    {rows.values.map(row => (
      <LeftCell primary={primary} key={row.key} onClick={() => restrictedAccess ? null : onClickRow(rows.key, row.key)} onMouseEnter={() => {
        row.key !== 'Total' && onMouseEnterRow(row.key)
      }} onMouseLeave={onMouseLeaveRow} highlight={hoverDemographic === row.key}>{row.key}</LeftCell>
    ))}
  </LeftColumnContainer>
}

LeftColumn.propTypes = {
  hoverDemographic: PropTypes.string,
  labelFirstRow: PropTypes.bool,
  onClickRow: PropTypes.func,
  onMouseEnterRow: PropTypes.func,
  onMouseLeaveRow: PropTypes.func,
  primary: PropTypes.bool,
  restrictedAccess: PropTypes.bool,
  rows: PropTypes.object
}

const RightColumns = (props) => (
  <RightScrollableArea>
    <div>
      {props.scrollable && props.offset < 0 && <ScrollButton hover={props.hover} onClick={props.onClickLeft} right={false} />}
      <Row>
        {props.choices.map(choice => <HeaderCell offset={props.offset} width={props.width} key={choice} choice={choice} colorScale={props.colorScale}>{choice}</HeaderCell>)}
      </Row>
      {props.data.values.map(d => {
        const maxValue = max(d.values, d => d.value);
        return <Row highlight={props.hoverDemographic === d.key} key={d.key} onClick={() => props.restrictedAccess ? null : props.onClickRow(props.data.key, d.key)} onMouseEnter={() => {
          d.key !== 'Total' && props.onMouseEnterRow(d.key)
        }} onMouseLeave={props.onMouseLeaveRow}>
          {d.values.map((e, i) => <AnimatedCell
            key={e.key}
            width={props.width}
            offset={props.offset}
            isMax={maxValue === e.value}
            colorScale={props.colorScale}
            restrictedAccess={props.restrictedAccess}
            data={e}
            checkScroll={i === d.values.length - 1}
          ></AnimatedCell>)}
        </Row>
      })}
      {props.scrollable && !props.fullScrolled && <ScrollButton hover={props.hover} onClick={props.onClickRight} right={true} />}
    </div>
  </RightScrollableArea>
)

RightColumns.propTypes = {
  choices: PropTypes.array,
  colorScale: PropTypes.func,
  data: PropTypes.object,
  fullScrolled: PropTypes.bool,
  hover: PropTypes.bool,
  hoverDemographic: PropTypes.string,
  offset: PropTypes.number,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  onClickRow: PropTypes.func,
  onMouseEnterRow: PropTypes.func,
  onMouseLeaveRow: PropTypes.func,
  scrollable: PropTypes.bool,
  restrictedAccess: PropTypes.bool,
  width: PropTypes.number
}

class CrossTab extends React.Component {
  constructor(props) {
    super(props);
    this.root = React.createRef();

    this.state = { offset: 0 }

    this.onClickRight = this.onClickRight.bind(this);
    this.onClickLeft = this.onClickLeft.bind(this);
    this.onMouseEnterRow = this.onMouseEnterRow.bind(this);
    this.onMouseLeaveRow = this.onMouseLeaveRow.bind(this);
    this.getPosition = this.getPosition.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.getPosition)
    this.getPosition();
  }

  getPosition() {
    setTimeout(() => {
      const el = document.querySelector('.check-scroll');
      if (!el) return;
      const { right } = el.getBoundingClientRect();
      const rightMargin = 51;
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      let fullScrolled = false
      if (right < (viewportWidth - rightMargin)) fullScrolled = true;
      this.setState({ fullScrolled })
    }, 600)
  }

  onClickRight() {
    this.setState({
      offset: this.state.offset - 1,
    }, () => this.getPosition())

  }

  onClickLeft() {
    this.setState({
      offset: this.state.offset + 1,
    }, () => this.getPosition())
  }

  onMouseEnterRow(demographic) {
    this.setState({
      hoverDemographic: demographic
    })
  }

  onMouseLeaveRow() {
    this.setState({
      hoverDemographic: null
    })
  }

  grid(demographic, scrollable, width, primary, labelFirstRow, rightColumnWidth, restrictedAccess) {
    const { colorScale, choices, onClickRow } = this.props;
    const { offset = 0, hoverDemographic, fullScrolled } = this.state;
    return <HoverGrid key={demographic.key} render={(hover) => (
      <React.Fragment>
        <LeftColumn
          labelFirstRow={labelFirstRow}
          hoverDemographic={hoverDemographic}
          onClickRow={onClickRow}
          onMouseEnterRow={this.onMouseEnterRow}
          onMouseLeaveRow={this.onMouseLeaveRow}
          primary={primary}
          restrictedAccess={restrictedAccess}
          rows={demographic}
        />
        <RightColumns
          choices={choices}
          colorScale={colorScale}
          data={demographic}
          rightColumnWidth={rightColumnWidth}
          hover={hover}
          hoverDemographic={hoverDemographic}
          offset={offset}
          onClickLeft={this.onClickLeft}
          onClickRight={this.onClickRight}
          onClickRow={onClickRow}
          onMouseEnterRow={this.onMouseEnterRow}
          onMouseLeaveRow={this.onMouseLeaveRow}
          restrictedAccess={restrictedAccess}
          scrollable={scrollable}
          fullScrolled={fullScrolled}
          width={width}
        />
      </React.Fragment>
    )} />
  }

  getCellWidth() {
    const { choices } = this.props;
    let cellWidth = 0, scrollable = false, rightColumnWidth
    if (this.root.current) {
      const { width } = this.root.current.getBoundingClientRect();
      rightColumnWidth = width - 200;
      const maxLabelWidth = getMaxLabelLength(choices);
      const maxChoiceLength = max(choices.map(choice => computeTextLength(choice, 14, 'bold Lato')))
      if (maxChoiceLength > breakWidth && maxChoiceLength * choices.length > rightColumnWidth) {
        cellWidth = maxChoiceLength * 0.7
        scrollable = true
      }
      else if (maxLabelWidth * (choices.length + 2) > rightColumnWidth) {
        cellWidth = maxLabelWidth;
        scrollable = true;
      }
    }
    return { cellWidth, scrollable, rightColumnWidth }
  }


  render() {
    const { data, population, demographics, dateRange, restrictedAccess } = this.props
    if (!data) return <Loading />
    const { globalTotal, selectedDemographicTotal, subdemographics, predictors } = data;
    const isHomeState = predictors.home_state
    const { scrollable, cellWidth, rightColumnWidth } = this.getCellWidth()
    return (
      <div>
        <Root ref={this.root}>
          <CrosstabHeader label='All Respondents' population={1} dateRange={dateRange} />
          {this.grid(globalTotal, scrollable, cellWidth, true, false, rightColumnWidth, false)}
          <CrosstabHeader
            isHomeState={isHomeState}
            label='Subgroups'
            population={population >= 1 && !isHomeState ? null : population}
            style={{ marginTop: '40px', marginBottom: '20px' }}
          >
            <div style={{ height: '10px' }}></div>
            <ActiveDemographics removable={true} demographics={demographics} />
          </CrosstabHeader>
          {size(predictors) > 0 && this.grid(selectedDemographicTotal, scrollable, cellWidth, true, false, rightColumnWidth, restrictedAccess)}
          {subdemographics.map((demographic, index) => (
            this.grid(demographic, scrollable, cellWidth, false, true, rightColumnWidth, restrictedAccess)
          ))}
        </Root>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resise', this.getPosition)
  }
}

CrossTab.propTypes = {
  choices: PropTypes.array,
  colorScale: PropTypes.func,
  data: PropTypes.object,
  dateRange: PropTypes.array,
  demographics: PropTypes.object,
  onClickRow: PropTypes.func,
  population: PropTypes.number,
  restrictedAccess: PropTypes.bool
}

export default CrossTab;
