import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import ChartTypeToggle from './ChartTypeToggle';
import ShareChartButton from './ShareChartButton';
import { isEmpty } from 'lodash';
import { StyleSheet, css } from 'aphrodite'
import { breakpoints, lightBlueGrey, viewportConstants as vc } from '../common';

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    alignItems: 'center',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, { display: 'block' })
  },
  spacer: {
    flex: 1
  },
  uncertainty: {
    minWidth: '180px',
    marginRight: '10px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, {
      height: 40,
      marginTop: 10,
      marginRight: 0,
      boxSizing: 'border-box', padding: '5px 0',
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
    })
  },
  annotations: {
    minWidth: '155px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, {
      height: 40,
      marginRight: 0,
      boxSizing: 'border-box', padding: '5px 0',
      borderBottom: '1px solid #ddd',
    })
  },
  zoomIn: {
    minWidth: '120px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, {
      height: 40,
      marginRight: 0,
      boxSizing: 'border-box',
      padding: '5px 0',
      borderBottom: '1px solid #ddd',
    })
  },
  sumTotals: {
    minWidth: '130px',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, {
      height: 40,
      marginRight: 0,
      boxSizing: 'border-box',
      padding: '5px 0',
      borderBottom: '1px solid #ddd',
    })
  },
  shareChartButton: {
    flex: 'none',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, vc.TABLET_LANDSCAPE, {
      display: 'none'
    })
  }
})



class ChartToolbar extends React.Component {

  render() {
    const { onToggle, show, onShareChart, jobDescription, shareUrl, onClose, embeddable } = this.props;
    if (!jobDescription) return null;
    return (
      <div className={css(styles.root)}>
        <ChartTypeToggle />
        <div className={css(styles.spacer)} />
        {!show.map && [
          <div className={css(styles.uncertainty)} key={1}>
            {<Checkbox
              labelPosition={'right'}
              disabled={show.net}
              checked={show.uncertainty}
              iconStyle={{ fill: lightBlueGrey, marginRight: 5 }}
              labelStyle={{ fontSize: '14px', fontFamily: 'Lato', color: lightBlueGrey }}
              onCheck={(e, state) => onToggle('uncertainty', state)}
              label='Uncertainty Range'
            />
            }
          </div>,
          <div className={css(styles.annotations)} key={2}>
            <Checkbox labelPosition={'right'}
              disabled={isEmpty(jobDescription.annotationsList)}
              checked={show.annotations}
              iconStyle={{ fill: lightBlueGrey, marginRight: 5 }}
              labelStyle={{ fontSize: '14px', fontFamily: 'Lato', color: lightBlueGrey }}
              onCheck={(e, state) => onToggle('annotations', state)}
              label={'News & Events'}
            />
          </div>,
          !isEmpty(jobDescription.choiceTotals) && !show.net && <div className={css(styles.sumTotals)} key={3}>
            <Checkbox labelPosition={'right'}
              disabled={isEmpty(jobDescription.choiceTotals)}
              checked={show.sumTotals}
              iconStyle={{ fill: lightBlueGrey, marginRight: 5 }}
              labelStyle={{ fontSize: '14px', fontFamily: 'Lato', color: lightBlueGrey }}
              onCheck={(e, state) => onToggle('sumTotals', state)}
              label={'Sum Totals'}
            />
          </div>,
          <div className={css(styles.zoomIn)} key={4}>
            <Checkbox labelPosition={'right'}
              checked={show.zoomIn}
              iconStyle={{ fill: lightBlueGrey, marginRight: 5 }}
              labelStyle={{ fontSize: '14px', fontFamily: 'Lato', color: lightBlueGrey }}
              onCheck={(e, state) => onToggle('zoomIn', state)}
              label={'Zoom In'}
            />
          </div>

        ]
        }
        <div className={css(styles.shareChartButton)}>
          {embeddable &&
            <ShareChartButton onClick={onShareChart} shareUrl={shareUrl} onClose={onClose} />
          }
        </div>
      </div>
    );
  }
}

ChartToolbar.propTypes = {
  onToggle: PropTypes.func,
  show: PropTypes.object,
  onShareChart: PropTypes.func,
  jobDescription: PropTypes.object,
  shareUrl: PropTypes.string,
  onClose: PropTypes.func,
  embeddable: PropTypes.bool
}

export default ChartToolbar;
