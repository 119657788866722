import { utcDay, utcFormat, utcMonth } from 'd3';
import { isEmpty, isEqual, isObject, mapKeys } from 'lodash';
import { all, call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { getColorForChoice } from '../charts';
import { summedTotalsColor } from '../charts/colorScales';
import { showBrushOnLoad } from '../charts/utilities';
import { api, mixpanel } from '../common';
import * as helpers from '../helpers';
import { DASHBOARD, goToDashboard, goToEmpty, goToHome } from '../routing';
import * as actions from './actions';

const timeFormat = utcFormat('%Y/%m/%d');
let apiTask;


export default function* dashboardSaga() {
  yield all([
    takeEvery(actions.MOUNT, onMount),
    takeEvery(actions.SHARE_CHART, shareChart),
    takeEvery([actions.ADD_DEMOGRAPHIC, actions.REMOVE_DEMOGRAPHIC, actions.CLEAR_DEMOGRAPHICS], changeDemographic),
    takeEvery(actions.TOGGLE_SHOW, toggleShow),
    takeEvery([actions.TOGGLE_SHOW, actions.ADD_DEMOGRAPHIC, actions.REMOVE_DEMOGRAPHIC, actions.CLEAR, actions.MOUNT], track),
    takeEvery(actions.SET_CHART_TYPE, setChartType),
    takeEvery(actions.REQUEST_REMOVE_SHARED_DATE, requestRemoveSharedDate),
    takeEvery(actions.SET_ACTIVE_CHOICE, setActiveChoice),
    takeEvery(actions.SET_DATES, setDates),
  ])
}

function* goToUnfilteredJob(jobName) {
  yield put(goToEmpty())
  yield put(goToDashboard(jobName, {
    annotations: true,
    uncertainty: true,
    zoomIn: true
  }))
}

function* onMount(action) {
  if (!action.query) {
    yield goToUnfilteredJob(action.jobName)
  } else {
    const queryParams = helpers.url.getQueryParams(action.query);
    const jobName = action.jobName
    if (!jobName) {
      yield put(goToHome())
    } else {
      const brushVisibility = showBrushOnLoad(queryParams.show)
      yield put(actions.setMany({ jobName, ...queryParams, brushVisibility, firstLoad: true }))
      yield call(fetchMetaData);
    }

  }
}

function* fetchMetaData() {
  const { jobName } = yield select(state => state.dashboard)
  let { data: metadata, error } = yield call(api.metadata, jobName)

  if (error) {
    yield put(goToHome());
  } else {
    const { run_id: runId } = metadata;
    const { data: unfilteredTrendlineData, error } = yield call(api.topline, jobName, {}, runId);

    if (error) {
      yield put(goToHome())
    } else {
      yield put(actions.setMany({
        runId,
        apiData: {
          metadata,
          unfilteredTrendlineData
        },
      }))
      yield call(setMetaData)
      apiTask = yield call(fetchApiData)
      yield fork(moreLikeThis, jobName, runId);
      yield fork(handleUrlChange);
    }
  }
}

function* fetchApiData() {
  const { jobName, apiData, demographics: d, activeDemographics, runId } = yield select(state => state.dashboard)
  const demographics = {
    ...d,
    active: activeDemographics
  }
  const predictors = helpers.model.demographicsToPredictors(demographics)

  const { data: trendlineData, error: trendlineError } = yield call(api.topline, jobName, predictors, runId);
  const { data: crossTabData } = yield call(api.latestCrosstabs, jobName, predictors, runId)

  if (trendlineError) {
    yield put(goToHome())
  } else {
    yield put(actions.setMany({
      apiData: {
        ...apiData,
        trendlineData,
        crossTabData,
      },
      demographics,
      predictors
    }))
    yield call(setData)
  }
}

const isEmbeddable = (metadata, demographics) => {
  if (isObject(metadata.embeddable)) {
    const isTopline = isEmpty(demographics.active);
    if (isTopline) {
      return metadata.embeddable.topline;
    } else {
      return metadata.embeddable.subdemographic;
    }
  } else {
    return metadata.embeddable;
  }
}

function* setMetaData() {
  const { apiData, embedDate, show, jobName, activeDemographics } = yield select(state => state.dashboard);
  const { user } = yield select(state => state.login);
  const { metadata } = apiData;
  const jobDescription = helpers.model.jobDescription(metadata);
  const restrictedAccess = user === 'public' && jobDescription.securityLevel === 'teaser';
  const annotations = helpers.model.getAnnotations(jobDescription, embedDate);
  const available = helpers.model.predictorsToDemographics(metadata)
  const hasNet = !isEmpty(jobDescription.displayNet);
  const hasMap = jobDescription.geographicalSubunit === 'home_state'

  if ((restrictedAccess && !isEmpty(activeDemographics)) || (restrictedAccess && show.map)) {
    delete show.map
    yield put(goToEmpty());
    yield put(goToDashboard(jobName, { ...show, embedDate }));
    yield cancel(apiTask)
  } else {
    yield put(actions.setMany({
      jobDescription,
      annotations,
      restrictedAccess,
      hasNet,
      hasMap,
      demographics: {
        available
      },
      sampleSize: metadata.sample_size,
      questionBody: helpers.model.removeHtml(metadata.question_body)
    }))
  }
}

function* setData() {
  const { apiData, show, jobDescription, demographics, predictors, restrictedAccess, firstLoad, jobName } = yield select(state => state.dashboard);

  const { trendlineData, crossTabData, unfilteredTrendlineData } = apiData;
  let crosstabs, demographic_size, state_size;
  if (crossTabData) ({ crosstabs, demographic_size, state_size } = crossTabData)
  const { sumTotals } = show;
  const _trendlineData = sumTotals ? helpers.sumTrendline(trendlineData, jobDescription) : trendlineData
  const _crosstabs = sumTotals ? helpers.sumCrosstabs(crosstabs, jobDescription) : crosstabs
  const _unfilteredTrendlineData = sumTotals ? helpers.sumTrendline(unfilteredTrendlineData, jobDescription) : unfilteredTrendlineData
  const dates = helpers.model.getDates(_trendlineData);
  const dateRange = helpers.model.getDateRange(dates);
  const choices = helpers.model.choices(jobDescription, _unfilteredTrendlineData, dates, sumTotals);
  const mapChoices = helpers.model.choices(jobDescription, _unfilteredTrendlineData, dates, false);
  const trendline = helpers.model.lineChart(_trendlineData, choices, dates)
  const net = helpers.model.getNet(jobDescription, trendlineData, dates);
  const colorScale = sumTotals && !show.net ? summedTotalsColor({ jobDescription, choices }) : getColorForChoice(jobDescription)
  const population = helpers.model.getPopulationStatistics(demographic_size, state_size);
  const mapData = helpers.model.getMapData(crosstabs, jobDescription)
  const populationLimit = state_size ? 0.002 : 0.0001;

  const crossTabs = {
    globalTotal: helpers.model.globalTotal(_unfilteredTrendlineData, dates, choices, sumTotals),
    selectedDemographicTotal: helpers.model.selectedDemographicTotal(demographics, _crosstabs, choices, restrictedAccess),
    subdemographics: helpers.model.lowLevelCrosstabs(_crosstabs, demographics, predictors, choices, restrictedAccess),
    predictors: predictors
  }

  if (population < populationLimit) {
    yield put(actions.setMany({ populationWarning: true }))
    if (firstLoad) {
      yield goToUnfilteredJob(jobName)
    }
  } else {
    yield put(actions.setMany({
      crossTabData: crossTabs,
      mapData,
      population,
      jobDescription,
      dates,
      dateRange,
      trendline,
      net,
      choices,
      mapChoices,
      colorScale,
      populationWarning: false,
      firstLoad: false,
      embeddable: isEmbeddable(apiData.metadata, demographics)
    }))
  }

}

function* changeDemographic(action) {
  const { query } = yield select(state => state.location)
  const { type } = action;
  switch (type) {
    case actions.ADD_DEMOGRAPHIC:
      yield updateQueryParameters(helpers.url.addDemographic(action, query))
      break;
    case actions.REMOVE_DEMOGRAPHIC:
      yield updateQueryParameters(helpers.url.removeDemographic(action, query))
      break;
    case actions.CLEAR_DEMOGRAPHICS:
      yield updateQueryParameters(helpers.url.clearDemographics(query))
  }
}

function* toggleShow(action) {
  const { query } = yield select(state => state.location)
  yield updateQueryParameters(helpers.url.toggleBoolean(action.parameter, query))
}

function* shareChart() {
  const { jobName, demographics, show, runId } = yield select(state => state.dashboard);
  const predictors = helpers.model.demographicsToPredictors(demographics)
  const { data, error } = yield call(api.shareChart, jobName, predictors, show, runId);
  if (error) {
    console.log('error sharing chart')
  } else {
    mixpanel.clickEmbed({
      question: jobName,
      ...mapKeys(demographics.active, (v, key) => `filter_${key}`),
      ...show
    })
    yield put(actions.setShareUrl(`${window.location.origin}/results/embed?snapshotUrl=${data.url}`))
  }
}

function* track(action) {
  const { query, payload: { jobName } } = yield select(state => state.location);
  const { activeDemographics, show } = helpers.url.getQueryParams(query);

  mixpanel.viewGraph({
    question: jobName,
    ...mapKeys(activeDemographics, (v, key) => `filter_${key}`),
    ...show
  })
}

function* moreLikeThis(jobName, runId) {
  const { data } = yield call(api.moreLikeThis, jobName, runId);
  const defaultQuestionName = 'approve_president_trump';

  try {
    if (data.length === 0) {
      const { data: metadata } = yield call(api.metadata, defaultQuestionName);
      const { data: trendline } = yield call(api.topline, defaultQuestionName);
      yield put(actions.setRelated([
        {
          jobDescription: helpers.model.jobDescription(metadata),
          trendline
        }
      ]));
    } else {
      const topFour = helpers.moreLikeThis.getTopFour(data);
      const jobNames = helpers.moreLikeThis.getJobNames(topFour);
      const { data: toplines } = yield call(api.toplines, jobNames);

      const related = topFour.map(metadata => {
        return {
          jobDescription: helpers.model.jobDescription(metadata),
          trendline: {
            trendline: toplines[metadata.job_description.name]
          }
        }
      })

      yield put(actions.setRelated(related));
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestRemoveSharedDate() {
  const { query } = yield select(state => state.location)

  yield put(actions.removeSharedDate());
  yield put(actions.setMany({ embedDate: null }));
  yield updateQueryParameters(helpers.url.removeParameters(['embedDate'], query))
}

function* setActiveChoice(action) {
  const { activeChoice } = action;
  const { query } = yield select(state => state.location)
  yield updateQueryParameters(helpers.url.setOrRemoveChoice(activeChoice, query))
}

function* setDates(action) {
  const { dates } = action;
  const { startDate, endDate, delta } = dates;
  const formatUrlDate = d => timeFormat(utcDay.floor(d));
  const { query = {} } = yield select(state => state.location)
  const { jobName, dateRange } = yield select(state => state.dashboard)
  if (delta) {
    const startDate = formatUrlDate(Math.max(utcMonth.offset(dateRange[1], -delta), dateRange[0]));
    yield put(goToDashboard(jobName, {
      ...query, startDate, endDate: null
    }))
  }
  else if (!startDate && !endDate) {
    yield updateQueryParameters(helpers.url.removeParameters(['startDate', 'endDate'], query))
  } else {
    yield put(goToDashboard(jobName, {
      ...query, startDate: formatUrlDate(startDate), endDate: formatUrlDate(endDate)
    }))
  }
}

function* handleUrlChange() {
  while (true) {
    const { meta: { query }, payload: { jobName } } = yield take(DASHBOARD);
    const { activeDemographics, show, jobName: currentJobName } = yield select(state => state.dashboard);
    const parsedQuery = helpers.url.getQueryParams(query);

    if (jobName !== currentJobName) {
      yield put(actions.setMany({ jobName }))
      yield call(fetchMetaData);
    } else {
      yield put(actions.setMany(parsedQuery))
      if (!isEqual(activeDemographics, parsedQuery.activeDemographics)) {
        yield call(fetchApiData)
      }

      if (show.sumTotals !== parsedQuery.show.sumTotals) {
        yield call(setData)
      }

    }
  }
}

function* updateQueryParameters(query) {
  const { payload: { jobName } } = yield select(state => state.location);
  yield put(goToDashboard(jobName, query))
}

function* setChartType(action) {
  const { chartType } = action;
  const { query } = yield select(state => state.location)
  yield updateQueryParameters(helpers.url.setChartType(query, chartType))
}