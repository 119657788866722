import { utcParse, ascending } from 'd3';
import { sharedDateLabel } from '../../common'
import { propOr, applySpec, invoker, prop, compose, map, sort, append, complement, isNil, when } from 'ramda';

const isNotNil = complement(isNil);
const getAnnotationsList = propOr([], 'annotationsList')
const parseDateString = utcParse('%Y-%m-%d');
const parseAndGetTime = compose(invoker(0, 'getTime'), parseDateString, prop('date'));
const createAnnotation = applySpec({
  date: parseAndGetTime,
  text: prop('event')
})
const getAndCreateAnnotations = compose(map(createAnnotation), getAnnotationsList)
const sortFunc = (a, b) => ascending(a.date, b.date);
const sortAnnotations = sort(sortFunc)
const createEmbedAnnotation = date => ({ date, text: sharedDateLabel });
const addEmbedAnnotation = date => when(() => isNotNil(date), append(createEmbedAnnotation(date)))

const getAnnotations = (jobDescription, embedDate) => compose(sortAnnotations, addEmbedAnnotation(embedDate), getAndCreateAnnotations)(jobDescription);

export default getAnnotations;
