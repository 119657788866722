import { interpolate, timer } from 'd3';

export default function animate(prev, data, cb, duration = 200) {
  const i = interpolate(prev, data);
  const t = timer((elapsed) => {
    cb(i(elapsed / duration))
    if (elapsed > duration) {
      t.stop();
      cb(data)
    }
  })
}