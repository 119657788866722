import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import breakpoints, { viewportConstants } from './breakpoints';
import { Results } from '../search';
import { connect } from 'react-redux';

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 85,
    left: 0,
    right: 0,
    bottom: -100,
    backgroundColor: '#fff',
    zIndex: 99
  },
  results: {
    overflowY: 'scroll',
    backgroundColor: '#fff',
    marginTop: 50,
    ...breakpoints(viewportConstants.PHONE, {
      marginTop: 30
    })
  }
})

const mapStateToProps = state => {
  const { results, isSearching, noResults } = state.search;
  return {
    results,
    isSearching,
    noResults
  }
}

class SearchPopup extends React.Component {
  render() {
    const { results, isSearching, noResults } = this.props;
    if(!results.length && !isSearching && !noResults) return null;
    return (
      <div className={css(styles.root)}>
        <div className={css(styles.results)}>
          <Results mixPanelOrigin='dashboard'/>
        </div>
      </div>
    );
  }

}

SearchPopup.propTypes = {
  results: PropTypes.array,
  onSearch: PropTypes.func,
  onGoBack: PropTypes.func,
  onMount: PropTypes.func,
  viewport: PropTypes.symbol,
  isSearching: PropTypes.bool,
  router: PropTypes.object,
  noResults: PropTypes.bool
}

export default connect(mapStateToProps)(SearchPopup);
