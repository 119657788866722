import * as d3 from 'd3';
import { max } from 'lodash';
import * as topojson from 'topojson';
import mouse from './mouse';

export function drawLine(context, lineGenerator, data, colorScale, show = {}, tempChoice) {
  const color = d3.color(colorScale(data.key));
  color.opacity = show.choice ? show.choice === data.key ? 1 : 0.1 : 1;
  color.opacity = (show.choice || tempChoice) ? (show.choice || tempChoice) === data.key ? 1 : 0.1 : 1;
  context.setLineDash([])
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = color
  lineGenerator.context(context)(data.values);
  context.stroke();
}

export function drawNetLine(context, lineGenerator, data, color) {
  context.setLineDash([])
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = color
  lineGenerator.context(context)(data);
  context.stroke();
}

export function drawNetArea(context, areaGenerator, data, color) {
  const _color = d3.color(color)
  _color.opacity = 0.15;
  context.fillStyle = _color;
  context.beginPath();
  areaGenerator.context(context)(data);
  context.fill();
}

export function drawArea(context, areaGenerator, data, colorScale, show, tempChoice) {
  const color = d3.color(colorScale(data.key));
  color.opacity = (show.choice || tempChoice) ? (show.choice || tempChoice) === data.key ? 0.1 : 0 : 0.1;
  context.beginPath();
  context.fillStyle = color.toString()
  areaGenerator.context(context)(data.values);
  context.fill();
}

export function drawCursor(context, x, height, date) {
  context.save();
  const _x = x(date);
  context.beginPath();
  context.lineWidth = 1;
  context.setLineDash([2, 2])
  context.strokeStyle = '#585858';
  context.moveTo(_x, -2);
  context.lineTo(_x, height);
  context.stroke();
  context.setLineDash([])
  context.fillStyle = '#fff';
  context.fillStyle = '#585858'
  context.textAlign = 'center';
  context.textBaseline = 'bottom';
  context.font = '12px Lato'
  context.fillText(d3.utcFormat('%Y-%m-%d')(date), _x, -4)
  context.restore();
}


export function drawLabel(context, label, width, show = {}, tempChoice) {
  context.save();
  const lineGenerator = d3.line().x(d => d.x).y(d => d.y).curve(d3.curveBasis);
  const activeOffset = ((show.choice || tempChoice) === label.key) ? 7 : 0;
  const color = d3.color(label.color);
  const rectX = label.background.x + activeOffset;
  const path = [{ x: label.x, y: label.y }, { x: rectX, y: label.y2 }];

  context.fillStyle = '#fff';
  context.fillRect(rectX, label.background.y, width, label.height)

  context.fillStyle = color;
  context.fillRect(rectX, label.background.y, 3, label.height);

  context.beginPath();
  context.setLineDash([2, 2]);
  context.strokeStyle = color;
  lineGenerator.context(context)(path);
  context.stroke()

  color.opacity = 0.1;
  context.fillStyle = color
  context.fillRect(rectX, label.background.y, width, label.height);

  context.fillStyle = '#000';
  context.textBaseline = 'bottom';
  context.textAlign = 'start';
  label.rows.map(row => {
    context.fillText(row.text, row.x + activeOffset, row.y)
  })

  context.restore();
}

export function drawLabels(context, labels, show = {}, tempChoice) {
  context.font = 'bold 14px Lato'
  const maxLabelWidth = max(labels.map(r => r.width))
  labels.forEach(label => drawLabel(context, label, maxLabelWidth, show, tempChoice))
}

export function reset(context, totalWidth, totalHeight, marginLeft, marginTop) {
  context.setTransform(2, 0, 0, 2, 0, 0);
  context.clearRect(0, 0, totalWidth, totalHeight)
  context.translate(marginLeft, marginTop)
}

function collides(left, right, x, spacing) {
  const leftX = x(left.date);
  const rightX = x(right.date);

  return leftX + spacing > rightX - spacing;
}

export function getCollisions(annotations, x, spacing, activeAnnotation) {
  annotations.forEach((annotation, i) => {
    annotation.x = x(annotation.date)
    annotation.active = false;
    // annotation.isEmbed = annotat]ion.text === sharedDateLabel
    if (i > 0) {
      let leftAnnotation = annotations[i - 1]
      if (collides(leftAnnotation, annotations[i], x, spacing)) {
        if (activeAnnotation === annotation) {
          annotation.active = true;
          annotation.hide = false;
          leftAnnotation.hide = true;
        } else if (leftAnnotation.hide) {
          annotation.hide = false
        } else {
          annotation.hide = true;
        }
      } else {
        annotation.hide = false;
      }
    }
  });

  return annotations
}

export function drawAnnotations(context, annotations, x, height) {
  context.save();
  for (const annotation of annotations) {
    const { date, text, hide } = annotation;
    const _x = x(date)
    const offscreen = _x <= 0;
    const color = offscreen ? '#fff' : '#585858'
    context.beginPath();
    context.strokeStyle = color;
    context.fillStyle = color;
    context.setLineDash([2, 2])
    context.lineWidth = 1;
    context.moveTo(_x, -22);
    context.lineTo(_x, height)
    context.stroke();
    context.font = '12px Lato';
    context.textAlign = 'start';
    if (!hide) {
      context.save();
      context.translate(_x, 0)
      context.rotate(toRadians(-35))
      context.fillText(text, 10, -22)
      context.restore()
    } else {
      context.setLineDash([]);
      context.beginPath();
      context.arc(_x, -22, 3, 0, 2 * Math.PI);
      context.fill();
      context.stroke();
    }
  }

  context.restore();
}

export function translateMouse(elem, e, margins) {
  const [x, y] = mouse(elem, e);
  return [x - margins.left, y - margins.top];
}

export function chartClipPath(context, width, height, x = 0, y = 0, cb) {
  context.save();
  context.beginPath();
  context.rect(x, y, width, height)
  context.clip()
  cb();
  context.restore();
}

export function drawMask(context, x, date, width, height) {
  context.save();
  context.globalCompositeOperation = 'source-atop'
  context.fillStyle = 'rgba(255,255,255,0.85)'
  context.fillRect(x(date), 0, width - x(date), height);
  context.globalCompositeOperation = 'source-over'
  context.restore();
}

export function fillState(context, path, feature, color) {
  context.beginPath();
  context.fillStyle = color;
  path.context(context)(feature)
  context.fill();
}

export function addMesh(context, path, features) {
  context.beginPath();
  path(topojson.mesh(features, features.objects.states, (a, b) => a !== b));
  context.lineWidth = 0.5;
  context.strokeStyle = '#fff';
  context.stroke();
}


export const toRadians = angle => Math.PI / 180 * angle;
const getX = (height, angle) => Math.tan(toRadians(angle)) * height;

export function annotationClipPath(context, width, height, top, cb) {
  context.save();
  context.beginPath();
  context.moveTo(0, top);
  context.lineTo(0, height);
  context.lineTo(width, height);
  context.lineTo(width, top);
  context.lineTo(width, -24)
  context.lineTo(width + getX(top - 24, -45), top)
  context.closePath();
  context.clip()
  cb();
  context.restore();
}