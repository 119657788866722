import * as d3 from 'd3';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestRemoveSharedDate, setBrushVisibility } from '../dashboard/actions';
import animate from './animate';
import Attribution from './Attribution';
import Axes from './Axes';
import Brush from './Brush';
import { annotationClipPath, chartClipPath, drawAnnotations, drawCursor, drawLabels, drawMask, drawNetArea, drawNetLine, getCollisions, reset, translateMouse } from './canvas';
import { netScale } from './colorScales';
import getScales from './getScales';
import { getYDomainForNet } from './getYDomain';
import { getAnnotationHover, getRightMargin, getTopMargin, labelsToRows, netLabels, sharedDateWasClicked } from './labelHelpers';
import { Canvas, Container } from './StyledComponents';
import useBrush from './useBrush';
import useMouse from './useMouse';
import usePrevious from './usePrevious';
import useResize from './useResize';
import { allowBrush, setContainerHeight, setCursor } from './utilities';

const brushHeight = 60;

function Net(props) {
  const { store = 'dashboard', height, marginBottom = 20, marginLeft = 35, viewport } = props;
  const { jobDescription, colorScale, annotations, dateRange, net, show, choices, brushVisibility } = useSelector(state => state[store])

  const container = useRef()
  const canvas = useRef()
  const brush = useRef()

  const dispatch = useDispatch();
  const interpolater = d3.curveBasis;

  const brushPositiveClipId = uniqueId();
  const brushNegativeClipId = uniqueId();

  const { positiveColor, negativeColor } = netScale(jobDescription)

  const [activeAnnotation, setActiveAnnotation] = useState();

  const marginRight = getRightMargin([jobDescription.displayNet.label, ...choices], viewport)
  const marginTop = getTopMargin(annotations, show.annotations)

  const margins = { top: marginTop, marginBottom: marginBottom, left: marginLeft, right: marginRight }

  const width = useResize(container, marginLeft, marginRight)

  const totalHeight = height + marginTop + marginBottom;
  const totalWidth = width + marginLeft + marginRight;

  const [yMin, yMax] = getYDomainForNet(show.zoomIn, net);
  const brushYDomain = getYDomainForNet(true, net);

  let [x, y, originalX] = getScales(height, width, dateRange, [yMin, yMax], show);

  const brushY = y.copy().domain(brushYDomain).range([brushHeight, 0]);
  const intermediateDates = useBrush(brush, originalX, width, show);

  if (intermediateDates) x.domain(intermediateDates)

  const [currentDate] = useMouse(container, x, dateRange, show, margins, height);

  const lineGenerator = useRef();
  const areaGenerator = useRef()
  const brushArea = d3.area().x(d => originalX(d.date)).y0(d => brushY(0)).y1(d => brushY(d.value))
  const brushLine = d3.line().x(d => originalX(d.date)).y(d => brushY(d.value)).curve(interpolater)

  const previous = usePrevious(net);
  const previousY = usePrevious([yMin, yMax]);

  const wrappedLabels = useRef([])

  const _annotations = getCollisions(annotations, x, 10, activeAnnotation)

  function draw(context, data) {
    const labels = netLabels(data, currentDate, x, y, positiveColor, negativeColor, viewport);
    wrappedLabels.current = labelsToRows(labels, height);

    reset(context, totalWidth, totalHeight, marginLeft, marginTop);
    chartClipPath(context, width, height, 0, 0, () => {
      chartClipPath(context, width, height / 2, 0, 0, () => {
        drawNetArea(context, areaGenerator.current, data, positiveColor)
        drawNetLine(context, lineGenerator.current, data, positiveColor)
      })
      chartClipPath(context, width, height / 2, 0, height / 2, () => {
        drawNetArea(context, areaGenerator.current, data, negativeColor)
        drawNetLine(context, lineGenerator.current, data, negativeColor)
      })
      drawMask(context, x, currentDate, width, height)
    })
    annotationClipPath(context, width, height, -marginTop, () => {
      show.annotations && drawAnnotations(context, _annotations, x, height, totalWidth, totalHeight, marginLeft, marginTop);
    })
    drawCursor(context, x, height, currentDate)
    drawLabels(context, wrappedLabels.current);
  }

  useEffect(() => {
    if (!canvas.current || !net || !previous || width === 0) return;
    const context = canvas.current.getContext('2d');

    animate(previousY, [yMin, yMax], (domain) => {
      let _y = y.domain(domain)
      lineGenerator.current = d3.line().x(d => x(d.date) + 0.5).y(d => _y(d.value) - 0.5).curve(interpolater);
      areaGenerator.current = d3.area().x(d => x(d.date)).y0(d => y(0)).y1(d => _y(d.value)).curve(interpolater)
    })

    animate(previous, net, (data) => {
      draw(context, data);
    })
  });

  function onMouseMove(e) {
    const coords = translateMouse(canvas.current, e, margins);
    const activeAnnotation = getAnnotationHover(coords, _annotations, marginTop);
    setActiveAnnotation(activeAnnotation);
    setCursor(coords, width, height, canvas)
  }

  function toggleBrushVisibility() {
    dispatch(setBrushVisibility(!brushVisibility));
  }

  function onClick(e) {
    const coords = translateMouse(canvas.current, e, margins);
    if (sharedDateWasClicked(coords, annotations)) {
      dispatch(requestRemoveSharedDate())
    }
  }

  return <Container ref={container} width={totalWidth} height={setContainerHeight(totalHeight, brushVisibility)} onMouseMove={onMouseMove} onClick={onClick}>
    <Axes x={x} y={y} xAxisPosition={`${marginLeft}, ${height + marginTop} `} yAxisPosition={`${marginLeft}, ${marginTop} `} />
    <Canvas height={totalHeight * 2} width={totalWidth * 2} ref={canvas} marginLeft={marginLeft} marginTop={marginTop} />
    {allowBrush(store) && <Brush
      visible={brushVisibility}
      onToggleVisibility={toggleBrushVisibility}
      colorScale={colorScale}
      dateRange={dateRange}
      marginLeft={marginLeft}
      marginRight={marginRight}
      show={show}
      height={brushHeight}
      x={originalX}
    >
      <g>
        <defs>
          <clipPath id={brushPositiveClipId}>
            <rect width={width} height={60 / 2}></rect>
          </clipPath>
          <clipPath id={brushNegativeClipId}>
            <rect width={width} height={60 / 2} y={60 / 2}></rect>
          </clipPath>
        </defs>
        <g ref={brush} />
        <path style={{ pointerEvents: 'none', fill: positiveColor, opacity: 0.15 }} clipPath={`url(#${brushPositiveClipId})`} d={brushArea(net)} />
        <path style={{ pointerEvents: 'none', fill: negativeColor, opacity: 0.15 }} clipPath={`url(#${brushNegativeClipId})`} d={brushArea(net)} />
        <path style={{ pointerEvents: 'none', strokeWidth: 2, stroke: positiveColor, fill: 'none' }} clipPath={`url(#${brushPositiveClipId})`} d={brushLine(net)} />
        <path style={{ pointerEvents: 'none', strokeWidth: 2, stroke: negativeColor, fill: 'none' }} clipPath={`url(#${brushNegativeClipId})`} d={brushLine(net)} />
      </g>
    </Brush>
    }
    <Attribution top={totalHeight} left={10} />
  </Container >
}

Net.propTypes = {
  store: PropTypes.string,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
  height: PropTypes.number,
  viewport: PropTypes.symbol
}

export default Net;