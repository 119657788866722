import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import { NoResults, Loading, Preview, breakpoints, viewportConstants as vc } from '../common';
import { StyleSheet, css } from 'aphrodite';
import { interpolate, timer, easeCubicOut } from 'd3';
import check from '../common/favicon.ico';

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    minHeight: 'calc(100vh - 400px)',
  },
  tiles: {
    margin: '20px 100px 20px 100px',
    ...breakpoints(vc.PHONE, {
      margin: '20px 20px 100px 20px'
    }),
  },
  noMore: {
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 40,
    position: 'relative'
  },
  check: {
    height: 30,
    width: 'auto',
    display: 'block',
    margin: 'auto',
    marginBottom: 10
  }
})

class Results extends React.Component {
  constructor () {
    super();
    this.goBackToTop = this.goBackToTop.bind(this);
  }

  goBackToTop() {
    const duration = 1000;
    const i = interpolate(window.scrollY, 0);
    const t = timer((elapsed) => {
      window.scrollTo({top: (i(easeCubicOut(elapsed/duration)))})
      if (elapsed > duration) {
        t.stop();
        window.scrollTo({top: 0})
      }
    });
  }

  render() {
    const { noResults, isSearching, results, onSelect, mixPanelOrigin, noMoreResults, loadMore } = this.props;
    return (
      <div ref={node => this.root = node} className={css(styles.root)}>
        { noResults && <NoResults /> }
        <div className={css(styles.tiles)}>
          <Preview data={results} onClick={(q,i) => onSelect(q,i,isSearching, mixPanelOrigin)}></Preview>
        </div>
        {!noMoreResults && !noResults &&  <div className={css(styles.noMore)}>
          {isSearching ?
            <Loading /> :
            <RaisedButton
              onClick={loadMore}
              label={'Load More'}
              primary={true}
            />}
        </div>
        }
        { noMoreResults && <div className={css(styles.noMore)} onClick={this.goBackToTop}>
          <img className={css(styles.check)} src={check} />
          <RaisedButton label='Go back to top &#8593;' primary={true}/>
        </div>
        }
      </div>
    );
  }

  componentWillUnmount() {
    this.props.onWillUnmount();
  }

}

Results.propTypes = {
  noResults: PropTypes.bool,
  isSearching: PropTypes.bool,
  results: PropTypes.array,
  onSelect: PropTypes.func,
  mixPanelOrigin: PropTypes.string,
  loadMore: PropTypes.func,
  noMoreResults: PropTypes.bool,
  onWillUnmount: PropTypes.func
};

export default Results;
