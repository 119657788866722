import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { breakpoints, viewportConstants as vc, widthConstants as wc, primaryBlue } from '../common';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    position: 'relative',
    color: '#fff'
  },
  searchArea: {
    display: 'flex',
    alignItems: 'center',
  },
  inputBackground: {
    backgroundColor: '#fff',
    borderRadius: 2,
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px 0 5px',
    flex: 1
  },
  input: {
    border: 'none',
    width: '100%',
    backgroundColor: '#fff',
    height: 40,
    flex: 1,
    boxSizing: 'border-box',
    fontSize: 16,
    outline: 'none',
    padding: '10px 5px',
  },
  i: {
    color: '#757575',
    cursor: 'pointer'
  },
  iButton: {
    color: '#fff'
  },
  button: {
    backgroundColor: '#98A2AF',
    border: 'none',
    borderRadius: '20px',
    color: '#fff',
    fontSize: '16px',
    outline: 'none',
    fontFamily: 'Lato',
    height: 40,
    padding: '0 60px',
    ...breakpoints(vc.PHONE, {padding:'0 20px'})
  },
  ul: {
    listDecoration: 'none',
    listStyle: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px'
  },
  li: {
    paddingLeft: 60,
    fontSize: 16,
    height: 40,
    lineHeight: '40px',
    ':hover': {
      backgroundColor: '#eee'
    }
  },
  liActive: {
    backgroundColor: '#eee'
  },
  searchIcon: {
    color: primaryBlue
  },
  closeButton: {
    color: '#fff',
    cursor: 'pointer',
    marginRight: 10
  }
});

class Search extends React.Component {
  constructor () {
    super();

    this.state = {
      value: ''
    }

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.keyCodes = {
      13: 'enter'
    }
  }

  componentDidMount() {
    const { innerWidth, innerHeight } = window
    const chartHeight = 200;
    const jobsPerRow = innerWidth > wc.BIG_DESKTOP_MIN ? 5 : 4;
    const minLoadQty = jobsPerRow;
    const resultsAreaHeight = Math.max(innerHeight - (this.props.headerHeight || 0), 0);
    const initialLoadQty = Math.ceil(resultsAreaHeight/chartHeight) * jobsPerRow;

    this.props.setLoadParams(initialLoadQty || minLoadQty, jobsPerRow);

    if(this.props.searchValue) {
      this.setState({
        value: this.props.searchValue
      })
    }

    if(this.props.searchOnMount) {
      this.onSubmit();
    }
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({
      value
    }, () => {
      if(value.length === 0) {
        this.onSubmit();
      }
    })
  }

  onKeyDown(e) {
    const { keyCode } = e;
    if (this.keyCodes[keyCode] === 'enter') {
      this.onSubmit()
    }
  }

  onSubmit () {
    this.props.onSubmit(this.state.value, this.props.mixPanelOrigin)
  }

  render() {
    const { viewport, inputStyle = {}, style ={}, autoFocus = false, showCloseButton, onClickClose } = this.props;
    const isPhone = viewport === vc.PHONE;
    return (
      <div onKeyDown={this.onKeyDown} className={css(styles.root)} style={{...style}}>
        <div className={css(styles.searchArea)}>
          <div className={css(styles.inputBackground)}>
            {!isPhone && <i className={`material-icons ${css(styles.searchIcon)}`}>search</i>}
            <input
              type="text"
              ref={input => this.input = input}
              className={css(styles.input)}
              value={this.state.value}
              onChange={this.onChange}
              style={{...inputStyle}}
              autoFocus={true}
              placeholder='Search Civiqs surveys'
            />
          </div>
          {showCloseButton && <i className={`material-icons ${css(styles.closeButton)}`} onClick={onClickClose}>close</i>}
          <button type="button" className={css(styles.button)} style={inputStyle} onClick={this.onSubmit}>
            { isPhone ?
              <i className={`material-icons ${css(styles.iButton)}`}>search</i>
              : 'Search'
            }
          </button>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  onChange: PropTypes.func,
  suggestions: PropTypes.array,
  viewport: PropTypes.symbol,
  onSelect: PropTypes.func,
  inputStyle: PropTypes.object,
  style: PropTypes.object,
  onSubmit: PropTypes.func,
  searchOnMount: PropTypes.bool,
  mixPanelOrigin: PropTypes.string,
  autoFocus: PropTypes.bool,
  headerHeight: PropTypes.number,
  setLoadParams: PropTypes.func,
  searchValue: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onComponentWillMount: PropTypes.func,
  onClickClose: PropTypes.func,
}

export default Search;
