import { connect } from 'react-redux';
import LoginLogout from './LoginLogout';
import { logout, login, loginFailed } from './actions';

const mapStateToProps = state => {
  return {
    user: state.login.user,
    loginFailed: state.login.loginFailed
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: ({email, password}) => {
      dispatch(login(email, password))
    },
    onClick: () => {
      dispatch(logout());
    },
    clearError: () => {
      dispatch(loginFailed(false))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLogout);
