import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { model } from '../helpers/';
import { getColorForChoice } from './colorScales';
import * as d3 from 'd3';
import styled from 'styled-components'
import { Canvas, Container } from './StyledComponents';
import useResize from './useResize';
import { reset, drawLine } from './canvas';

const BorderedContainer = styled(Container)`
  background-color: #fff;
  :hover {
    background-color: #eee
  }
  border: 1px solid #ddd;
`

function Preview(props) {
  const y = d3.scaleLinear();
  const x = d3.scaleUtc();
  const line = d3.line().curve(d3.curveMonotoneX);
  const { data, height } = props;
  const dates = model.getDates(data.trendline)
  const choices = model.choices(data.jobDescription, data.trendline, dates);
  const trendline = model.lineChart(data.trendline, choices, dates);
  const colorScale = getColorForChoice(data.jobDescription);
  const canvas = useRef()
  const container = useRef()
  const width = useResize(container, 0, 0)

  x.domain(d3.extent(dates)).range([0, width]);
  y.range([height, 0]);
  line.x(d => x(d.date)).y(d => {
    return y(d.value);
  });

  useEffect(() => {
    if (!canvas.current || !trendline) return;
    const context = canvas.current.getContext('2d');
    reset(context, width, height, 0, 0);
    trendline.map(data => drawLine(context, line, data, colorScale))
  });

  return <BorderedContainer ref={container} height={height} width={width}>
    <Canvas ref={canvas} height={height * 2} width={width * 2} />
  </BorderedContainer>

}

Preview.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
}

export default Preview;
