import { scaleLinear, scaleUtc } from 'd3';

function getScales(height, width, xDomain, yDomain, show) {
  const _xDomain = [show.startDate || xDomain[0], show.endDate || xDomain[1]]
  const x = scaleUtc().range([0, width]).domain(_xDomain)
  const originalX = scaleUtc().range([0, width]).domain(xDomain)
  const y = scaleLinear().range([height, 0]).domain(yDomain)
  return [x, y, originalX]
}

export default getScales;
