import { useEffect } from 'react';
import * as d3 from 'd3'
import { keys } from 'lodash';

const stylesKeys = ['background-color', 'fill', 'stroke'];

function useAnimate(ref, attributes, zeroDuration, skip) {
  useEffect(() => {
    if (skip) return;
    const selection = d3.select(ref.current);
    let t = selection.transition().duration(zeroDuration ? 0 : 250)
    function setAttrOrText(attribute, value) {
      if (skip) {
        selection.attr(attribute, value);
        return
      }
      if (attribute === 'text') {
        t.tween('text', function () {
          const node = d3.select(this)
          var i = d3.interpolate(node.text(), value);
          return function (t) {
            const text = i(t);
            const res = text.replace(/(<)?(.+)%/, (a, b, c) => {
              return `${b || ''}${Math.round(+c)}%`
            })
            node.text(res);
          };
        })
      } else if (stylesKeys.includes(attribute)) {
        t.style(attribute, value)
      }
      else {
        t.attr(attribute, value)
      }
    }

    keys(attributes).forEach(key => {
      setAttrOrText(key, attributes[key])
    })

  }, [attributes, skip, ref, zeroDuration])
}



export default useAnimate
