import React from 'react';
import { Header, Footer, CopyContainer, BannerButton } from './common';
import styled from 'styled-components'

const H1 = styled.h1`
  font-weight: 300;
  text-align: center;
  font-size: 3.6rem;
`

const Rect = styled.div`
  width: 400px;
  height: 200px;
  padding: 40px;
  padding-top: 10px;
  font-size: 1.8rem;
  text-align: center;
  margin: auto;
  margin-bottom: 100px;
`

const BB = styled(BannerButton)`
  background-color: ${props => props.theme.primaryBlue}
`

function FourZeroFour () {

  return (
    <div>
      <Header />
      <CopyContainer>
        <H1>We're Sorry!</H1>
        <Rect>
          <p>
            We can't find the page you're looking for.
            Please check the URL you entered to make sure it's spelled correctly. 
          </p>
          <p>
            You can view survey results by clicking below.
          </p>
          <BB href='/'>
            View Survey Results
          </BB>
        </Rect>
      </CopyContainer>
      <Footer />
    </div>
  );
}

export default FourZeroFour;
