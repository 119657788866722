import { axisBottom, axisLeft, format } from 'd3';
import { format as tickFormat, ticks } from './axisFormat';

function getAxes(x, y, yTickValues) {
  const width = x.range()[1];
  const xAxis = axisBottom().tickFormat(tickFormat(x.domain())).tickSizeOuter(0).ticks(ticks(x.domain(), width)).scale(x)
  const yAxis = axisLeft().tickSizeInner(-width).tickFormat(format('.0%')).scale(y).tickValues(yTickValues)
  return [xAxis, yAxis]
}

export default getAxes;
