import { MESSAGE_ERROR, MESSAGE_CLEAR } from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case MESSAGE_ERROR: {
      return {
        type: 'error',
        message: action.message
      }
    }
    case MESSAGE_CLEAR: {
      return {

      }
    }
    default:
      return state
  }
}
