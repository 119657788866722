import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { last, find, isArray } from 'lodash';
import { StyleSheet, css } from 'aphrodite';
import { descending, symbol } from 'd3';
import { setActiveChoice } from '../dashboard/actions'
import reduceOptions from './reduceOptions'

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    lineHeight: '15px',
    justifyContent: 'center'
  },
  label: {
    margin: '0 5px',
    fontSize: 12,
    lineHeight: 2,
    cursor: 'pointer'
  },
  faded: {
    opacity: 0.25
  }
})

const circle = symbol()();

function getLabels(props) {
  const {
    colorScale,
    dates,
    data,
    showNet
  } = props;
  const {
    displayNet: {
      subtrahend,
      minuend
    }
  } = props.jobDescription

  let labels = [];

  if (showNet) {
    const negativeColor = colorScale(isArray(subtrahend) ? subtrahend[0] : subtrahend);
    const positiveColor = colorScale(isArray(minuend) ? minuend[0] : minuend);
    if (isArray(minuend)) {
      labels.push({
        color: positiveColor,
        text: minuend.join('/')
      })
    } else {
      labels.push({
        color: positiveColor,
        text: minuend
      })
    }
    if (isArray(subtrahend)) {
      labels.push({
        color: negativeColor,
        text: subtrahend.join('/')
      })
    } else {
      labels.push({
        color: negativeColor,
        text: subtrahend
      })
    }
  } else {
    const latestDate = last(dates);
    const aboveOnePercent = reduceOptions(data);
    labels = aboveOnePercent.map(b => {
      const lastPoint = find(b.values, {
        date: latestDate
      })
      const y = lastPoint.value;
      return {
        color: colorScale(b.key),
        value: y,
        key: b.key,
        text: b.key
      }
    }).sort((a, b) => descending(a.value, b.value))
  }

  return labels;
}


function MobileKey (props) {
  const {
    showNet,
    activeChoice,
    disableSelect = false
  } = props;
  
  const labels = getLabels(props);

  const dispatch = useDispatch();

  function onClick(choice) {
    if (disableSelect) return;
    if (choice === activeChoice) {
      dispatch(setActiveChoice(null));
    } else {
      dispatch(setActiveChoice(choice))
    }

  }
  
  return (
    <div className={css(styles.root)}>
      {showNet && <span className={css(styles.label)}>Net: </span>}
      {
        labels.map((l,i) => {
          return <div key={i} className={css([styles.label, activeChoice && activeChoice !== l.key ? styles.faded : null])} onClick={() => onClick(l.key)}>
            <svg width={12} height={9}>
              <g transform='translate(4.51, 4.51)'>
                <path style={{fill: l.color}} d={circle}/>
              </g>
            </svg>
            <span>
              {l.text}
            </span>
          </div>
        })
      }
    </div>
  );
}

MobileKey.propTypes = {
  showNet: PropTypes.bool,
  activeChoice: PropTypes.string,
  disableSelect: PropTypes.bool
}

export default MobileKey;
