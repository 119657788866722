import React from 'react';
import PropTypes from 'prop-types';
import { monthDayYear, shortMonthYear } from './formatting';
import { viewportConstants } from './breakpoints';

const DateRangeSubtitle = ({ range = [[], []], viewport }) => {
  let dateFormat = monthDayYear;
  if (viewport === viewportConstants.PHONE) {
    dateFormat = shortMonthYear
  }
  return <span>
    {
      `${dateFormat(range[0])} — ${dateFormat(range[1])}`
    }
  </span>
};

DateRangeSubtitle.propTypes = {
  range: PropTypes.array.isRequired,
  viewport: PropTypes.symbol
};

export default DateRangeSubtitle;
