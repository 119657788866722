import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keys, isEmpty, uniq } from 'lodash'
import { fillOpacity } from './colorScales'
import getRanking from './getRanking';
import { getChoicesForMap } from '../helpers';
import styled from 'styled-components';
import { multiply, compose, map, assoc, concat } from 'ramda'
import uuid from 'uuid';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: middle;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 40px;
  flex-wrap: wrap;
`

const Text = styled.text`
  font-size: 10px;
  fill: #8c8a8a;
  text-anchor: middle;
  :first-child {
    text-anchor: start;
  }
  :last-child {
    text-anchor: end;
  }
`

const Line = styled.line`
  stroke: #fff;
`

const Label = styled.span`
  margin-right: 4px;
  display: inline-block;
  min-width: 100px;
  text-align: right;
`
const width = 175;

const getAllFirstPlaces = data => {
  return uniq(keys(data).map(d => {
    const { firstPlace } = getRanking(data[d]);
    return firstPlace
  })).sort()
}

const netPercentages = [-1, -0.5, 0, .5, 1];
const nonNetPercentages = [0, 0.25, 0.5, 0.75, 1]
const dividerPercentages = [0.25, 0.5, 0.75]
const nonNetStops = [{ offset: 0, opacity: 0 }, { offset: 100, opacity: 1 }]
const leftStops = [{ offset: 0, opacity: 1 }, { offset: 49, opacity: 0 }];
const rightStops = [{ offset: 51, opacity: 0 }, { offset: 100, opacity: 1 }];

const addColor = assoc('color')
const addPercentSign = (i, v) => i === 0 || i === 4 ? v + '%' : v;
const multiplyByWidth = multiply(width)
const createDividerLine = x => <Line key={x} x1={x} x2={x} y1="0" y2="20" />
const createDividerLines = map(compose(createDividerLine, multiplyByWidth))
const createStop = ({ offset, color, opacity }) => <stop key={offset} offset={`${offset}%`} style={{ stopColor: color, stopOpacity: fillOpacity(opacity) }} />
const createStops = map(createStop);

class MapKey extends Component {

  renderSvg({ label, stops, percentages }) {
    const url = uuid.v1()
    return <Container>
      <Label>{label}</Label>
      <svg height="35" width={width}>
        <defs>
          <linearGradient id={url} x1="0%" y1="0%" x2="100%" y2="0%">
            {createStops(stops)}
          </linearGradient>
        </defs>
        <g transform='translate(0, 10)'>
          <rect height={20} width={width} fill={`url(#${url})`} />
          {createDividerLines(dividerPercentages)}
        </g>
        <g>
          {percentages.map((p, i) => <Text key={i} x={(i / 4) * width} y={8}>{addPercentSign(i, p * 100)}</Text>)}
        </g>
      </svg>
    </Container>
  }

  net() {
    const { jobDescription } = this.props;
    const choices = getChoicesForMap(jobDescription.displayNet);
    const leftColor = jobDescription.choiceColors[choices.subtrahend[0]];
    const rightColor = jobDescription.choiceColors[choices.minuend[0]];
    const stops = concat(map(addColor(leftColor), leftStops), map(addColor(rightColor), rightStops));
    return this.renderSvg({ label: jobDescription.displayNet.label, stops, percentages: netPercentages })
  }

  nonNet() {
    const { jobDescription, data } = this.props;
    return <Container>
      {
        getAllFirstPlaces(data).map((choice) => {
          const color = jobDescription.choiceColors[choice];
          const stops = map(addColor(color), nonNetStops)
          return this.renderSvg({ label: choice, stops, percentages: nonNetPercentages })
        })
      }
    </Container>
  }

  render() {
    const { jobDescription, data } = this.props;
    if (!data) return null;
    return isEmpty(jobDescription.displayNet) ? this.nonNet() : this.net()
  }
}

MapKey.propTypes = {
  jobDescription: PropTypes.object,
  data: PropTypes.object
}

export default MapKey;
