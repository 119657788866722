import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Map from './Map';
import MapTooltip from './MapTooltip'
import MapKey from './MapKey'
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`

function MapWrapper(props) {
  const { height, store = 'dashboard', disableSelect = false, viewport } = props;
  const { mapData: data, jobDescription, dateRange, colorScale, mapChoices } = useSelector(state => state[store]);
  const [mouseData, setMouseData] = useState()

  return <Container>
    <Map
      setMouseData={setMouseData}
      data={data}
      jobDescription={jobDescription}
      disableSelect={disableSelect}
      height={height}
      colorScale={colorScale}
      viewport={viewport}
    />
    <MapKey jobDescription={jobDescription} data={data} />
    {mouseData && <MapTooltip
      x={mouseData.x}
      y={mouseData.y}
      colorScale={colorScale}
      dateRange={dateRange}
      jobDescription={jobDescription}
      data={mouseData.data}
      choices={mapChoices}
    />}
  </Container>
}

MapWrapper.propTypes = {
  height: PropTypes.number,
  store: PropTypes.string,
  disableSelect: PropTypes.bool,
  viewport: PropTypes.symbol
}

export default MapWrapper;
