import React from 'react';
import PropTypes from 'prop-types';
import { Loading, viewportConstants as vc, ActiveDemographics, DateRangeSubtitle, breakpoints, VoterType, SamplingFrame } from '../common'
import { Timeseries, NetTimeseries, StateMap, MobileKey } from '../charts'
import { StyleSheet, css } from 'aphrodite';
import { stringify } from 'querystring'
import { getTopMargin } from '../charts/labelHelpers';

const styles = StyleSheet.create({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 20,
    backgroundColor: '#fff'
  },
  questionTitle: {
    lineHeight: 2
  },
  samplingInfo: {
    fontWeight: 300,
    fontSize: 14,
    marginBottom: 5,
    ...breakpoints(vc.PHONE, { display: 'inline-block' })
  },
  questionBody: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: '10px 0',
    '.link': {
      color: '#000'
    }
  },
  mobileKey: {
    marginTop: '-20px'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    },
    fontSize: 'inherit',
  },
  footer: {
    paddingTop: 10,
    color: '#757575',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

class Embed extends React.Component {
  constructor() {
    super();
    this.state = {
      topHeight: 200,
      bottomHeight: 200
    };

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  resize() {
    if (!this.jobDescriptionNode || !this.footer) return;
    const { height: topHeight } = this.jobDescriptionNode.getBoundingClientRect();
    const { height: bottomHeight } = this.footer.getBoundingClientRect();

    this.setState({
      topHeight,
      bottomHeight
    });
  }

  componentWillMount() {
    const { liveEmbed, location: { payload, query } } = this.props;
    this.props.onWillMount(liveEmbed, payload.jobName, query)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trendline) setTimeout(() => this.resize());
  }

  link(text) {
    const { name, activeDemographics, show, dateRange, liveEmbed } = this.props;
    let queryParms = { ...activeDemographics, ...show, utm_campaign: 'embed' };

    if (!liveEmbed) queryParms = { ...queryParms, embedDate: dateRange[1] }
    return <a
      target='_blank'
      className={css(styles.link)}
      rel='noopener noreferrer'
      href={
        `${window.location.origin}/results/${name}?${
        stringify(queryParms)}`}>
      {text}
    </a>
  }

  render() {
    const { viewport } = this.props;
    if (!this.props.trendline) return <Loading />
    // const colorScale = color(this.props);
    const chartHeight = 480 - this.state.topHeight - this.state.bottomHeight - getTopMargin(this.props.annotations, this.props.show.annotations)
    const isPhone = viewport === vc.PHONE;
    return (
      <div className={css(styles.root)}>
        <div className={css(styles.container)}>
          <div ref={node => this.jobDescriptionNode = node}>
            <div className={css(styles.questionBody)}>
              {this.link(this.props.questionBody)}
            </div>
            <div className={css(styles.samplingInfo)}>
              <SamplingFrame style={{ letterSpacing: 'normal' }} label={this.props.jobDescription.geo}>
                {isPhone ? <br /> : ','}
              </SamplingFrame>
              <VoterType {...this.props.jobDescription} />
              <span>:&nbsp;</span>
              <DateRangeSubtitle range={this.props.dateRange} viewport={viewport} />
            </div>
            <div>
              <ActiveDemographics wrap={false} demographics={{ active: this.props.activeDemographics, available: this.props.availableDemographics }} />
            </div>
          </div>
          <div>
            {
              this.props.show.net ?
                <NetTimeseries
                  store='embeds'
                  marginLeft={40}
                  height={chartHeight}
                  disableSelect={true}
                  viewport={viewport} />
                : this.props.show.map ? <StateMap
                  store='embeds'
                  marginLeft={0}
                  marginRight={0}
                  height={isPhone ? 250 : 402}
                  disableSelect={true}
                  isPhone={isPhone}
                  viewport={viewport}
                />
                  : <Timeseries
                    store={'embeds'}
                    marginLeft={40}
                    disableSelect={true}
                    height={chartHeight}
                    viewport={viewport}
                  />
            }
          </div>
          <div ref={node => this.footer = node}>
            <div className={css(styles.mobileKey)}>
              {isPhone && !this.props.show.map &&
                <MobileKey
                  data={this.props.trendline}
                  disableSelect={true}
                  activeChoice={this.props.show.choice}
                  showNet={this.props.show.net}
                  jobDescription={this.props.jobDescription}
                  colorScale={this.props.colorScale}
                  dates={this.props.dates} />}
            </div>
            <div className={css(styles.footer)}>
              {this.link('Data provided by Civiqs')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
}

Embed.propTypes = {
  viewport: PropTypes.symbol,
  annotations: PropTypes.array,
  choices: PropTypes.array,
  colorScale: PropTypes.func,
  dateRange: PropTypes.array,
  dates: PropTypes.array,
  embedDate: PropTypes.number,
  jobDescription: PropTypes.object,
  netData: PropTypes.array,
  lineChartData: PropTypes.array,
  show: PropTypes.object,
  liveEmbed: PropTypes.bool,
  activeDemographics: PropTypes.object,
  availableDemographics: PropTypes.array,
  name: PropTypes.string,
  questionBody: PropTypes.string,
  onWillMount: PropTypes.func,
  location: PropTypes.object
}

export default Embed;
