import * as actions from './actions';
import { NOT_FOUND } from 'redux-first-router';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.HOME:
      return {
        activeRoute: actions.HOME
      }
    case actions.DASHBOARD:
      return {
        activeRoute: actions.DASHBOARD
      }
    case actions.LOGIN:
      return {
        activeRoute: actions.LOGIN
      }
    case actions.EMBED:
      return {
        activeRoute: actions.EMBED
      }
    case actions.PROMO:
      return {
        activeRoute: actions.PROMO
      }
    case actions.TOS:
      return {
        activeRoute: actions.TOS
      }
    case actions.ABOUT:
      return {
        activeRoute: actions.ABOUT
      }
    case actions.METHODOLOGY:
      return {
        activeRoute: actions.METHODOLOGY
      }
    case actions.WHAT_WE_OFFER:
      return {
        activeRoute: actions.WHAT_WE_OFFER
      }
    case actions.JOIN_IN:
      return {
        activeRoute: actions.JOIN_IN
      }
    case actions.SURVEY_TOS:
      return {
        activeRoute: actions.SURVEY_TOS
      }
    case actions.JOBS:
      return {
        activeRoute: actions.JOBS
      }
    case actions.PRIVACY:
      return {
        activeRoute: actions.PRIVACY
      }
    case actions.FAQ:
      return {
        activeRoute: actions.FAQ
      }
    case actions.THANKS:
      return {
        activeRoute: actions.THANKS
      }
    case actions.REPORTS:
      return {
        activeRoute: actions.REPORTS
      }
    case actions.REPORTS_PAGED: {
      return {
        activeRoute: actions.REPORTS_PAGED
      }
    }
    case actions.REPORT:
      return {
        activeRoute: actions.REPORT
      }
    case actions.EMPTY:
      return {
        activeRoute: actions.EMPTY
      }
    case actions.FOUR_ZERO_FOUR:
      return {
        activeRoute: actions.FOUR_ZERO_FOUR
      }
    case actions.TEST_EXCEPTION:
      return {
        activeRoute: actions.TEST_EXCEPTION
      }
    case actions.NOT_FOUND:
    case NOT_FOUND:
      return {
        activeRoute: actions.NOT_FOUND
      }

    default:
      return state;
  }
}

export default reducer;
