import * as d3 from 'd3';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestRemoveSharedDate, setActiveChoice, setBrushVisibility } from '../dashboard/actions';
import animate from './animate';
import Attribution from './Attribution';
import Axes from './Axes';
import Brush from './Brush';
import { annotationClipPath, chartClipPath, drawAnnotations, drawArea, drawCursor, drawLabels, drawLine, drawMask, getCollisions, reset, translateMouse } from './canvas';
import getScales from './getScales';
import { getYDomainForTrendline } from './getYDomain';
import { getAnnotationHover, getClickedLabel, getRightMargin, getTopMargin, labelsToRows, sharedDateWasClicked, timeseriesLabels } from './labelHelpers';
import reduceOptions from './reduceOptions';
import { Canvas, Container } from './StyledComponents';
import useBrush from './useBrush';
import useMouse from './useMouse';
import usePrevious from './usePrevious';
import useResize from './useResize';
import { allowBrush, setContainerHeight, setCursor } from './utilities';

function Timeseries(props) {
  const { store = 'dashboard', height, marginBottom = 20, marginLeft = 35, viewport, disableSelect } = props;
  const { dateRange, show, trendline, colorScale, choices, annotations, jobDescription, brushVisibility } = useSelector(state => state[store])
  const container = useRef()
  const canvas = useRef()
  const brush = useRef()

  const dispatch = useDispatch();
  const interpolater = d3.curveBasis;

  const brushHeight = 60;

  const [choiceLock, setChoiceLock] = useState(false);
  const [activeAnnotation, setActiveAnnotation] = useState();
  const [tempChoice, setTempChoice] = useState();

  const marginRight = getRightMargin([jobDescription.displayNet.label, ...choices], viewport)
  const marginTop = getTopMargin(annotations, show.annotations)
  const margins = { top: marginTop, marginBottom: marginBottom, left: marginLeft, right: marginRight }

  const width = useResize(container, marginLeft, marginRight)

  const totalHeight = height + marginTop + marginBottom;
  const totalWidth = width + marginLeft + marginRight;

  const reducedOptions = reduceOptions(trendline);
  const [yDomain, yTickValues] = getYDomainForTrendline(show.zoomIn, reducedOptions);
  const [brushYDomain] = getYDomainForTrendline(true, reducedOptions);

  let [x, y, originalX] = getScales(height, width, dateRange, yDomain, show);

  const intermediateDates = useBrush(brush, originalX, width);

  if (intermediateDates) x.domain(intermediateDates);

  const brushY = y.copy().domain(brushYDomain).range([brushHeight, 0]);

  const [mouseDate] = useMouse(container, x, dateRange, show, margins, height);

  const lineGenerator = useRef();
  const areaGenerator = useRef()


  const brushLineGenerator = d3.line().x(d => originalX(d.date)).y(d => brushY(d.value) - 0.5).curve(interpolater);
  const brushAreaGenerator = d3.area().x(d => originalX(d.date)).y0(d => brushY(d.low) - 0.5).y1(d => brushY(d.high) - 0.5)

  const wrappedLabels = useRef([])

  const previous = usePrevious(reducedOptions);
  const previousY = usePrevious(yDomain);

  const _annotations = getCollisions(annotations, x, 10, activeAnnotation)

  const maxDate = intermediateDates ? intermediateDates[1] : mouseDate;

  function toggleBrushVisibility() {
    dispatch(setBrushVisibility(!brushVisibility));
  }

  function draw(context, data) {
    const labels = timeseriesLabels(data, maxDate, x, y, colorScale, viewport);
    wrappedLabels.current = labelsToRows(labels, height);

    reset(context, totalWidth, totalHeight, marginLeft, marginTop);
    chartClipPath(context, width, height, 0, 0, () => {
      show.uncertainty && data.map(data => drawArea(context, areaGenerator.current, data, colorScale, show, tempChoice))
      data.map(data => drawLine(context, lineGenerator.current, data, colorScale, show, tempChoice))
      drawMask(context, x, maxDate, width, height)
    })
    annotationClipPath(context, width, height, -marginTop, () => {
      show.annotations && drawAnnotations(context, _annotations, x, height, totalWidth, totalHeight, marginLeft, marginTop);

    });
    drawLabels(context, wrappedLabels.current, show, tempChoice);
    drawCursor(context, x, height, maxDate)

  }

  useEffect(() => {
    if (!canvas.current || !trendline || !previous || width === 0) return;
    const context = canvas.current.getContext('2d');

    animate(previousY, yDomain, (yDomain) => {
      let _y = y.domain(yDomain);
      lineGenerator.current = d3.line().x(d => x(d.date) + 0.5).y(d => _y(d.value) - 0.5).curve(interpolater);
      areaGenerator.current = d3.area().x(d => x(d.date) - 0.5).y0(d => _y(d.low) - 0.5).y1(d => _y(d.high) - 0.5).curve(interpolater)
    })

    animate(previous, reducedOptions, (trendline) => {
      draw(context, trendline);
    })
  });

  useEffect(() => {
    if (show.choice) {
      setChoiceLock(true)
    }
  }, [])

  function onClick(e) {
    if (disableSelect) return;
    const coords = translateMouse(canvas.current, e, margins);
    const label = getClickedLabel(coords, wrappedLabels.current, marginRight);
    if (label) {
      if (label.key === show.choice) {
        if (choiceLock) {
          dispatch(setActiveChoice(null))
          setChoiceLock(false);
          setTempChoice(null);
        } else {
          setChoiceLock(true)
          dispatch(setActiveChoice(label.key))
        }
      } else {
        setChoiceLock(true)
        dispatch(setActiveChoice(label.key))
      }
    }

    if (sharedDateWasClicked(coords, annotations)) {
      dispatch(requestRemoveSharedDate())
    }
  }

  function onMouseMove(e) {
    const coords = translateMouse(canvas.current, e, margins, height);
    const label = getClickedLabel(coords, wrappedLabels.current);
    const activeAnnotation = getAnnotationHover(coords, _annotations);
    setCursor(coords, width, height, canvas)
    setActiveAnnotation(activeAnnotation);

    if (choiceLock) return;
    if (!label && tempChoice) {
      setTempChoice(null);
    }
    if (label && tempChoice !== label.key) {
      setTempChoice(label.key)
    }
  }

  return <Container ref={container} width={totalWidth} height={setContainerHeight(totalHeight, brushVisibility)} onClick={onClick} onMouseMove={onMouseMove}>
    <Axes x={x} y={y} xAxisPosition={`${marginLeft}, ${height + marginTop} `} yAxisPosition={`${marginLeft}, ${marginTop} `} yTickValues={yTickValues} />
    <Canvas height={totalHeight * 2} width={totalWidth * 2} ref={canvas} />
    {allowBrush(store) && <Brush x={originalX} show={show} dateRange={dateRange} marginLeft={marginLeft} marginRight={marginRight} height={brushHeight} visible={brushVisibility} onToggleVisibility={toggleBrushVisibility}>

      <g>
        <g ref={brush} />
        {reducedOptions.map(d => {
          return <g key={d.key} style={{ pointerEvents: 'none' }}>
            <path d={brushAreaGenerator(d.values)} style={{ fill: colorScale(d.key), stroke: 'none', opacity: 0.1 }} />
            <path d={brushLineGenerator(d.values)} style={{ fill: 'none', stroke: colorScale(d.key), strokeWidth: 2 }} />
          </g>
        })}
      </g>
    </Brush>
    }
    <Attribution top={totalHeight} left={10} />
  </Container>
}

Timeseries.propTypes = {
  store: PropTypes.string,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
  height: PropTypes.number,
  disableSelect: PropTypes.bool,
  viewport: PropTypes.symbol
}

export default Timeseries;