import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import { StyleSheet, css } from 'aphrodite';
import { lightBlueGrey, Loading } from '../common';

const styles = StyleSheet.create({
  root: {
    border: `1px solid ${lightBlueGrey}`,
    fontSize: '12px',
    height: '30px',
    lineHeight: '30px',
    cursor: 'pointer',
    padding: '0 10px',
    boxSizing: 'border-box ',
    textAlign: 'center',
    color: lightBlueGrey,
    ':hover': {
      backgroundColor: lightBlueGrey,
      color: '#fff'
    }
  },
  embedCode: {
    padding: '10px 15px',
    lineHeight: 1.2,
  },
  strong: {
    margin: '10px 0',
    display: 'block'
  },
  showPreview: {
    display: 'block'
  }
})

class ShareChartButton extends React.Component {

  constructor() {
    super();

    this.state = {
      embedRequested: false
    }
    this.onClick = this.onClick.bind(this);
    this.onClickCode = this.onClickCode.bind(this);
    this.onClickTogglePreview = this.onClickTogglePreview.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {

  }
  onClick() {
    this.setState({
      embedRequested: true
    }, () => {
      this.props.onClick();
    })
  }

  onClickCode() {
    var range = document.createRange();
    range.selectNode(document.getElementById('embed'));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  onClickTogglePreview() {
    this.setState({
      showPreview: !this.state.showPreview
    })
  }

  handleClose() {
    this.setState({
      embedRequested: false
    }, () => {
      this.props.onClose();
    })
  }

  render() {
    const { shareUrl } = this.props;
    const { embedRequested, showPreview } = this.state;
    return (
      <div>
        <div className={css(styles.root)} onClick={this.onClick}>
          SHARE THIS CHART
        </div>
        <Dialog open={embedRequested} title='Embed Chart' contentStyle={{ width: '1500px' }} bodyStyle={{ overflowY: 'scroll' }} onRequestClose={this.handleClose}>
          {embedRequested && !shareUrl ? <Loading /> :
            <div>
              <div className={css(styles.embedCode)}>
                <strong className={css(styles.strong)}>Click & Copy the Snippet Below</strong>
                <div onClick={this.onClickCode} id='embed'>
                  <div style={{ fontFamily: 'monospace' }}>
                    {`<iframe src="${shareUrl}" height="550px" width="100%" frameBorder="0"></iframe>`}
                  </div>
                </div>
              </div>
              <div>
                <IconButton onClick={this.onClickTogglePreview} tooltip={showPreview ? 'Hide Preview' : 'Show Preview'} iconClassName='material-icons'>{
                  showPreview ? 'visibility_off' : 'visibility'
                }</IconButton>
              </div>
              <div style={{ display: showPreview ? 'block' : 'none', paddingBottom: '50px' }}>
                <Divider />
                {showPreview && <iframe id='embed-iframe' src={shareUrl} height='550px' width='100%' frameBorder="0"></iframe>}
              </div>
            </div>
          }
        </Dialog>
      </div>
    );
  }


}
ShareChartButton.propTypes = {
  onClick: PropTypes.func,
  shareUrl: PropTypes.string,
  onClose: PropTypes.func
};

export default ShareChartButton;
