import { isEmpty } from 'lodash';
import { getMinuendsAndSubtrahends } from './map';

const getNet = (jobDescription, topline, dates) => {
  const { displayNet } = jobDescription
  if (isEmpty(displayNet)) return [];
  const { minuend, subtrahend } = getMinuendsAndSubtrahends(displayNet);

  return dates.map(date => {
    const point = topline.trendline[date];
    return {
      date,
      value: minuend(point) - subtrahend(point),
      label: displayNet.label
    }
  })

}

export default getNet;
