import styled from 'styled-components';
import { primaryBlue } from './colors';
import mediaQueries from './mediaQueries';

export const Heading = styled.h1`
  color:  #8c8c8c
`

export const BlueBanner = styled.div`
  background-color: ${primaryBlue};
  color: #fff;
  min-height: 220px;
  width: 100%;
  display: grid;
  align-items: center;
  font-size: 6rem;
  font-weight: 600;
  text-align: center;
  ${mediaQueries.tablet`font-size:4rem`}
`

export const BannerButton = styled.a`
  border: 1px solid ${props => props.inverse ? props.theme.primaryBlue : '#fff'};
  color: ${props => props.inverse ? props.theme.primaryBlue : '#fff'};
  transition: color 0.5s, background-color 0.5s;
  :hover {
    border: 1px solid ${props => props.inverse ? '#fff' :  props.theme.primaryBlue };
    color: ${props => props.inverse ? '#fff' : props.theme.primaryBlue};
    background-color: ${props => props.inverse ? props.theme.primaryBlue : '#fff' };
  }
  border-radius: 2px;
  max-width: 200px;
  margin: auto;
  padding: 0px 20px;
  display: block;
  line-height: 45px;
  text-align: center;
  font-size: 1.4rem;
  background-color: transparent;
`

export const CopyContainer = styled.div`
  max-width: 750px;
  margin: auto;
  padding: 20px;
  color: ${props => props.theme.color};
  font-family: Lato,sans-serif;
  font-weight: 400;
  line-height: 26px;
  h1 {
    margin: 4rem 0 2rem 0
  }
  h2 {
    color: ${props => props.theme.color};
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 35px
  }
  .pull-quote {
    color: #c4c6c7;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 32px;
    margin: 42px;
    text-align: center
  };

`

export const Subtitle = styled.h2`
  color: ${props => props.theme.color};
  margin: auto;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const P = styled.p`
  color: #616a73;
  font-family: Lato,sans-serif;
  font-weight: 400;
  padding: 0 10px;
  text-align: center;
  line-height: 26px;
  > p > a: {
    color: rgb(35, 83, 125) !important;
  }
`

export const ReportDate = styled.div`
  margin-top: 10px;
  font-size: 1.4rem;
  color: #737373;
`

export const Aside = styled.div`
  position: absolute;
  width: 100px;
  height: 250px;
  right: 0;
  top: 100px;
  border: 1px solid red;
`