import Crosstabs from './Crosstabs';
import { addDemographic } from './actions';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  const { choices, crossTabData, population, colorScale, restrictedAccess, dateRange, demographics } = state.dashboard;
  const { viewport } = state.app;
  return {
    viewport,
    choices,
    data: crossTabData,
    dateRange,
    population,
    colorScale,
    restrictedAccess,
    demographics
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickRow: (dimension, value) => {
      dispatch(addDemographic(dimension, value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crosstabs);
