import { actionGenerator } from '../common';

export const CALL_SEARCH_API = 'search:CALL_SEARCH_API';
export const SET_RESULTS = 'search:SET_RESULTS';
export const SET_IS_SEARCHING = 'search:SET_IS_SEARCHING';
export const SET_NO_RESULTS = 'search:SET_NO_RESULTS';
export const RESET_DEFAULTS = 'search:RESET_DEFAULTS';
export const TOGGLE_SEARCH_BAR = 'search:TOGGLE_SEARCH_BAR';
export const NO_MORE_RESULTS = 'search:NO_MORE_RESULTS';
export const SET_SEARCH_VALUE = 'search:SET_SEARCH_VALUE';
export const SET_FROM_INDEX = 'search:SET_FROM_INDEX';
export const INCREMENT_FROM_INDEX = 'search:INCREMENT_FROM_INDEX';
export const SET_LOAD_PARAMS = 'search:SET_LOAD_PARAMS';
export const RESULTS_UNMOUNT = 'search:RESULTS_UNMOUNT';
export const SEARCH_SUBMIT = 'search:SEARCH_SUBMIT';
export const FETCH_TOPLINES = 'search:FETCH_TOPLINES';

export const callSearchApi = (mixPanelOrigin) => actionGenerator(CALL_SEARCH_API, {mixPanelOrigin});
export const setResults = results => actionGenerator(SET_RESULTS, {results});
export const setIsSearching = flag => actionGenerator(SET_IS_SEARCHING, {flag});
export const setNoResults = flag => actionGenerator(SET_NO_RESULTS, {flag});
export const resetDefaults = () => actionGenerator(RESET_DEFAULTS);
export const toggleSearchBar = flag => actionGenerator(TOGGLE_SEARCH_BAR, {flag});
export const noMoreResults = flag => actionGenerator(NO_MORE_RESULTS, {flag});
export const setSearchValue = value => actionGenerator(SET_SEARCH_VALUE, {value});
export const setFromIndex = fromIndex => actionGenerator(SET_FROM_INDEX, {fromIndex});
export const incrementFromIndex = () => actionGenerator(INCREMENT_FROM_INDEX);
export const setLoadParams = (initialLoadQty, jobsPerRow) => actionGenerator(SET_LOAD_PARAMS, {initialLoadQty, jobsPerRow});
export const resultsUnmount = () => actionGenerator(RESULTS_UNMOUNT);
export const searchSubmit = (value, mixPanelOrigin) => actionGenerator(SEARCH_SUBMIT, {value, mixPanelOrigin});
export const fetchToplines = (data, results, exhausted) => actionGenerator(FETCH_TOPLINES, {data, results, exhausted});
