import publicUrl from './publicUrl';
import { useState, useEffect } from 'react';
import remark from 'remark'
import html from 'remark-html';
import frontmatter from 'remark-frontmatter'
import extract from 'remark-extract-frontmatter';
import { parse } from 'yaml'

const md = remark()
  .use(html)
  .use(frontmatter, ['yaml'])
  .use(extract,{yaml: parse})


function useMarkdown (path) {
  const [parsed, setParsed ] = useState({});
  useEffect(() => {
    async function fetchData() {
      const data = await fetch(publicUrl(path));
      const text = await data.text();
      const processed = await md.process(text);
      setParsed(processed)
    }
    fetchData();
  }, [])
  
  return parsed;
}

export default useMarkdown;