import { demographicToString, demographicsToPredictors } from './demographics';
import { last, map, includes, reject, keys } from 'lodash';
const restrictedValue = -1

export const sortToChoiceOrder = (data, choices, restricted) => {
  return choices.map(choice => {
    return {
      key: choice,
      value: restricted ? restrictedValue : data[choice]
    }
  })
}

export const globalTotal = (rawData, dates, choices) => {
  const getLatestPoints = (rawData) => {
    const latestDate = last(dates);
    return choices.map(choice => {
      return {
        key: choice,
        value: rawData.trendline[latestDate][choice]
      }
    })
  }
  return {
    key: 'All Respondents',
    values: [
      {
        key: 'Total',
        values: getLatestPoints(rawData)
      }
    ]
  }
}

export const selectedDemographicTotal = (demographics, crossTabAPIHit, choices, restricted) => {
  const activePredictors = demographicsToPredictors(demographics);
  const predictorString = demographicToString(activePredictors);
  const totalData = restricted ? null : crossTabAPIHit[predictorString];

  const filteredTotal = {
    key: 'Filtered Totals',
    values: [
      {
        key: 'Total',
        values: sortToChoiceOrder(totalData, choices, restricted)
      }
    ]
  }
  return filteredTotal
}

export const demographicsToCrosstabulate = ({ available, active }) => reject(
  available,
  ({ demographic }) => includes(keys(active), demographic)
)

export const lowLevelCrosstabs = (apiResponse, demographics, predictors, choices, restricted) => {
  const rowsForDemographicValue = function (demographicValue, demographic) {
    const dataForDemographic = (() => {
      if (restricted) return null;
      const demographicDictionary = { ...predictors }
      demographicDictionary[demographic.predictor] = demographicValue;
      return apiResponse[demographicToString(demographicDictionary)]
    })();

    return {
      key: demographicValue,
      values: sortToChoiceOrder(dataForDemographic, choices, restricted)
    }
  }

  return map(demographicsToCrosstabulate(demographics), function (demographic) {
    return {
      key: demographic.demographic,
      label: demographic.label,
      values: map(
        demographic.values,
        (value) => rowsForDemographicValue(value, demographic)
      )
    }
  });

}
