import { connect } from 'react-redux';
import Login from './Login';
import { login, loginFailed } from './actions';

const mapStateToProps = state => {
  return {
    loginFailed: state.login.loginFailed
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: ({email, password}) => {
      dispatch(login(email, password))
    },
    clearError: () => {
      dispatch(loginFailed(false))
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
