import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Span = styled.span`
  font-size: 12px;
  color: rgb(152, 162, 175);
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
`

const Attribution = ({ top, left }) => (
  <Span top={top} left={left} className='icon-civiqs-logo' ></Span>
);

Attribution.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number
}

export default Attribution;
