import { SET_LOGGEDIN, SET_USER, LOGIN_FAILED } from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_LOGGEDIN: {
      return {
        ...state,
        loggedIn: action.user 
      }
    }
    case SET_USER: {
      return {
        ...state,
        user: action.user
      }
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: action.status
      }
    }
    default:
      return state
  }
}
