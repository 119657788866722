import { useEffect, useState } from 'react';

function useResize(ref, marginLeft, marginRight) {
  const startWidth = 0;
  const [innerWidth, setInnerWidth] = useState(startWidth)
  useEffect(() => {
    function resize() {
      if (!ref.current) return;
      const { width } = ref.current.parentNode.getBoundingClientRect();
      setInnerWidth(width - marginLeft - marginRight);
    }
    window.addEventListener('resize', resize);
    setTimeout(() => resize());
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [marginLeft, marginRight, innerWidth, ref])

  return innerWidth;
}

export default useResize;
