import { select } from 'd3';
import { useEffect } from 'react';

const duration = 250;

function useScales(xRef, yRef, xAxis, yAxis) {
  useEffect(() => {
    select(yRef.current).transition().duration(duration).call(yAxis)
    select(xRef.current).call(xAxis)
  })
}

export default useScales;
