import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

export const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => props.pointerEvents ? 'all' : 'none'};
`

export const Background = styled.rect`
  fill: transparent;
  cursor: crosshair;
  `

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: ${ props => props.width / 2}px;
  height: ${ props => props.height / 2}px;
`