import React, { Component } from 'react';
import { primaryBlue } from './colors';
import Logo from './Logo';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'fixed',
    top: 50,
    left: 100,
    right: 100,
    bottom: 50
  },
  wrapper: {
    background: '#f8f8f8',
    padding: 50,
    width: 'calc(100vw - 10%)',
    border: '1px solid #ddd',
    height: '100%',

  },
  logo: {
    color: primaryBlue,
    fontSize: 40,
    height: 60
  },
  a: {
    textDecoration: 'none',
    color: primaryBlue,
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline'
    }
  }
})


class NotFound extends Component {

  render() {
    return (
      <div className={css(styles.root)}>
        <div className={css(styles.wrapper)}>
          <div className={css(styles.logo)}>
            <Logo />
          </div>
          <div className={css(styles.noFlex)}>
            <span>This chart is not available. More data is available </span>
            <a className={css(styles.a)} rel='noopener noreferrer' target='_blank' href="home">here</a>.
          </div>
        </div>
      </div>
    );
  }

}

export default NotFound;
