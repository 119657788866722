import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CrossTab, MobileCrosstabs } from '../charts';
import { viewportConstants as vc } from '../common';


class Crosstabs extends Component {

  render() {
    const { choices, population, data, colorScale, onClickRow, viewport, restrictedAccess, dateRange, demographics } = this.props;
    const isPhone = viewport === vc.PHONE;
    if(isPhone) {
      return <MobileCrosstabs
        data={data}
        colorScale={colorScale}
        restrictedAccess={restrictedAccess}
        demographics={demographics}
        dateRange={dateRange}
        population={population}/>
    } else {
      return <CrossTab
        data={data}
        population={population}
        colorScale={colorScale}
        choices={choices}
        dateRange={dateRange}
        restrictedAccess={restrictedAccess}
        demographics={demographics}
        onClickRow={onClickRow} />
    }
  }
}

Crosstabs.propTypes = {
  annotations: PropTypes.array,
  choices: PropTypes.array,
  colorScale: PropTypes.func,
  data: PropTypes.object,
  population: PropTypes.number,
  onClickRow: PropTypes.func,
  viewport: PropTypes.symbol,
  demographics: PropTypes.object,
  restrictedAccess: PropTypes.bool,
  dateRange: PropTypes.array
}

export default Crosstabs
