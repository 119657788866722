const PHONE_MAX = 699;
const TABLET_PORTRAIT_MIN = 700;
const TABLET_PORTRAIT_MAX = 992;
const TABLET_LANDSCAPE_MIN = 993;
const TABLET_LANDSCAPE_MAX = 1199;
const DESKTOP_MIN = 1200;
const DESKTOP_MAX = 1799;
const BIG_DESKTOP_MIN = 1800;

export const widthConstants = {
  PHONE_MAX,
  TABLET_PORTRAIT_MIN,
  TABLET_PORTRAIT_MAX,
  TABLET_LANDSCAPE_MIN,
  TABLET_LANDSCAPE_MAX,
  DESKTOP_MIN,
  DESKTOP_MAX,
  BIG_DESKTOP_MIN
}

const PHONE = Symbol('phone');
const TABLET_PORTRAIT = Symbol('tabletPortrait');
const TABLET_LANDSCAPE = Symbol('tabletLandscape');
const DESKTOP = Symbol('desktop');
const BIG_DESKTOP = Symbol('bigDesktop');

export const viewportConstants = {
  PHONE,
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
  DESKTOP,
  BIG_DESKTOP
}

const phone = (style = {}) => {
  return {
    [[`@media (max-width: ${PHONE_MAX}px)`]]: {
      ...style
    }
  }
}

const tabletPortrait = (style = {}) => {
  return {
    [`@media (max-width: ${TABLET_PORTRAIT_MAX}px) and (min-width: ${TABLET_PORTRAIT_MIN}px)`]: {
      ...style
    }
  }
}

const tabletLandscape = (style = {}) => {
  return {
    [`@media (max-width: ${TABLET_LANDSCAPE_MAX}px) and (min-width: ${TABLET_LANDSCAPE_MIN}px)`]: {
      ...style
    }
  }
}

const desktop = (style = {}) => {
  return {
    [`@media (max-width: ${DESKTOP_MAX}px) and (min-width: ${DESKTOP_MIN}px)`]: {
      ...style
    }
  }
}

const bigDesktop = (style = {}) => {
  return {
    [`@media (min-width: ${BIG_DESKTOP_MIN}px)`]: {
      ...style
    }
  }
}

const styles = {
  [PHONE]: phone,
  [TABLET_LANDSCAPE]: tabletLandscape,
  [TABLET_PORTRAIT]: tabletPortrait,
  [DESKTOP]: desktop,
  [BIG_DESKTOP]: bigDesktop
}

const breakpoints = (...args) => {
  const style = args.pop();
  let obj = {};
  for (var arg of args) {
    obj = {
      ...obj,
      ...styles[arg](style)
    }
  }
  return obj;
}

export default breakpoints;
