import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Logo, primaryBlue, breakpoints, viewportConstants } from '../common/';
import LoginError from './LoginError';

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left:0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  form : {
    width: 500,
    ...breakpoints(viewportConstants.PHONE, {width: 300}),
    height: '400px'
  },
  input : {
    height: 50,
    width: '100%',
    display: 'block',
    outline: 'none',
    border: '1px solid #ddd',
    fontSize: 14,
    padding: 10,
    boxSizing: 'border-box',
    borderRadius: 2,
    marginBottom: 40,
    '-webkit-appearance': 'none',
    ':focus': {
      border: `1px solid ${primaryBlue}`
    }
  },
  submit: {
    color: '#fff',
    '-webkit-appearance': 'none',
    textAlign: 'center',
    backgroundColor: primaryBlue,
    border: 'none',
    fontFamily: 'Lato',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  logo: {
    color: primaryBlue,
    fontSize: 48,
    marginBottom: 40
  },
  label: {
    marginBottom: 5,
    display: 'block'
  }
})

class Login extends React.Component {
  constructor () {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  handleChange() {
    this.props.clearError();
  }


  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit({
      email: this.refs.email.value,
      password: this.refs.password.value
    })
  }

  render() {
    const { loginFailed } = this.props;
    return (
      <div className={css(styles.root)}>
        <form className={css([styles.form])} ref='form' action="" onSubmit={this.onSubmit}>
          <div className={css(styles.logo)}>
            <Logo />
          </div>
          <label className={css(styles.label)} htmlFor="email">Email</label>
          <input className={css(styles.input)} ref='email' name='email' type="text" onChange={this.handleChange}/>
          <label className={css(styles.label)} htmlFor="password">Password</label>
          <input className={css(styles.input)} ref='password' name='password' type="password" onChange={this.handleChange}/>
          <input className={css([styles.input, styles.submit])} type='submit' value='Sign In'></input>
          <LoginError loginFailed={loginFailed}/>
        </form>
      </div>
    );
  }

}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clearError: PropTypes.func,
  loginFailed: PropTypes.bool
}

export default Login;
