import { connect } from 'react-redux';
import Embed from './Embed';
import { goToDashboard, goToNotFound } from '../routing';
import { onWillMount } from './actions';

const mapStateToProps = state => {
  return {
    ...state.app,
    ...state.embeds,
    location: state.location
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWillMount:(liveEmbed, jobName, query) => {
      dispatch(onWillMount(liveEmbed, jobName, query))
    },
    onClick: url => {
      dispatch(goToDashboard(null, {embedUrl: url}))
    },
    onError: () => {
      dispatch(goToNotFound())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed);
