import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, keys, upperFirst, find } from 'lodash';
import mediaQueries from './mediaQueries';
import styled from 'styled-components';

const Root = styled.div`
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  min-height: 30px;
  ${mediaQueries.phone`display: ${props => props.display ? 'flex' : 'none'}`};
  flex-wrap: wrap;
`

const Box = styled.div`
  box-sizing: border-box;
  margin: 0 5px 0 0;
  cursor: pointer;
  display: inline-block;
  line-height: 25px;
`

const Title = styled.span`
  font-weight: bold;
`

const ClearAll = styled(Box)`
  margin: 0 5px;
  color: #979BA1;
  display: ${props => props.removable ? 'inline' : 'none'};
`

const X = styled(Box)`
  background: #f7f7f7;
  padding: 5px;
  margin-left: 2px;
  margin-right: 10;
  display: ${props => props.removable ? 'inline' : 'none'};
`

class ActiveDemographics extends React.Component {

  label(key, activeDemographics, availableDemographics) {

    return <Box
      key={key}
      onClick={() => {
        if (!this.props.removable) return;
        this.props.onRequestDelete(key, activeDemographics[key])
      }}
    >
      <Title>
        {`${upperFirst(find(availableDemographics, { demographic: key }).label)}: `}
      </Title>
      <span>
        {`${upperFirst(activeDemographics[key])}`}
      </span>
      <X removable={this.props.removable}>
        {'\u2715'}
      </X>
    </Box>
  }

  labels(activeDemographics, availableDemographics) {
    let labels = [
      <Box style={{ fontWeight: 'lighter' }} key='refine-by'>
        Refined by:
      </Box>
    ]
    if (isEmpty(activeDemographics)) return;

    keys(activeDemographics).forEach(key => {
      labels.push(this.label(key, activeDemographics, availableDemographics));
    });

    labels.push(
      <ClearAll removable={this.props.removable} onClick={() => this.props.onRequestDeleteAll()}>
        Clear All
      </ClearAll>

    )
    return labels;
  }

  render() {
    const { demographics: { active, available } } = this.props;
    return (
      <Root display={keys(active).length > 0}>
        {
          this.labels(active, available)
        }
      </Root>
    );
  }
}

ActiveDemographics.propTypes = {
  demographics: PropTypes.object,
  removable: PropTypes.bool,
  onRequestDelete: PropTypes.func,
  onRequestDeleteAll: PropTypes.func
}

export default ActiveDemographics;
