import { connect } from 'react-redux';
import ChartToolbar from './ChartToolbar';
import * as actions from './actions';

const mapStateToProps = state => {
  return {
    jobDescription: state.dashboard.jobDescription,
    show: state.dashboard.show,
    shareUrl: state.dashboard.shareUrl,
    embeddable: state.dashboard.embeddable,
    restrictedAccess: state.dashboard.restrictedAccess,
    ...state.app
  }
}

const dispatchToProps = dispatch => {
  return {
    onToggle: (parameter) => {
      dispatch(actions.toggleShow(parameter));
    },
    onClose: () => {
      dispatch(actions.setShareUrl(null))
    },
    onShareChart: () => {
      dispatch(actions.shareChart());
    }
  }
}

export default connect(mapStateToProps, dispatchToProps)(ChartToolbar);
