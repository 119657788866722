import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import { Home } from '../home';
import { Dashboard } from '../dashboard';
import { Embed } from '../embeds';
import { Login, LoggedIn } from '../login';
import { NotFound, Copy } from '../common';
import About from '../About';
import WhatWeOffer from '../WhatWeOffer';
import JoinIn from '../JoinIn';
import HealthCheck from '../HealthCheck'
import Maintenance from '../Maintenance'
import Thanks from '../Thanks'
import Jobs from '../Jobs'
import Reports from '../reports/Reports'
import Report from '../reports/Report'
import FourZeroFour from '../FourZeroFour'

const mapStateToProps = state => {
  return {
    ...state.routing
  }
}

class TrafficCop extends Component {
  decide(activeRoute) {
    switch (activeRoute) {
      case actions.HOME:
      case actions.DASHBOARD:
        return <HealthCheck render={({ healthy, pending }) => {
          if (healthy) {
            return <LoggedIn>
              {activeRoute === actions.HOME ? <Home /> : activeRoute === actions.DASHBOARD ? <Dashboard /> : null}
            </LoggedIn>
          } else {
            return <Maintenance />
          }
        }}>
        </HealthCheck>
      case actions.LOGIN:
        return <Login />
      case actions.EMBED:
        return <Embed />
      case actions.PROMO:
        return <Embed liveEmbed={true} />
      case actions.TOS:
        return <Copy fileName='results-tos.md' />
      case actions.SURVEY_TOS:
        return <Copy fileName='survey-tos.md' />
      case actions.ABOUT:
        return <About />
      case actions.PRIVACY:
        return <Copy fileName='privacy.md' />
      case actions.JOBS:
        return <Jobs fileName='jobs.md' />
      case actions.METHODOLOGY:
        return <Copy fileName='methodology.md' />
      case actions.WHAT_WE_OFFER:
        return <WhatWeOffer />
      case actions.JOIN_IN:
        return <JoinIn />
      case actions.THANKS:
        return <Thanks />
      case actions.FAQ:
        return <Copy fileName='faq.md' />
      case actions.REPORTS:
      case actions.REPORTS_PAGED:
        return <Reports />
      case actions.REPORT:
        return <Report />
      case actions.FOUR_ZERO_FOUR:
        return <FourZeroFour />
      case actions.TEST_EXCEPTION:
        throw("Test exception thrown in results_frontend!");
      case actions.EMPTY:
        return null
      case actions.NOT_FOUND:
        return <NotFound />
      default:

    }
  }
  render() {
    const { activeRoute } = this.props;
    return (
      <div>
        {this.decide(activeRoute)}
      </div>
    );
  }
}

TrafficCop.propTypes = {
  activeRoute: PropTypes.string
}

export default connect(mapStateToProps)(TrafficCop);
