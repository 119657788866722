import { StyleSheet, css } from 'aphrodite';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Logo, primaryBlue } from '../common';

const styles = StyleSheet.create({
  linkBack: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer'
  },
  copy: {
    lineHeight: '1.5em'
  },
  contact: {
    color: 'inherit',
    ':hover': {
      color: primaryBlue,
      textDecoration: 'underline'
    }
  }
})

class RestrictedPopup extends Component {
  constructor() {
    super();
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  componentDidMount() {
    const { pathname: previousPathname } = this.props.location.prev;
    if (this.props.restrictedAccess && previousPathname == '') {
      setTimeout(() => this.props.flashPopup(), 10000);
    }
  }

  render() {
    const { showRestrictedModal = false } = this.props;
    const actions = <div>
      <FlatButton
        key="2"
        href="/"
        label="More poll results"
      />
    </div>;
    return (
      <Dialog
        open={showRestrictedModal}
        title={<div><Logo /></div>}
        actions={actions}
        modal={false}
        onRequestClose={this.handleClose}>
        <div className={`material-icons ${css(styles.linkBack)}`} onClick={this.handleClose}>close</div>
        <div className={css(styles.copy)}>
          Additional data is available for states and demographic groups. Please contact <a className={css(styles.contact)} href='mailto:inquiries@civiqs.com'>inquiries@civiqs.com </a>for plans and pricing.
        </div>
        <br />
      </Dialog>
    );
  }
}

RestrictedPopup.propTypes = {
  restrictedAccess: PropTypes.bool,
  location: PropTypes.object,
  showRestrictedModal: PropTypes.bool,
  onClose: PropTypes.func,
  flashPopup: PropTypes.func,
}

export default RestrictedPopup;
