import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SamplingFrame,
  DateRangeSubtitle,
  VoterType,
  commaNumber
} from './';
import styled from 'styled-components';

const mapStateToProps = state => {
  const { jobDescription, sampleSize, dateRange } = state.dashboard;
  const { viewport } = state.app;
  return {
    jobDescription,
    sampleSize,
    dateRange,
    viewport
  }
}


const Root = styled.div`
  width: 100%;
  line-height: 1.2;
`

const DisplayText = styled.div`
  font-size: 24px;
  margin: 0;
  font-weight: 800;
`

const Sampling = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const SampleSize = styled.span`
  margin-left: 5px;
  font-weight: normal;
`

const DateRange = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
`

class JobDescription extends React.Component {
  render() {
    const { jobDescription, sampleSize, viewport, dateRange } = this.props;
    if (!jobDescription) return null;
    return (
      <Root>
        <SamplingFrame label={jobDescription.geo} />
        <DisplayText>
          {jobDescription.displayText}
        </DisplayText>
        <Sampling>
          <VoterType selectable={true} />
          <DateRange>
            <DateRangeSubtitle range={dateRange} viewport={viewport} />:
            <SampleSize>
              {commaNumber(sampleSize)} Responses
            </SampleSize>
          </DateRange>
        </Sampling>
      </Root >
    );
  }
}

JobDescription.propTypes = {
  jobDescription: PropTypes.object,
  viewport: PropTypes.symbol,
  sampleSize: PropTypes.number,
  dateRange: PropTypes.array
}

export default connect(mapStateToProps)(JobDescription);
