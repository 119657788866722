import { connect } from 'react-redux';
import RestrictedPopupTrigger from './RestrictedPopupTrigger';
import { showRestrictedModal } from '../actions';

const mapStateToProps = state => {
  return {
    restrictedAccess: state.dashboard.restrictedAccess
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      dispatch(showRestrictedModal(true))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedPopupTrigger);
