import React from 'react';
import PropTypes from 'prop-types';
import VoterType from './VoterType';
import { lightBlueGrey } from './colors';
import DateRangeSubtitle from './DateRangeSubtitle';
import SamplingFrame from './SamplingFrame';
import breakpoints, { viewportConstants as vc } from './breakpoints';
import { getDates, getDateRange } from '../helpers';
import { css, StyleSheet } from 'aphrodite';
import { Preview as PreviewChart } from '../charts'
import { isEqual } from 'lodash';
import styled from 'styled-components';

const ThumbnailContainer = styled.a`
  color: inherit;
  :hover {
    color: inherit
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  ${ThumbnailContainer}:hover & {
    text-decoration: underline;
  }

`


const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tile: {
    width: '23%',
    margin: '1%',
    marginBottom: '50px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    ...breakpoints(vc.PHONE, vc.TABLET_PORTRAIT, { width: '100%' }),
    ...breakpoints(vc.TABLET_LANDSCAPE, {
      width: '48%'
    }),
    ...breakpoints(vc.BIG_DESKTOP, {
      width: '18%'
    })
  },
  VoterTypeContainer: {
    fontSize: 12
  },
  VoterType: {
    fontWeight: 'bold'
  },
  chartContainer: {
    position: 'relative'
  },
  triangle: {
    position: 'absolute',
    bottom: 23,
    right: 0,
    borderLeft: '50px solid transparent',
    borderBottom: '50px solid rgba(0,0,0,0.2)',
  },
  icon: {
    position: 'absolute',
    right: 3,
    bottom: 27
  }
})


class Preview extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps)
  }

  render() {
    const { data = [] } = this.props;
    return (
      <div className={css(styles.root)}>
        {data.map((q, i) => {
          return <ThumbnailContainer id={q.jobDescription.name}
            key={q.jobDescription.name}
            className={css(styles.tile)}
            href={`/results/${q.jobDescription.name}?uncertainty=true&annotations=true&zoomIn=true`}
          >
            <div className={css(styles.chartContainer)}>
              <PreviewChart data={q} height={150} marginLeft={0} marginRight={0} marginTop={0} />
            </div>
            <SamplingFrame label={q.jobDescription.geo} style={{ color: lightBlueGrey, fontSize: '12px' }} />
            <Title>
              {q.jobDescription.displayText}
            </Title>
            <div className={css(styles.VoterTypeContainer)}>
              <div className={css(styles.VoterType)}>
                <VoterType populationModel={q.jobDescription.populationModel} limitToParty={q.jobDescription.limitToParty} />
              </div>
              <div>
                <DateRangeSubtitle range={getDateRange(getDates(q.trendline))} />
              </div>
            </div>
          </ThumbnailContainer>
        })
        }
      </div>
    );
  }
}

Preview.propTypes = {
  data: PropTypes.array
}

export default Preview;
