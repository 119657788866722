import * as R from 'ramda';
import { captureMouse } from './labelHelpers'

const isNotNill = R.complement(R.isNil)
const someDefined = R.any(isNotNill)

export const showBrushOnLoad = R.compose(someDefined, R.values, R.pick(['startDate', 'endDate']))

export function setContainerHeight(totalHeight, brushVisibilty) {
  const brushAllowance = 160, brushPadding = 40;
  return brushVisibilty ? totalHeight + brushAllowance : totalHeight + brushPadding;
}

export const allowBrush = R.equals('dashboard');

export const setCursor = (coords, width, height, ref) => {
  const top = 0, left = 0, right = left + width, bottom = top + height;
  const bbox = { top, left, right, bottom };
  if (captureMouse(coords)(bbox)) {
    ref.current.style.cursor = 'crosshair';
  } else {
    ref.current.style.cursor = 'auto'
  }
}