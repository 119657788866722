import { connect } from 'react-redux';
import Home from './Home'
import { mixpanel } from '../common';

const mapStateToProps = state => {
  return {
    ...state.home,
    ...state.search,
    ...state.app,
    ...state.login
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onComponentDidMount: () => {
      mixpanel.loadFrontPage()
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);
