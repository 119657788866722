
import PropTypes from 'prop-types';
import { LoginLogout } from '../login/';
import { Logo } from '../common';
import React, { Component } from 'react';
import styled from 'styled-components';
import mediaQueries from './mediaQueries';

const Container = styled.div`
  height: 72px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  align-items: center;
  padding: 0 10px;
  background-color: ${props => props.menuClicked ? '#fff' : props.theme.primaryBlue};
  color: ${props => props.menuClicked ? props.theme.primaryBlue : '#fff'};
  z-index: 10;
  position: ${props => props.sticky ? 'fixed' : 'static'};
  left: 0;
  right: 0;
  top: 0;
  ${mediaQueries.tablet`height: 60px`};
  ${mediaQueries.tablet`position: static`};
`

const LogoContainer = styled.span`
  font-size: 3rem;
  padding-bottom: 5px;
  ${mediaQueries.tablet`font-size:2.4rem`}
  ${mediaQueries.tablet`flex:1`}
  ${mediaQueries.tablet`text-align:center`}
`

const LinksContainer = styled.nav`
  ${mediaQueries.tablet`display:none`};
  padding: 0 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LinksContainerMobile = styled.nav`
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 40px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
`

const RightContainer = styled.span`
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MobileMenuTrigger = styled.i`
  display: none;
  cursor: pointer;
  ${mediaQueries.tablet`display:inline`}
`

const Link = styled.a`
  font-size: 1.4rem;
  margin: 0 10px;
  line-height: 30px;
  display: ${props => props.menuClicked ? 'block' : 'inline-block'};
  border: ${props => props.isAction ? '1px solid #fff' : 'none'};
  border-radius: ${props => props.isAction ? '15px' : '0'};
  padding: ${props => props.isAction ? '0 10px' : props.menuClicked ? '10px 0' : '0'};
  background-color: ${props => props.isAction ? '#fff' : 'none'};
  border-bottom: ${props => props.menuClicked ? '1px solid #ddd' : 'none'};
  color: ${props => props.isAction || props.menuClicked ? props.theme.primaryBlue : '#fff'};
`

const SearchOpen = styled.i`
  cursor: pointer;
  ${mediaQueries.tablet`display:none`}
  ::after {
    content: 'search'
  }
`

const MobileSeachOpen = styled(SearchOpen)`
  margin-right: 10px;
  display: none;
  ${mediaQueries.tablet`display:inline-block`}
`
const links = [
  { href:'', text: 'Survey Results'},
  { href: 'reports', text: 'News and Reports'},
  { href:'what-we-offer', text: 'What We Offer'},
  { href:'methodology', text: 'Methodology'},
  { href:'about', text: 'Team'},
  { href:'what-we-offer#contact', text: 'Conduct a Survey'},
  { href:'join-in', text: 'Take Surveys', action: true}
]

const LinkSwitcher = ({menuClicked, children}) => (
  menuClicked ? <LinksContainerMobile>{children}</LinksContainerMobile> : <LinksContainer>{children}</LinksContainer>
)

LinkSwitcher.propTypes = {
  menuClicked: PropTypes.bool,
  children: PropTypes.any
}

class Header extends Component {
  constructor() {
    super();

    this.state = {menuClicked: false}

    this.onClickMenu = this.onClickMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.onClickSearchOpen = this.onClickSearchOpen.bind(this);
  }

  onClickMenu () {
    this.setState({
      menuClicked: !this.state.menuClicked
    })
  }

  closeMenu() {
    if(this.state.menuClicked) {
      this.setState({menuClicked: false})
    }
  }

  onClickSearchOpen() {
    this.props.onClickSearchOpen();
  }

  componentDidMount() {
    window.addEventListener('resize', this.closeMenu)
    window.addEventListener('scroll', this.closeMenu)
    window.addEventListener('click', this.closeMenu)
  }

  render() {
    const { menuClicked } = this.state;
    const { sticky, enableSearch } = this.props;
    return (
      <React.Fragment>
        <Container menuClicked={menuClicked} sticky={sticky} onClick={e => e.stopPropagation()}>
          <MobileMenuTrigger className='material-icons' onClick={this.onClickMenu}>
            {menuClicked ? 'close' : 'menu'}
          </MobileMenuTrigger>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <LinkSwitcher menuClicked={menuClicked}>
            {enableSearch && <SearchOpen onClick={this.onClickSearchOpen} className='material-icons'/>}
            {links.map(link => (
              <Link key={link.text} href={`/${link.href}`} isAction={!menuClicked && link.action} menuClicked={menuClicked}>{link.text}</Link>
            ))}
          </LinkSwitcher>
          <RightContainer>
            {enableSearch && <MobileSeachOpen onClick={this.onClickSearchOpen} className='material-icons'/>}
            <LoginLogout/>
          </RightContainer>
        </Container>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.closeMenu)
    window.removeEventListener('scroll', this.closeMenu)
    window.removeEventListener('click', this.closeMenu)
  }

}

Header.propTypes = {
  sticky: PropTypes.bool,
  enableSearch: PropTypes.bool,
  onClickSearchOpen: PropTypes.func
}

export default Header;
