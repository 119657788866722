import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { isUndefined, max } from 'lodash'
import { signedRawPercent, percent, monthDayYear } from '../common'
import { rgb } from 'd3'
import { fillOpacity, netScale } from './colorScales'

const styles = StyleSheet.create({
  container: {
    borderRadius: 3,
    boxShadow: '2px 2px 6px rgba(0,0,0,.5)',
    position: 'absolute',
    zIndex: 10,
    padding: '6px 8px',
    backgroundColor: '#fff',
    pointerEvents: 'none'
  },
  mobile: {
    position: 'static',
    boxShadow: 'none',
    width: '100%'
  },
  strong: {
    marginBottom: 10,
    fontSize: 14,
    display: 'block'
  },
  table: {
    fontSize: 12,
    width: '100%',
    borderCollapse: 'collapse'
  },
  spacerRow: {
    height: 10
  },
  td: {
    paddingLeft: 5,
    paddingTop: 2,
    paddingBottom: 2,
    ':nth-child(2)': {
      fontWeight: 'bold',
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'right'
    }
  },
  net: {
    border: 'none',
  },
  small: {
    textAlign: 'right',
    display: 'block',
    fontSize: 10,
    color: '#c6c6c6',
    marginTop: 10
  },
  hide: {
    display: 'none'
  }
})

class MapTooltip extends Component {

  getY() {
    if (!this.container) return;
    return this.props.y + 50
  }

  getX() {
    if (!this.container) return;
    const bbox = this.container.getBoundingClientRect();
    const width = bbox.width === 0 ? 150 : bbox.width
    return this.props.x - width / 2
  }

  getCellStyle(choice, maxValue) {
    if (this.props.data[choice] === maxValue) {
      let color = rgb(this.props.jobDescription.choiceColors[choice]);
      color.opacity = fillOpacity(this.props.data[choice])
      return { backgroundColor: color }
    }
  }

  getChoiceStyle(choice) {
    const color = this.props.jobDescription.choiceColors[choice];
    return { borderLeft: `2px solid ${color}` }
  }

  getNetRowStyle(net) {
    const {
      negativeColor,
      positiveColor
    } = netScale(this.props.jobDescription);

    const backgroundColor = rgb(net >= 0 ? positiveColor : negativeColor)
    backgroundColor.opacity = fillOpacity(Math.abs(net))
    return { backgroundColor }
  }

  render() {
    const { data, jobDescription, dateRange, isPhone, choices } = this.props
    const maxValue = max(choices.map(choice => data[choice]))
    const left = this.getX();
    const top = this.getY();
    const hasNet = !isUndefined(data.net);

    return (
      <div ref={node => this.container = node} className={css(styles.container, isPhone && styles.mobile, !(left || top) && styles.hide)} style={{ left, top }}>
        <strong className={css(styles.strong)}>{data.state}</strong>
        <table className={css(styles.table)}>
          <tbody>
            {hasNet && <tr>
              <td className={css(styles.td, styles.net)}>{jobDescription.displayNet.label}</td>
              <td className={css(styles.td, styles.net)} style={this.getNetRowStyle(data.net)}>{signedRawPercent(data.net)}</td>
            </tr>
            }
            <tr className={css(styles.spacerRow)}></tr>
            {choices.map((choice) => {
              if (!jobDescription.choiceColors[choice]) return null;
              return <tr key={choice} className={css(styles.tr)}>
                <td className={css(styles.td)} style={this.getChoiceStyle(choice)}>{choice}</td>
                <td className={css(styles.td)} style={this.getCellStyle(choice, maxValue)}>{percent(data[choice])}</td>
              </tr>
            })}
          </tbody>
        </table>
        <small className={css(styles.small)}>{monthDayYear(dateRange[1])}</small>
      </div>
    );
  }
}

MapTooltip.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  jobDescription: PropTypes.object,
  dateRange: PropTypes.array,
  isPhone: PropTypes.bool,
  data: PropTypes.object,
  choices: PropTypes.array
}

export default MapTooltip;
