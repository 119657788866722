import React, { useEffect, useState } from 'react';
import { Header, Footer, mediaQueries, BlueBanner, useYaml, P, publicUrl, ReportDate } from '../common';
import styled from 'styled-components'
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { gotToFourZeroFour } from '../routing';
import { utcParse, utcFormat } from 'd3';

const getDate = R.prop('date')
const parseDate = utcParse('%m/%d/%Y')
const formatDate = utcFormat('%B %Y')
const addArchiveDate = o => R.compose(R.assoc('archiveDate', R.__, o), formatDate, parseDate, getDate)(o)
const getTopElevenReports = R.splitEvery(10);
const getReports = R.compose(R.map(addArchiveDate), R.prop('reports'));
const getPageLink = page => page === 1 ? '/reports' : `/reports/page/${page}`
const getUniqueMonthYears = R.compose(R.uniq, R.map(R.prop('archiveDate')));
const datesMatch = R.propEq('archiveDate');
const filterReports = (data, filters) => {
  if (filters.date) {
    return R.filter(datesMatch(filters.date), data)
  } else {
    return data;
  }
}

const CopyContainer = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: auto;
`

const Subtitle = styled.h2`
  text-align: center;
  color: ${props => props.theme.color};
  margin: auto;
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 50px;
  margin-top: 50px;
`

const ReportsContainer = styled.div`
  position: relative;
`

const ReportContainer = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  max-width: 800px;
  margin-bottom: 50px;
  color: #000;
  :last-child {
    margin-bottom: 0
  }
  :hover {
    h2 {
      text-decoration: underline;
    }
  };
`

const TextContainer = styled(P)`
  margin-left: 20px;
  text-align: left;
  margin-top: 0px;
  padding: 0;
  h2 {
    margin-top: 0;
    margin-bottom: 5px
  }
`

const Date = styled(ReportDate)`
  text-align: left;
`

const Image = styled.div`
  height: ${props => props.large ? 300 : 200}px;
  width: ${props => props.large ? 600 : 400}px;
  flex: none;
  display: block;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  ${mediaQueries.phone`display:none`};
  ${mediaQueries.tablet`display:none`};
`

const MobileImage = styled.img`
  width: 150px;
  height: auto;
  margin: auto;
  display: none;
  ${mediaQueries.phone`display:block`};
  ${mediaQueries.tablet`display:block`};
`

const BottomNav = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #ddd;
  justify-content: center;
  margin-top: 20px;
  padding-top: 10px;
`

const PageNumber = styled.a`
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-width: 2.4rem;
  text-align: center;
  border-bottom: ${props => props.active ? `2px solid ${props.theme.primaryBlue}` : 'none'};
  display: ${props => props.hide ? 'none' : 'block'};
`

const ArchiveList = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ::before {
    content: 'Archives'
  }
  ${mediaQueries.tablet`display: none`};
`

const ArchiveLink = styled.a`
  display: block;
  line-height: 2.4rem;
  font-size: 1.4rem;
  :first-child {
    border-top: 1px solid #ddd;
    padding-top: 5px;
    margin-top: 5px;
  }
`

const FilterHeader = styled.h2`
  display: ${props => props.display ? 'block' : 'none'};
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
`
const generatePage = activePage => {
  return page => {
    return <PageNumber active={page === activePage} href={getPageLink(page)} key={page}>{page}</PageNumber>
  }
}

export const createPageNumbers = (pages, activePage) => {
  if (pages === 1) return <div />
  const onFirstPage = activePage === 1;
  const onLastPage = activePage === pages;
  const minPage = R.max(2, activePage - 2);
  const maxPage = R.min(pages, activePage + 3)
  const pageRange = R.range(minPage, maxPage)
  const pageGenerator = generatePage(activePage);

  const defaultPages = [
    <PageNumber key='previous' hide={onFirstPage} href={getPageLink(activePage - 1)} >&#8249;</PageNumber>,
    <PageNumber key='first-page' active={onFirstPage} href={getPageLink(1)}>1</PageNumber>,
    <PageNumber key={'previous-ellipsis'} hide={minPage === 2}>&#8230;</PageNumber>,
    <PageNumber key='more-ellipsis' hide={maxPage === pages}>&#8230;</PageNumber>,
    <PageNumber key='last-page' active={onLastPage} href={getPageLink(pages)}>{pages}</PageNumber>,
    <PageNumber key='next' hide={onLastPage} href={getPageLink(activePage + 1)}>&#8250;</PageNumber>
  ]

  return R.insertAll(3, R.map(pageGenerator, pageRange), defaultPages)
}


function Reports() {
  const data = useYaml('/data/reports.yml');
  const { payload: { page = 1 }, query = {} } = useSelector(state => state.location);
  const [pagedData, setPagedData] = useState([]);
  const dispatch = useDispatch();
  const [pages, setPages] = useState(0)
  const [monthYears, setMonthYears] = useState([]);

  useEffect(() => {
    if (data.reports) {
      const reports = getReports(data)
      const filteredReports = filterReports(reports, query)
      const grouped = getTopElevenReports(filteredReports);
      setMonthYears(getUniqueMonthYears(reports))
      setPages(grouped.length);
      if (page > grouped.length) {
        dispatch(gotToFourZeroFour())
      } else {
        setPagedData(grouped[page - 1])
      }
    }
  }, [data, page, dispatch])

  return (
    <div>
      <Header />
      <div>
        <BlueBanner>{data.title}</BlueBanner>
        <CopyContainer>
          <Subtitle>{data.subtitle}</Subtitle>
          <FilterHeader display={query.date}>Archive for {query.date}</FilterHeader>
          <ReportsContainer>
            {pagedData.map((report, i) => {
              return <React.Fragment key={i}>
                <ReportContainer href={report.external_url ? report.external_url : `/reports/${report.slug}`}>
                  <Image src={publicUrl(`/images/reports/${report.preview_image}`)}></Image>
                  <TextContainer as='div'>
                    <h2>{report.title}</h2>
                    <Date>{report.date}</Date>
                    {report.preview_image && <MobileImage src={publicUrl(`/images/reports/${report.preview_image}`)} />}
                    <p>{report.blurb}</p>
                  </TextContainer>
                </ReportContainer>
              </React.Fragment>
            })}
            <ArchiveList>
              {
                monthYears.map(d => <ArchiveLink key={d} active={query.date === d} href={`/reports?date=${d}`}>{d}</ArchiveLink>)
              }
            </ArchiveList>
          </ReportsContainer>
          <BottomNav>
            {createPageNumbers(pages, page)}
          </BottomNav>
        </CopyContainer>
      </div>
      <Footer />
    </div >
  );
}

export default Reports;
