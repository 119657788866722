import React from 'react';
import PropTypes from 'prop-types';
import  Demographic from './Demographic';
import { lightBlueGrey, viewportConstants } from '../common/'
import { get, keys } from 'lodash';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    padding: 0,
  },
  header: {
    height: '40px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '40px',
    color: '#fff',
    fontSize: '0.8em',
    background: lightBlueGrey,
    cursor: 'pointer',
  }
});

class Demographics extends React.Component {
  constructor() {
    super();

    this.state = {};

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      show: !this.state.show
    })
  }

  render() {
    const { onAdd, onRemove, demographics, viewport, restrictedAccess } = this.props;
    const { show } = this.state;
    if(!demographics.available) return <span />
    const activeCount = keys(demographics.active).length
    const isCompactView = viewport === viewportConstants.PHONE || viewport === viewportConstants.TABLET_PORTRAIT;
    return (
      <div className={css(styles.root)}>
        <div className={css(styles.header)} onClick={() => isCompactView && this.toggle()}>
          <div>
            {`Refine By ${activeCount ? `(${activeCount})` : '' }`}
          </div>
          <i className='material-icons' style={{lineHeight: '40px', marginRight: 20}}>
            {isCompactView ? show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' : ''}
          </i>
        </div>
        <div style={{display : !isCompactView ? 'block' : show ? 'block' : 'none'}}>
          { demographics.available.map((d,i) => {
            const activeDemographic = get(demographics.active, d.demographic);
            return <Demographic
              key={d.demographic}
              demographic={d}
              restrictedAccess={restrictedAccess}
              activeDemographic={activeDemographic}
              onRemove={option => {
                onRemove(d.demographic,option)
                isCompactView && this.toggle()}
              }
              onAdd={option => {
                onAdd(d.demographic, option)
                isCompactView && this.toggle();
              }}
              options={d.values}>
            </Demographic>
          })
          }
        </div>
      </div>
    );
  }
}

Demographics.propTypes = {
  active: PropTypes.object,
  demographics: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  viewport: PropTypes.symbol,
  restrictedAccess: PropTypes.bool
}

export default Demographics;
