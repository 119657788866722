import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RestrictedPopupTrigger extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if(!this.props.restrictedAccess) return;
    e.stopPropagation();
    this.props.onClick();
  }

  render() {
    const { children } = this.props;
    return (
      <span onClick={this.onClick}>
        {children}
      </span>

    );
  }
}

RestrictedPopupTrigger.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  restrictedAccess: PropTypes.bool
}

export default RestrictedPopupTrigger;
