import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import { select } from 'd3';

function AnimatedPath(props) {
  const { attribute, value, skipAnimation } = props;
  const ref = useRef();
  useEffect(() => {
    const selection = select(ref.current);
    const t = selection.transition().duration(skipAnimation ? 0 : 250)
    if (attribute === 'fill') {
      t.style(attribute, value)
    } else {
      t.attr(attribute, value)
    }
  }, [value, attribute, skipAnimation])
  return <path {...props} ref={ref}></path>
}

AnimatedPath.propTypes = {
  attribute: PropTypes.string,
  value: PropTypes.any,
  skipAnimation: PropTypes.bool
}

export default AnimatedPath;