import { mapValues, isEmpty } from 'lodash';
import * as R from 'ramda';
import { forceToArray } from '../displayNet'

const isHomeState = (v, k) => k.includes('home_state');
const getHomeStates = R.pickBy(isHomeState)
const renameHomeState = ([key, value]) => [key.match(/"home_state":"([\w\s,\.]+)"/)[1], value]
const renameHomeStates = R.compose(R.fromPairs, R.map(renameHomeState), R.toPairs)
const getAndRenameStates = R.compose(renameHomeStates, getHomeStates);
const sumValuesByKeys = keys => R.compose(R.sum, R.values, R.pickAll(keys))
export const getMinuendsAndSubtrahends = R.compose(R.map(R.compose(sumValuesByKeys, forceToArray)));

const getData = (crosstabData, jobDescription) => {
  if (!jobDescription.geographicalSubunit) return undefined
  const { displayNet } = jobDescription
  const { minuend, subtrahend } = getMinuendsAndSubtrahends(displayNet);
  return mapValues(getAndRenameStates(crosstabData), (value) => {
    if (isEmpty(jobDescription.displayNet)) return { ...value }
    return {
      ...value,
      net: minuend(value) - subtrahend(value)
    }
  })
}

export default getData
