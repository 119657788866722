import deepFreeze from 'deep-freeze';
import * as actions from './actions';

const defaults = {
  results: [],
  fromIndex: 0,
  jobsPerRow: 4,
  rows: 2,
  initialLoadQty: 12
}

const searchReducer = (state = defaults, action = {}) => {
  deepFreeze(state);
  switch (action.type) {
    case actions.SET_RESULTS:
      return {
        ...state,
        results: action.results
      }
    case actions.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.flag
      }
    case actions.SET_NO_RESULTS:
      return {
        ...state,
        noResults: action.flag
      }
    case actions.RESET_DEFAULTS:
      return defaults
    case actions.TOGGLE_SEARCH_BAR:
      return {
        ...state,
        showSearchBar: action.flag
      }
    case actions.NO_MORE_RESULTS:
      return {
        ...state,
        noMoreResults: action.flag
      }
    case actions.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.value
      }
    case actions.SET_FROM_INDEX:
      return {
        ...state,
        fromIndex: action.fromIndex
      }
    case actions.SET_LOAD_PARAMS:
      return {
        ...state,
        initialLoadQty: action.initialLoadQty,
        jobsPerRow: action.jobsPerRow,
      }
    case actions.INCREMENT_FROM_INDEX:
      return {
        ...state,
        fromIndex: state.fromIndex === 0 ? state.initialLoadQty : state.fromIndex +  (state.jobsPerRow * state.rows)
      }
    default:
      return state;

  }
}

export default searchReducer;
