import { load } from 'js-yaml';
import Dialog from 'material-ui/Dialog';
import React, { Component } from 'react';
import styled from 'styled-components';
import { BlueBanner, Footer, Header, mediaQueries, publicUrl } from './common';

const Root = styled.div`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.color}!important;
`

const MaxWidthDiv = styled.div`
  max-width: 840px;
  margin: auto;
  ${mediaQueries.tablet`padding:20px`}
  a {
    color: 'rgb(102,119,164)'
  }
`

const Title = styled(MaxWidthDiv)`
  color: ${props => props.theme.color};
  font-size: 28px;
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
`

const Subtitle = styled(MaxWidthDiv)`
  max-width: 600px;
  text-align: center;
  color: #616a73;
  font-size: 18px;
  line-height: 1.5em;
`

const LongDescriptionSection = styled(MaxWidthDiv)`
  display: flex;
  padding: 50px 0;
  ${mediaQueries.tablet`display:block`}
  `

const LongDescription = styled.div`
  height: 650px;
  ${mediaQueries.tablet`height:auto`}
  position: relative;
  background-color: rgb(248,249,252);
  flex: 1 1 33%;
  padding: 25px;
  text-align: center;
  ::before {
    content: ${props => `url('${props.icon}')`};
    display: block;
    transform: scale(2);
    margin: 50px 0;
  }
  h2 {
    font-size: 28px;
    color: rgb(51,51,51);
  }
  p {
    font-weight: 300;
    line-height: 1.5em;
  }
  :nth-child(2) {
    background-color: rgb(242,244,249);
  }
  hr {
    position: absolute;
    bottom: 130px;
    left: 0;
    right: 0;
    ${mediaQueries.tablet`position:static;margin: 40px 0`}
  }
  button {
    width: 140px;
    height: 85px;
    background-color: rgb(102,119,164);
    color: #fff;
    border: 1px solid grey;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 70px);
    cursor: pointer;
    :hover {
      background-color: #4f5e85;
    }
    ${mediaQueries.tablet`position:static`}
    ::before {
      content: 'Contact For Pricing';
    }
  }
`

const ShortDescriptionBackground = styled.div`
  background-color: rgb(243,245,250);
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ShortDescription = styled(MaxWidthDiv)`
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  color: rgb(51,51,51);
  h2 {
    margin: 0px;
    padding: 0;
    font-size: 48px;
    ${mediaQueries.tablet`font-size:24px`}
  }
  p {
    font-size: 18px;
    line-height: 1.5em;
    margin: 0px;
    margin-top: 10px;
    ${mediaQueries.tablet`font-size:16px`}
  }
  `

const ShortDescriptionIcon = styled.div`
  display: flex;
  flex: 0 0 25%;
  justify-content: center;
  align-items: center;
  ::before {
    content: ${props => `url('${props.icon}')`};
    display: block;
    transform: scale(1.75);
    margin: 50px 0;
    ${mediaQueries.phone`transform:scale(1)`}
  }
`

const ListArea = styled(MaxWidthDiv)`
  padding: 30px 0;
  justify-content: center;
  h2 { 
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgb(102,119,164);
    margin: 15px 0;
  }
  `

const List = styled.div`
  display: flex;
  ${mediaQueries.phone`flex-wrap:wrap`}
  ul {
    line-height: 1.5em;
    padding: 0 20px;
    margin: 0;
    ${mediaQueries.phone`flex: 1 0 100%`}
    li {
      margin-top: 5px;
    }
  }
`

const DailyTrackingHeaderStyle = styled.div`
  div {
    min-height: 85px;
    background-color: rgb(243,245,250);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    line-height: 1.5em;
    text-align: center; 
    font-size: 18px;
    margin-bottom: 20px;
    ${mediaQueries.phone`font-size:14px; padding:20px 50px`}
  }
`

const DailyTrackingFooterStyle = styled.div`
  div {
    text-transform: uppercase;
    margin: 20px 0 10px 0;
    color: #000;
  }
  ul {
    font-size: 13px;
    margin: 0;
    padding: 0 20px;
    line-height: 1.5em;
  }
`

const SubscriptionServiceHeaderStyle = styled.div`
  span { 
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 30px;
    font-style: italic;
  }
  div {
    margin: 10px 0 10px 0;
    line-height: 1.5em;
  }
`

const ContactSubtitle = styled(Subtitle)`
  color: rgba(42,53,70,1);
  font-size: 24px;
`

const P = styled.p`
  color: #616a73;
  font-family: Lato,sans-serif;
  font-weight: 400;
  line-height: 26px;
  .pull-quote {
    color: #c4c6c7;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 32px;
    margin: 42px;
    text-align: center;
  }
`

const Form = styled.form`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3);
  ${mediaQueries.tablet`width: 100%`};
`

const Input = styled.input`
  min-height: 34px;
  border: 1px solid #ddd;
  font-size: 1.4rem;
  padding: 0.6rem 1.2rem;
  grid-column: ${props => props.gridColumn};
  grid-column-end: ${props => props.gridColumnEnd};
  ${mediaQueries.tablet`grid-column-start:1`};
  ${mediaQueries.tablet`grid-column-end:3`};
  resize: vertical;
`

const Submit = styled(Input)`
  height: 45px;
  max-width: 150px;
  :hover {
    background-color: #98a2af;
    color: #fff;
  }
`

const FormContainer = styled.div`
  max-width: 780px;
  margin: auto;
`

const PollingInterviewingFooter = () => (
  <div style={{ fontSize: 16, marginTop: 20, fontStyle: 'italic' }}>
    Examples of Civiqs Surveys: <a href='/reports'>civiqs.com/reports</a>
  </div>
)

const PollingInterviewingHeader = (onClick) => {
  return <div style={{ marginTop: 20, marginBottom: 20 }}>
    <span style={{ fontSize: 16, fontStyle: 'italic' }}>Civiqs will provide a <a style={{ cursor: 'pointer' }} onClick={onClick}>custom estimate</a> for every survey project.</span>
  </div>
}

const DailyTrackingHeader = () => (
  <DailyTrackingHeaderStyle>
    <div>
      <span>
        Daily Tracking Surveys are a unique service offered only by Civiqs. Visit <a href='/'>civiqs.com</a> for a demonstration of Civiqs' opinion trendline technology.
      </span>
    </div>
  </DailyTrackingHeaderStyle>
)

const DailyTrackingFooter = () => (
  <DailyTrackingFooterStyle>
    <div>As featured in the new york times:</div>
    <ul>
      <li>
        <a href='https://www.nytimes.com/interactive/2020/03/21/upshot/coronovirus-public-opinion.html'>
          Red vs. Blue on Coronavirus Concern: The Gap Is Still Big but Closing. </a>
        March 21, 2020
      </li>
      <li>
        <a href='https://www.nytimes.com/interactive/2020/06/10/upshot/black-lives-matter-attitudes.html'>How Public Oninion Has Moved on Black Lives Matter. </a>
        June 10, 2020
      </li>
      <li>
        <a href="https://www.nytimes.com/2021/05/22/opinion/blm-movement-protests-support.html">Support for Black Lives Matter Surged Last Year. Did It Last? </a>
        May 22, 2021
      </li>
    </ul>
  </DailyTrackingFooterStyle>
)

const SubscriptionServiceHeader = () => (
  <SubscriptionServiceHeaderStyle>
    <span>Subscribers can log in to <a href="/">civiqs.com</a> for interactive results over time and within key subgroups.</span>
    <h2>DAILY POLLING ON 70 POLITICAL AND POLICY QUESTIONS NATIONWIDE</h2>
    <div>
      Civiqs tracks favorable ratings for every statewide politician in the United States, as well as every major party candidate for state and national elected office.
    </div>
  </SubscriptionServiceHeaderStyle>
)

const DynamicComponents = {
  PollingInterviewingFooter,
  PollingInterviewingHeader,
  DailyTrackingHeader,
  DailyTrackingFooter,
  SubscriptionServiceHeader
}

const renderDynamicComponent = (component, onClick) => DynamicComponents[component] ? DynamicComponents[component](onClick) : null

class WhatWeOffer extends Component {
  constructor() {
    super();
    this.state = { modalOpen: false }
  }

  fetchManifest() {
    return fetch(publicUrl('/data/landing.yml')).then(resp => resp.text()).then(d => load(d))
  }

  async componentDidMount() {
    const data = await this.fetchManifest();
    this.setState({
      data
    })
  }

  render() {
    const { data } = this.state
    return (data && <Root>
      <Header />
      <BlueBanner>{data.what_we_offer.title}</BlueBanner>
      <Title>{data.what_we_offer.subtitle}</Title>
      <LongDescriptionSection>
        {data.what_we_offer.services.map(service => {
          return <LongDescription key={service.title} icon={publicUrl(`/images/icons/${service.icon}`)}>
            <h2>{service.title}</h2>
            <p>{service.long_description}</p>
            <hr />
            <button onClick={() => this.setState({ modalOpen: true })} />
          </LongDescription>
        })}
      </LongDescriptionSection>
      {data.what_we_offer.services.map(service => {
        return <React.Fragment key={service.title}>
          <ShortDescriptionBackground >
            <ShortDescription>
              <ShortDescriptionIcon icon={publicUrl(`/images/icons/${service.icon}`)} />
              <div>
                <h2>{service.title}</h2>
                <p>{service.short_description}</p>
              </div>
            </ShortDescription>
          </ShortDescriptionBackground>
          <ListArea>
            {renderDynamicComponent(service.column_header, () => this.setState({ modalOpen: true }))}
            <List>
              {service.columns.map((list, i) => {
                return <ul key={i}>
                  {list.map(item => <li key={item}>{item}</li>)}
                </ul>
              })}
            </List>
            {renderDynamicComponent(service.column_footer)}
          </ListArea>
        </React.Fragment>
      })}
      <div style={{ minHeight: 100 }}></div>
      <Footer />
      <Dialog open={this.state.modalOpen} modal={false} onRequestClose={() => this.setState({ modalOpen: false })}>
        <FormContainer>
          <ContactSubtitle>{data.contact.title}</ContactSubtitle>
          <P>{data.contact.subtitle}</P>
          <Form autocomplete="off" novalidate="novalidate" name="contact-form" action="https://formspree.io/inquiries@civiqs.com" method="post">
            <Input type="text" placeholder='Enter your name' required="true" name='full_name' />
            <Input type="text" placeholder='Enter your email' required="true" name='email' />
            <Input type="text" placeholder='Subject' required="true" name='_subject' gridColumn={'1/3'} />
            <Input as='textarea' rows='6' placeholder='Write your message' required="true" name='message' gridColumn={'1/3'} />
            <Submit type="submit" value="Send Message" />
          </Form>
        </FormContainer>
      </Dialog>
    </Root>
    );
  }
}

export default WhatWeOffer;
