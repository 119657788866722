import { validUrlDemographics } from './model';
import * as R from 'ramda';
import { safeParseDate, parseBool } from './shared';

const chartTypes = ['trendline', 'net', 'map'];
const chartOptions = ['annotations', 'uncertainty', 'zoomIn', 'sumTotals'];
const booleanParameters = R.concat(chartOptions, chartTypes);
const dateParameters = ['startDate', 'endDate'];
const getBooleans = R.compose(R.map(parseBool), R.pickAll(booleanParameters))
const getDates = R.compose(R.map(safeParseDate), R.pickAll(dateParameters))
const getShow = query => R.mergeAll([getBooleans(query), getDates(query), R.pick(['choice'], query)])
const hasMap = R.has('map');
const hasHomeState = R.has('home_state')
const hasBothMapAndHomeState = R.both(hasMap, hasHomeState);
const removeHomeState = R.when(hasBothMapAndHomeState, R.dissoc('home_state'));
const removeMap = R.when(hasBothMapAndHomeState, R.dissoc('map'));
const setChoice = R.assoc('choice')
const removeChoice = R.dissoc('choice')

const getQueryParams = R.compose(R.applySpec({
  activeDemographics: R.pickBy((val, key) => validUrlDemographics.indexOf(key) > -1),
  embedDate: R.prop('embedDate'),
  show: getShow
}), R.defaultTo({}))

const setChartType = (query, chartType) => {
  const propsToRemove = R.without(chartType, chartTypes);
  return R.compose(removeHomeState, R.assoc(chartType, true), R.omit(propsToRemove))(query)
}

const toggleBoolean = (parameter, query) => {
  const l = R.lensProp(parameter)
  return R.over(l, R.not, R.map(parseBool, query))
}

const removeParameters = (parameters, query) => {
  return R.omit(parameters, query);
}

const addDemographic = (action, query) => {
  return R.compose(removeMap, R.assoc(action.demographic, action.value))(query)
}

const removeDemographic = (action, query) => {
  return removeParameters([action.demographic], query)
}

const setOrRemoveChoice = (choice, query) => {
  return choice ? setChoice(choice, query) : removeChoice(query)
}

const clearDemographics = R.omit(validUrlDemographics);

export { getQueryParams, setChartType, toggleBoolean, addDemographic, removeDemographic, clearDemographics, setOrRemoveChoice, removeParameters };
