import { actionGenerator } from '../common';
export const LOGIN = Symbol('login');
export const CHECK_USER = Symbol('checkUser');
export const SET_LOGGEDIN = Symbol('setLoggedIn');
export const SET_USER = Symbol('setLoggedIn');
export const LOGOUT = Symbol('logout');
export const LOGIN_FAILED = Symbol('loginFailed');

export const login = (email, password) => actionGenerator(LOGIN, {email, password});
export const checkUser = () => actionGenerator(CHECK_USER);
export const setLoggedIn = (user) => actionGenerator(SET_LOGGEDIN, {user});
export const setUser = (user) => actionGenerator(SET_USER, {user});
export const logout = () => actionGenerator(LOGOUT);
export const loginFailed = status => actionGenerator(LOGIN_FAILED, {status});
