import { stringify } from 'querystring';

const protocol = window.location.protocol + '//';
const apiVersion = 'v3'

let defaultRoot = `${protocol}${window.civiqs.config.MODELER_API_HOST_PORT}/results_api/`;

const handleReponse = (resp) => {
  let result = {}
  result.user = resp.headers.get('Modeler-User') || 'public'
  return resp.json().then(data => {
    return { ...result, data }
  })
}

const handleErrors = (resp) => {
  if (!resp.ok) {
    throw Error(resp.statusText);
  } else {
    return resp;
  }
}

const _fetch = ({ method = 'GET', path, body, root = defaultRoot }) => {
  let options = {
    method,
    credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'Accept': `application/vnd.questionator.${apiVersion}` }
  };
  if (body) options.body = JSON.stringify(body)
  return fetch(root + path, options)
    .then(handleErrors)
    .then(handleReponse)
    .catch(error => {
      return { error }
    })
}

export const login = (email, password) => {
  return _fetch(
    {
      method: 'POST',
      path: 'login',
      body: {
        modeler_user: {
          email,
          password
        }
      }
    }
  )
}

export const logout = () => {
  return _fetch({
    method: 'GET',
    path: 'logout'
  })
}

export const search = (term, from, jobsPerRow) => {
  const query = `?size=${jobsPerRow}&from=${from}`
  return _fetch({
    path: term ? `search/${term}${query}` : `search${query}`
  })
}

export const moreLikeThis = (jobName, runId) => {
  return _fetch({
    path: `results/${jobName}/more_like_this?run_id=${runId}`
  })
}

export const latestCrosstabs = (jobName, selector, runId) => {
  return _fetch({
    path: `results/${jobName}/latest_crosstabs/${encodeURIComponent(JSON.stringify(selector))}?run_id=${runId}`
  })
}

export const shareChart = (jobName, demographic, show, runId) => {
  return _fetch({
    path: `results/${jobName}/embed?run_id=${runId}`,
    method: 'PUT',
    body: {
      demographic,
      show
    }
  })
}

export const topline = (jobName, demographic = {}, runId) => {
  return _fetch({
    path: `results/${jobName}/trendline/${encodeURIComponent(JSON.stringify(demographic))}?run_id=${runId}`
  })
}

export const toplines = (job_names = []) => {
  return _fetch({
    path: `results/toplines?${stringify({ 'job_names[]': job_names })}`
  })
}

export const metadata = jobName => (
  _fetch({ path: `results/${jobName}/metadata` })
)

export const snapshot = snapshotUrl => {
  return fetch(`https://${snapshotUrl}`).then(handleReponse);
}

const ping = () => {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'Accept': `application/vnd.questionator.${apiVersion}` }
  };

  return fetch(defaultRoot + 'search?size=0&from=0', options)
    .then(handleErrors)
    .catch(error => {
      return { error }
    })
}

export default {
  login,
  logout,
  search,
  moreLikeThis,
  shareChart,
  latestCrosstabs,
  topline,
  metadata,
  toplines,
  snapshot,
  ping
}
