import { connect } from 'react-redux';
import Results from './Results';
import { callSearchApi, incrementFromIndex, resultsUnmount } from './actions';

const mapStateToProps = state => {
  return {
    ...state.search
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadMore: (origin) =>{
      dispatch(incrementFromIndex())
      dispatch(callSearchApi(origin))
    },
    onWillUnmount: () => {
      dispatch(resultsUnmount())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
