import * as actions from './actions';
import { sharedDateLabel } from '../common';

const defaults = {
  demographics: {},
  chartData: {},
  show: {},
  rawData: {},
  showRestrictedModal: false,
  embeddable: false,
  rawEmbeddable: { topline: false, subdemographic: false },
  brushVisibility: true
};

export default (state = defaults, action) => {
  switch (action.type) {
    case actions.REMOVE_SHARED_DATE: {
      return {
        ...state,
        annotations: state.annotations.filter(d => d.text !== sharedDateLabel)
      }
    }
    case actions.SET_SHARE_URL:
      return {
        ...state,
        shareUrl: action.shareUrl
      }
    case actions.SET_RELATED:
      return {
        ...state,
        related: action.related
      }
    case actions.SET_MANY:
      return {
        ...state,
        ...action.data
      }
    case actions.SET_BRUSH_VISIBILITY:
      return {
        ...state,
        brushVisibility: action.visibility
      }
    case actions.UNMOUNT:
    case actions.CLEAR:
      return defaults
    default:
      return state;
  }
}
