import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { percent, ActiveDemographics, monthDayYear } from '../common';
import { scaleLinear } from 'd3';
import { upperFirst, isEmpty } from 'lodash'

const scale = scaleLinear();

const styles = StyleSheet.create({
  header: {
    fontSize: '1.2em',
    fontWeight: '800'
  },
  population: {
    color: '#ccc'
  },
  predictor: {
    margin: '20px 0 40px 0'
  },
  predictorLabelRow: {
    fontSize: 16
  },
  predictorLabel: {
    fontWeight: 'bold'
  },
  demographic: {
    margin: '10px 0 20px 0'
  },
  labelRow : {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '30px'
  },
  icon: {
    display: 'block',
    height: 30,
    lineHeight: '30px',
    fontSize: 18
  },
  percent: {
    display: 'flex'
  },
  percentLine: {
    height: '3px'
  },
  date: {
    fontSize: 14,
    fontWeight: 300
  }
})

class MobileCrosstabs extends React.Component {

  crosstab(predictor, restrictedAccess) {
    const { colorScale } = this.props;
    const isTotal = predictor.key === 'All Respondents' || predictor.key === 'Filtered Totals';
    return <div key={predictor.key}>
      {
        predictor.values.map((p,i) => {
          return <div key={p.key} className={css(styles.predictor)}>
            <div className={css(styles.predictorLabelRow)}>
              <span className={css(styles.predictorLabel)}>
                {upperFirst(isTotal ? 'Total' : `${predictor.label}:`)}&nbsp;
              </span>
              <span>
                {!isTotal && upperFirst(p.key)}
              </span>
            </div>
            {
              p.values.map((d,i) => {
                return <div key={d.key} className={css(styles.demographic)}>
                  <div className={css(styles.labelRow)}>
                    <div className={css(styles.label)}>{d.key}</div>
                    { restrictedAccess && <i className={`material-icons ${css(styles.icon)}`}>lock</i>}
                    {!restrictedAccess && <div >{percent(d.value)}</div>}
                  </div>
                  <div className={css(styles.percent)}>
                    <div className={css(styles.percentLine)} style={{
                      background: colorScale(d.key),
                      width: `${scale(d.value) * 100}%`
                    }}></div>
                    <div className={css(styles.percentLine)} style={{flex:1, background: '#DEE0E5'}}></div>
                  </div>
                </div>
              })
            }
          </div>
        })
      }

    </div>
  }

  render() {
    const { data, population, restrictedAccess, demographics, dateRange = [] } = this.props;
    if(!data) return <span></span>
    const isFiltered = !isEmpty(data.predictors);
    const isHomeState = data.predictors.home_state
    return (
      <div>
        <div className={css(styles.header)}>All Respondents</div>
        <div className={css(styles.date)}>{monthDayYear(dateRange[1])}</div>
        <small className={css(styles.population)}>100% of population</small>
        {this.crosstab(data.globalTotal)}
        <div className={css(styles.header)}>Subgroups</div>
        <ActiveDemographics removable={true} demographics={demographics}/>
        {isFiltered && <small className={css(styles.population)}>{population ? `${percent(population)} of ${isHomeState ? 'state' : 'population'}` : ''}</small>}
        {isFiltered && this.crosstab(data.selectedDemographicTotal, restrictedAccess)}
        {data.subdemographics.map(f => this.crosstab(f, restrictedAccess))}
      </div>
    );
  }
}

MobileCrosstabs.propTypes = {
  data: PropTypes.array,
  colorScale: PropTypes.func,
  population: PropTypes.number,
  demographics: PropTypes.object,
  restrictedAccess: PropTypes.bool,
  dateRange: PropTypes.array
}

export default MobileCrosstabs;
