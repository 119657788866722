import { connect } from 'react-redux';
import SearchOverlay from './SearchOverlay';
import { resetDefaults, toggleSearchBar} from '../search';

const mapStateToProps = state => {
  return {
    showSearchBar: state.search.showSearchBar
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickSearchClose: () => {
      dispatch(toggleSearchBar(false))
      dispatch(resetDefaults())
    },
    onGoBack: () => {
      dispatch(resetDefaults())
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOverlay);
