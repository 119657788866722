import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api, mixpanel } from './common';
import retry from 'retry'

class HealthCheck extends Component {
  constructor() {
    super();

    this.state = {
      healthy: true,
      pending: true
    }
  }

  async ping () {
    const resp = await api.ping()
    return resp;
  }

  componentDidMount() {
    var operation = retry.operation({retries:3});
    operation.attempt(async (currentAttempt) => {
      const { error } = await this.ping();
      if(operation.retry(error)) {
        mixpanel.pingRetry(currentAttempt, error)
        this.setState({
          healthy:false,
        })
        return
      }
      if(error) mixpanel.pingRetry(currentAttempt, 'failed to load')
      this.setState({pending: false})
    });
  }

  render() {
    return (
      <div>
        {this.props.render(this.state)}
      </div>
    );
  }
}

HealthCheck.propTypes = {
  render: PropTypes.func
};

export default HealthCheck;
