import { css } from 'styled-components'

const sizes = {
  desktop: 1200,
  tabletLandscape: 1199,
  tablet: 992,
  phone: 576
}

const breakpoints = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default breakpoints
