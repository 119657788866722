import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as d3 from 'd3';
import * as R from 'ramda'
import { setDates } from '../dashboard/actions'
import { getUtcDayEpoch } from '../helpers/shared';

const getDates = R.curry((x, data) => R.map(R.compose(getUtcDayEpoch, x.invert))(data))
const isMinRange = ([start, end]) => d3.timeDay.count(start, end) < 2;

function useBrush(ref, x, width) {
  const { show } = useSelector(state => state.dashboard)
  const dispatch = useDispatch();
  const [intermediateDates, setIntermediateDates] = useState()
  const brush = d3.brushX().extent([[0, 0], [width, 60]]).on('brush', brushing).on('end', brushend).handleSize(20)
  const g = useRef();

  function brushend() {
    if (!d3.event.selection) {
      dispatch(setDates({}))
    } else {
      const dates = d3.event.selection.map(x.invert);
      if (isMinRange(dates)) return
      dispatch(setDates({ startDate: dates[0], endDate: dates[1] }))
      setIntermediateDates(null)
    }
  }

  function brushing() {
    if (!d3.event.selection) return;
    const dates = getDates(x, d3.event.selection);
    if (isMinRange(dates)) return
    setIntermediateDates(dates)
  }

  useEffect(() => {
    if (ref.current) {
      g.current = d3.select(ref.current)
      g.current.call(brush);
      if (!intermediateDates) {
        brush.on('end', null).on('brush', null)
        if (show.startDate && !show.endDate) {
          g.current.call(brush.move, [x(show.startDate), x.range()[1]])
        } else if (show.startDate && show.endDate) {
          g.current.call(brush.move, [x(show.startDate), x(show.endDate)])
        }
        else if (!show.startDate && !show.endDate) {
          g.current.call(brush.move, null)
        }
        brush.on('end', brushend).on('brush', brushing)
      }
    }
  }, [show, ref, x, brush, intermediateDates])

  return intermediateDates;
}

export default useBrush;