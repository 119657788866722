import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const Loading = () => (
  <div className={css(styles.root)}>
    <CircularProgress size={100}/>
  </div>
);

export default Loading;
