import { ascending } from 'd3';
import { percent, rawPercent, viewportConstants, sharedDateLabel } from '../common'
import { max } from 'lodash';
import * as R from 'ramda';
import { getX, getY, toRadians } from './canvas';

const labelBreakpoint = 250
const labelLineHeight = 18;
const padding = 6;
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
context.font = 'bold 14px Lato'

const measureText = context.measureText.bind(context)
const getTextWidth = R.compose(R.prop('width'), measureText);
const getMaxWidth = R.compose(max, R.map(R.prop('width')));
const getMaxAnnotationWidth = R.compose(getMaxWidth, R.map(R.compose(measureText, R.prop('text'))));
const getPointAtDate = date => R.compose(R.prop('value'), R.find(R.propEq('date', date)));
const defaultToEmptyObject = R.compose(R.defaultTo({}), R.head);
const getMouseObject = coords => R.compose(defaultToEmptyObject, R.filter(captureMouse(coords)))
const getWords = R.split(' ');
const getPercentageTextWidth = R.compose(getTextWidth, R.concat(' - '), R.head);
const addBackgroundOffset = R.add(15);
const addTextOffset = R.add(7);
const addRightPadding = R.add(5);
const addBackgroundAndTextOffset = R.compose(addBackgroundOffset, addTextOffset);
const getTotalLabelWidth = R.compose(addRightPadding, addTextOffset, getTextWidth)

export function timeseriesLabels(data, date, x, y, colorScale, viewport) {
  return data.map((b) => {
    const yv = getPointAtDate(date)(b.values)
    const fmt = percent(yv);
    const text = viewport === viewportConstants.PHONE ? fmt : `${fmt} - ${b.key}`
    return {
      x: x(date),
      y: y(yv),
      key: b.key,
      color: colorScale(b.key),
      text
    }
  }).sort((a, b) => ascending(a.y, b.y));
}

export function netLabels(data, date, x, y, positiveColor, negativeColor, viewport) {
  const yv = getPointAtDate(date)(data)
  const fmt = rawPercent(yv);
  const text = viewport === viewportConstants.PHONE ? fmt : `${fmt} - ${data[0].label}`
  return [{
    x: x(date),
    y: y(yv),
    color: yv > 0 ? positiveColor : negativeColor,
    text
  }]
}

function breakWordsToRows(words) {
  let line = '', rows = []

  for (var n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    if (getTextWidth(testLine) > labelBreakpoint && line !== ' ') {
      rows.push(line)
      line = words[n] + ' ';
    }
    else {
      line = testLine;
    }
  }

  rows.push(line);
  return rows;
}

export function labelToRows(label) {
  let { text, x, y, color, key } = label;
  const words = getWords(text);
  const percentageTextWidth = getPercentageTextWidth(words)
  const textRows = breakWordsToRows(words);
  const height = textRows.length * labelLineHeight + padding;
  const backgroundY = y - (height / 2)

  const rows = textRows.map((row, i) => {
    const percentageOffset = i > 0 ? percentageTextWidth : 0;
    return {
      y: backgroundY + labelLineHeight * (i + 1) + (i === 0 ? padding / 4 : 0),
      x: addBackgroundAndTextOffset(x) + percentageOffset,
      text: row,
      width: getTotalLabelWidth(row) + percentageOffset
    }
  })

  return {
    x,
    y,
    background: {
      x: addBackgroundOffset(x),
      y: backgroundY,
    },
    width: getMaxWidth(rows),
    height,
    rows,
    color,
    key
  }
}

function nudge(a, b, amount, bidirectional = true) {
  a.y2 = a.y2 -= amount;
  a.background.y = a.background.y -= amount;
  a.rows.forEach(row => row.y -= amount)
  if (bidirectional) {
    b.y2 = b.y2 += amount / 2;
    b.background.y = b.background.y += amount / 2;
    b.rows.forEach(row => row.y += amount / 2)
  }
}

export function relax(labels, bidirectional = false) {
  let again = false;
  labels.forEach(function (a, i) {
    a.y2 = a.y2 || a.y;
    labels.slice(i + 1).forEach(function (b) {
      b.y2 = b.y2 || b.y;
      const dy = (a.background.y + a.height + 5) - b.background.y;
      if (dy > 0) {
        again = true;
        nudge(a, b, 0.5, bidirectional)
      }
    });
  });
  if (again) relax(labels, bidirectional);
}

export function labelsToRows(labels, height) {
  let rows = R.map(labelToRows, labels)
  const bottoms = rows.map(row => row.background.y + row.height)
  const bidirectional = max(bottoms) < height;
  relax(rows, bidirectional)
  return rows;
}

const addRightPaddingForBreakPoint = R.add(30);

export function getRightMargin(choices, viewport) {
  if (viewport === viewportConstants.PHONE) return 60;
  const widths = choices.map(choice => context.measureText(`100% - ${choice}`).width);
  const maxWidth = max(widths);
  const breakPointPlusPadding = addRightPaddingForBreakPoint(labelBreakpoint);
  return maxWidth > breakPointPlusPadding ? breakPointPlusPadding : addRightPaddingForBreakPoint(maxWidth);
}

const calculateAnnotationBounds = annotation => {
  const top = - 27, bottom = - 17, left = annotation.x - 3, right = annotation.x + 3;
  return { annotation, top, right, bottom, left };
}

const calculateLabelBounds = maxWidth => label => {
  const top = label.background.y, bottom = label.background.y + 5 + label.height, left = label.background.x, right = label.background.x + maxWidth;
  return { label, top, right, bottom, left }
}

export const captureMouse = ([x, y]) => ({ left, right, top, bottom }) => x >= left && x <= right && y >= top && y <= bottom;

export function getAnnotationHover(coords, annotations) {
  return R.compose(R.prop('annotation'), getMouseObject(coords), R.map(calculateAnnotationBounds))(annotations)
}

const insideCircle = ([x0, y0], [x1, y1]) => Math.sqrt((x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0)) < 20

export function sharedDateWasClicked(coords, annotations) {
  const sharedDateAnnotation = R.find(R.propEq('text', sharedDateLabel))(annotations)
  if (!sharedDateAnnotation) return false;
  context.save();
  context.font = '12px lato'
  const labelWidth = measureText(sharedDateLabel).width
  const radians = toRadians(55);
  const x = Math.sin(radians) * labelWidth + sharedDateAnnotation.x
  const y = Math.cos(radians) * -labelWidth - 22;
  context.restore()
  return insideCircle([x, y], coords)
}

export function getClickedLabel(coords, labels) {
  const maxWidth = getMaxWidth(labels)
  return R.compose(R.prop('label'), getMouseObject(coords), R.map(calculateLabelBounds(maxWidth)))(labels)
}

export function getTopMargin(annotations, showAnnotations) {
  if (!annotations.length || !showAnnotations) return 50;
  return getMaxAnnotationWidth(annotations) * Math.sin(35 * Math.PI / 180) + 20;
}