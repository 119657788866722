import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui/Snackbar';

class Message extends React.Component {

  render() {
    const { message = ''} = this.props;
    return (
      <div>
        <Snackbar message={message} open={message ? true : false} />
      </div>
    );
  }
}

Message.propTypes = {
  message: PropTypes.string
}

export default Message;
