import React, { Component } from 'react';
import { Header, Footer, BannerButton, BlueBanner, publicUrl } from './common';
import { load } from 'js-yaml';
import styled from 'styled-components'

const P = styled.p`
  color: #616a73;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 26px;
`

const Button = styled(BannerButton)`
  margin-bottom: 50px;
`
class Thanks extends Component {
  constructor() {
    super();
    this.state = { }
  }

  fetchManifest() {
    return fetch(publicUrl('/data/landing.yml')).then(resp => resp.text()).then(d => load(d))
  }

  async componentDidMount() {
    const data = await this.fetchManifest();
    this.setState({
      data
    })
  }

  render() {
    const { data } = this.state
    return (
      <div>
        <Header />
        { data && <BlueBanner>{data.contact.thanks_title}
          <P>{data.contact.thanks_description}</P>
          <Button href={`${data.contact.thanks_button_url}`}>{data.contact.thanks_button_text}</Button>
        </BlueBanner>
        }
        <Footer />
      </div>
    );
  }

}

export default Thanks;
