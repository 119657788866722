import { connect } from 'react-redux';
import Message from './Message';

const mapStateToProps = state => {
  return {
    ...state.messages
  }
}

const mapDispatchTopProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchTopProps)(Message);
