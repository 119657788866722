import { load } from 'js-yaml';
import publicUrl from './publicUrl';
import { useState, useEffect } from 'react';

function useYaml(path) {
  const [yaml, setYaml] = useState({});

  useEffect(() => {
    fetch(publicUrl('/data/reports.yml')).then(resp => resp.text()).then(d => {
      const data = load(d)
      setYaml(data)
    })
  }, [])

  return yaml;
}

export default useYaml;