import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { startCase } from 'lodash';
import { equals, when, concat, compose, propOr, __, prop, map, applySpec, sortBy } from 'ramda';
import { goToDashboard } from '../routing/actions';
import styled from 'styled-components';
import publicUrl from './publicUrl';
import { metadata } from './api';
import { jobDescription } from '../helpers';

const Select = styled.select`
  border: none;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border-radius: 0;
  background-color: #fff;
  font-family: 'Lato';
  appearance: none;
  -webkit-padding-start: 0;
  -webkit-padding-end: 15px;
  -moz-margin-start: -3px;
  -moz-padding-end: 15px;
  background-image: ${() => `url(${publicUrl('/images/select-arrow.png')})`};
  background-position-x: right;
  background-position-y: center;
  background-size: 10px auto;
  background-repeat: no-repeat;
`

const isDemocrat = equals('Democrat')
const defaultToEmptyString = propOr('');
const changeToDemocratic = when(isDemocrat, concat(__, 'ic'))
const getPopulationModel = compose(startCase, defaultToEmptyString('populationModel'));
const getLimitToParty = compose(changeToDemocratic, defaultToEmptyString('limitToParty'));
const then = fn => pr => pr.then(fn);
const fetchMetaData = compose(then(jobDescription), then(prop('data')), metadata);
const getText = props => `${getLimitToParty(props)} ${getPopulationModel(props)}`
const createOption = applySpec({
  value: prop('name'),
  text: getText
})
const sortByText = sortBy(prop('text'));
const createOptions = compose(sortByText, map(createOption))


const VoterTypeDropDown = () => {
  const dispatch = useDispatch()
  const { show, jobName, jobDescription, activeDemographics } = useSelector(state => state.dashboard)
  const [options, setOptions] = useState([])

  function onChange(e) {
    dispatch(goToDashboard(e.target.value, { ...show, ...activeDemographics }))
  }

  useEffect(() => {
    async function fetchData() {
      const otherJobDescription = await fetchMetaData(jobDescription.linkedPopulationModelJob);
      const options = createOptions([jobDescription, otherJobDescription]);
      setOptions(options)
    }
    if (jobDescription.linkedPopulationModelJob) {
      fetchData()
    }
  }, [])

  if (jobDescription.linkedPopulationModelJob) {
    return <Select value={jobName} onChange={onChange} >
      {options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
    </Select>
  } else {
    return <span>{getText(jobDescription)}</span>
  }
}

const VoterType = (props) => {
  if (!props.selectable) return <span>{getText(props)}</span>
  return VoterTypeDropDown()
};

export default VoterType;
