import React from 'react';
import PropTypes from 'prop-types';
import { percent, monthDayYear } from '../common/';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: '1.2em',
    fontWeight: '800'
  },
  population: {
    alignSelf: 'flex-end'
  },
  date: {
    fontSize: 14,
    fontWeight: 300,
    marginTop: 10
  }
}

const CrosstabHeader = ({label, population, children, style = {}, dateRange = [], isHomeState}) => (
  <div>
    <div style={{...styles.root, ...style}}>
      <div>
        <div style={styles.label}>
          {label}
        </div>
        {dateRange[1] && <div style={styles.date}>
          {monthDayYear(dateRange[1])}
        </div>
        }
        <div>
          {children}
        </div>
      </div>
      <div style={styles.population}>{population ? `${percent(population)} of ${isHomeState ? 'state' : 'population'}` : ''}</div>
    </div>
  </div>
);

CrosstabHeader.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string.isRequired,
  population: PropTypes.number,
  style: PropTypes.object,
  dateRange: PropTypes.array,
  isHomeState: PropTypes.string
};

export default CrosstabHeader;
