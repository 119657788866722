import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FuzzySearch from 'fuzzy-search';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    maxHeight: 400,
    overflowY: 'none',
    width: '100%',
    position: 'relative'
  },
  overflow: {
    overflowY: 'auto'
  },
  input: {
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    padding: '0 50px',
    border: 'none',
    outline: 'none',
    borderTop: '1px solid #ddd'
  },
  clear: {
    position: 'absolute',
    right: 15,
    lineHeight: '40px',
    fontSize: 12,
    cursor: 'pointer'
  }
})


class DemographicSearch extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.clear = this.clear.bind(this);

    this.state = { value: '' }
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    }, this.search(e.target.value))
  }

  search(value) {
    this.setState({
      searchResults: this.searcher.search(value)
    })
  }

  clear() {
    this.setState({
      value: ''
    }, () => this.search(''))
  }

  componentDidMount() {
    this.searcher = new FuzzySearch(this.props.options)
  }

  render() {
    const { options = [], render, demographic } = this.props;
    const isSearchable = options.length > 10
    return (
      <div className={css(styles.root, isSearchable && styles.overflow)}>
        {isSearchable && <input type="text" value={this.state.value} placeholder={`Search for ${demographic}...`} onChange={this.onChange} className={css(styles.input)} />}
        {this.state.value && <span className={css(styles.clear)} onClick={this.clear}>&#9447;</span>}
        {render(this.state.searchResults || options)}
      </div>
    );
  }
}

DemographicSearch.propTypes = {
  render: PropTypes.func,
  demographic: PropTypes.string,
  options: PropTypes.array,
}

export default DemographicSearch;
