
import { values, findKey } from 'lodash';

function getRanking(data) {
  const v = values(data).filter(isFinite).sort().reverse();
  const [first, second] = v;
  const firstPlace = findKey(data, value => value === first);
  return { firstPlace, first, second }
}

export default getRanking;