import { scaleOrdinal, scaleLinear, rgb } from 'd3';
import { keys, values, pickBy, invert } from 'lodash';
import getRanking from './getRanking';
import * as R from 'ramda'
import { forceToArray } from '../helpers';

export const fillOpacity = scaleLinear().domain([0, 1]).range([.15, 1])
const getColorForFirstChoice = colorPicker => R.compose(colorPicker, R.head, forceToArray);

export const netScale = (jobDescription) => {
  const colorPicker = getColorForChoice(jobDescription)
  const { subtrahend, minuend } = R.map(getColorForFirstChoice(colorPicker), jobDescription.displayNet)
  return { negativeColor: subtrahend, positiveColor: minuend }
}

export const summedTotalsColor = ({ jobDescription, choices }) => {
  const { displayNet: { minuend, subtrahend } } = jobDescription;
  const { negativeColor, positiveColor } = netScale(jobDescription)
  const overlappingColorChoices = pickBy(jobDescription.choiceColors, (v, k) => choices.indexOf(k) > -1);
  const inverted = invert(jobDescription.choiceTotals)
  const positiveChoice = inverted[minuend.toString()];
  const negativeChoice = inverted[subtrahend.toString()];

  const choiceColors = {
    ...overlappingColorChoices,
    [positiveChoice]: positiveColor,
    [negativeChoice]: negativeColor

  }
  return scaleOrdinal().range(values(choiceColors)).domain(keys(choiceColors))
}

export const nonNetMapScale = (data, colorScale) => {
  const { first, second, firstPlace } = getRanking(data)
  const delta = first - second;
  const color = rgb(colorScale(firstPlace));
  color.opacity = fillOpacity(delta)
  return color
}

export const getColorForChoice = R.curry((jobDescription, choice) => {
  return R.compose(R.defaultTo('#ddd'), R.prop(choice), R.prop('choiceColors'))(jobDescription)
})
