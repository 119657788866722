import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Search } from '../search';
import { SearchPopup } from '../common'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  padding: 0 10px;
  z-index: 15;
  display: flex;
  align-items: center;
  background: ${props => props.theme.primaryBlue}
`
class SearhOverlay extends Component {
  constructor() {
    super();

    this.onKeyPress = this.onKeyPress.bind(this)
  }

  onKeyPress(e) {
    const { keyCode } = e;
    if(keyCode === 27) this.props.onClickSearchClose();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyPress)
  }

  render() {
    const { showSearchBar, onClickSearchClose } = this.props;
    if(!showSearchBar) return null;
    return (
      <React.Fragment>
        <Container>
          <Search showCloseButton={true} onClickClose={onClickSearchClose}/>
        </Container>
        <SearchPopup />
      </React.Fragment>
    );
  }
}

SearhOverlay.propTypes = {
  showSearchBar: PropTypes.bool,
  onClickSearchClose: PropTypes.func
}

export default SearhOverlay;
