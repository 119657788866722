import React from 'react';
import PropTypes from 'prop-types';

class LoggedIn extends React.Component {

  componentWillMount() {
    this.props.onComponentWillMount()
  }

  render() {
    const { children, loggedIn } = this.props;
    return <div>
      { loggedIn && children}
    </div>
  }

}

LoggedIn.propTypes = {
  onComponentWillMount: PropTypes.func,
  loggedIn: PropTypes.bool,
  children: PropTypes.any
}

export default LoggedIn;
