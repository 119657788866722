import { utcMonth, utcWeek, utcDay, utcFormat } from 'd3';

const containsJanuary = (start, end) => {
  const months = utcMonth.range(start, end).map(d => d.getUTCMonth());
  return months.indexOf(0) > -1
}

export const format = (domain) => {
  return (date, tick) => {
    const [start, end] = domain;
    const days = utcDay.count(start, end);
    const month = date.getUTCMonth();
    if (days < 120) {
      return tick === 0 ? utcFormat('%b %e, %Y')(date) : utcFormat('%b %e')(date)
    }
    if (month === 0 || !containsJanuary(start, end) && tick === 0) return utcFormat('%b \'%y')(date);
    return utcFormat('%b')(date)
  }
}

export const ticks = (domain, innerWidth) => {
  const [start, end] = domain;
  const days = utcDay.count(start, end);
  const maxLabels = Math.max(Math.floor(innerWidth / 50), 4)

  const decider = (fn) => {
    let selected;
    const options = [
      fn.every(12),
      fn.every(6),
      fn.every(3),
      fn.every(2),
      fn.every(1)
    ]
    options.forEach(option => {
      const range = option.range(start, end)
      if (range.length <= maxLabels) selected = option;
    })
    return selected;
  }

  if (days < 15) return decider(utcDay)
  if (days < 120) return decider(utcWeek)
  return decider(utcMonth)

}
