import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// export Bugsnag to the window for console debugging
window.civiqs.bugsnag = Bugsnag;
Bugsnag.start({
  // while it's called a "key" this isn't a secret
  apiKey: '3facdd4aa7af3f7bca7b3407521e9f95',
  plugins: [new BugsnagPluginReact()],
  releaseStage: window.civiqs.config.DEPLOY_ENVIRONMENT
})

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
export default BugsnagErrorBoundary;
